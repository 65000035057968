<!--
 * @Author: LiuXin
 * @Date: 2021-10-11 16:57:00
 * @LastEditors: LiuXin
 * @LastEditTime: 2021-10-14 15:20:58
-->
<template>
  <div class="w100 h100 filter_chart_table">
    <!-- 筛选 -->
    <div class="tab_filter">
      <a-space style="margin-bottom: 11px">
        <a-range-picker value-format="YYYY-MM-DD" separator="至" />
        <span> 对比 </span>
        <a-switch></a-switch>
        <a-range-picker value-format="YYYY-MM-DD" separator="至" />
      </a-space>
      <a-space>
        <a-select></a-select>
        <a-select></a-select>
        <a-select></a-select>
      </a-space>
    </div>
    <!-- 趋势图 -->
    <div class="w100 tab_chart_wrapper" style="height: 412px">
      <div class="left h100">
        <base-chart :chart-option="mapOption" style="height: 100%"> </base-chart>
      </div>
      <div class="right h100">
        <div class="right_top w100">
          <base-chart :chart-option="provinceOption" style="height: 100%"> </base-chart>
        </div>
        <div class="right_bottom w100">
          <base-chart :chart-option="sexRateOption" style="height: 100%"> </base-chart>
          <base-chart :chart-option="ageRateOption" style="height: 100%"> </base-chart>
          <base-chart :chart-option="platRateOption" style="height: 100%"> </base-chart>
        </div>
      </div>
    </div>
    <!-- 表格 -->
    <div class="w100 tab_table_wrapper">
      <p class="module_title" style="padding-left: 12px">
        <span class="title_content"> 数据报表 </span>
        <span class="select_group">
          <a-button>自定义列</a-button>
          <a-button>下载报表</a-button>
        </span>
      </p>
      <base-table :table="table"></base-table>
    </div>
  </div>
</template>

<script>
import BaseChart from '@/views/popularize/components/BaseChart.vue';
import BaseTable from '@/views/popularize/components/BaseTable.vue';
export default {
  name: 'TargetGroup',
  components: { BaseTable, BaseChart },
  inject: ['advertiseDetail', 'echartsOptions'],
  data() {
    return {
      mapOption: {},
      provinceOption: {},
      sexRateOption: {},
      ageRateOption: {},
      platRateOption: {},
      table: { columns: this.advertiseDetail.targetGroup },
    };
  },
  mounted() {
    this.getOptions();
  },
  methods: {
    // 初始化图表
    getOptions() {
      // 地图数据
      const mapData = [
        { name: '北京', value: Math.round(Math.random() * 500) },
        { name: '天津', value: Math.round(Math.random() * 500) },
        { name: '上海', value: Math.round(Math.random() * 500) },
        { name: '重庆', value: Math.round(Math.random() * 500) },
        { name: '河北', value: Math.round(Math.random() * 500) },
        { name: '河南', value: Math.round(Math.random() * 500) },
        { name: '云南', value: Math.round(Math.random() * 500) },
        { name: '辽宁', value: Math.round(Math.random() * 500) },
        { name: '黑龙江省', value: Math.round(Math.random() * 500) },
        { name: '湖南', value: Math.round(Math.random() * 500) },
        { name: '安徽', value: Math.round(Math.random() * 500) },
        { name: '山东', value: Math.round(Math.random() * 500) },
        { name: '新疆', value: Math.round(Math.random() * 500) },
        { name: '江苏', value: Math.round(Math.random() * 500) },
        { name: '浙江', value: Math.round(Math.random() * 500) },
        { name: '江西', value: Math.round(Math.random() * 500) },
        { name: '湖北', value: Math.round(Math.random() * 500) },
        { name: '广西', value: Math.round(Math.random() * 500) },
        { name: '甘肃', value: Math.round(Math.random() * 500) },
        { name: '山西', value: Math.round(Math.random() * 500) },
        { name: '内蒙古', value: Math.round(Math.random() * 500) },
        { name: '陕西', value: Math.round(Math.random() * 500) },
        { name: '吉林', value: Math.round(Math.random() * 500) },
        { name: '福建', value: Math.round(Math.random() * 500) },
        { name: '贵州', value: Math.round(Math.random() * 500) },
        { name: '广东', value: Math.round(Math.random() * 500) },
        { name: '青海', value: Math.round(Math.random() * 500) },
        { name: '西藏', value: Math.round(Math.random() * 500) },
        { name: '四川', value: Math.round(Math.random() * 500) },
        { name: '宁夏', value: Math.round(Math.random() * 500) },
        { name: '海南', value: Math.round(Math.random() * 500) },
        { name: '台湾', value: Math.round(Math.random() * 500) },
        { name: '香港', value: Math.round(Math.random() * 500) },
        { name: '澳门', value: Math.round(Math.random() * 500) },
      ];
      this.mapOption = this.echartsOptions.china(mapData);
      // 省份数据
      const provinceData = {
        series: [
          {
            type: 'bar',
            data: [18203, 23489, 29034, 104970, 131744, 630230],
          },
        ],
        yData: ['北京', '四川', '重庆', '上海', '河南', '湖南'],
      };
      this.provinceOption = this.echartsOptions.province(provinceData);
      // 性别分布
      const sexRateData = {
        seriesData: [
          { value: 1048, name: '男' },
          { value: 735, name: '女' },
        ],
        title: '性别分布',
      };
      this.sexRateOption = this.echartsOptions.rate(sexRateData);
      // 年龄分布
      const ageRateData = {
        seriesData: [
          { value: 1048, name: '21-30岁' },
          { value: 735, name: '31-40岁' },
          { value: 457, name: '41-50岁' },
        ],
        title: '年龄分布',
      };
      this.ageRateOption = this.echartsOptions.rate(ageRateData);
      // 平台分布
      const platRateData = {
        seriesData: [
          { value: 1048, name: '平台1' },
          { value: 735, name: '平台2' },
          { value: 125, name: '平台3' },
          { value: 687, name: '平台4' },
        ],
        title: '平台分布',
      };
      this.platRateOption = this.echartsOptions.rate(platRateData);
    },
  },
};
</script>

<style scoped lang='less' >
.tab_chart_wrapper {
  @border: 1px solid rgb(239, 241, 246);
  display: flex;
  .left {
    width: 40%;
    border-right: @border;
  }
  .right {
    width: 60%;
    .right_top {
      height: 50%;
      border-bottom: @border;
    }
    .right_bottom {
      height: 50%;
      display: flex;
      & > div {
        flex-grow: 1;
        &:not(:last-child) {
          border-right: @border;
        }
      }
    }
  }
}
</style>