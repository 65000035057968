<!--
 * @Author: Yran
 * @Date: 2021-10-13 10:50:51
 * @LastEditors: LiuXin
 * @LastEditTime: 2021-12-27 10:33:42
-->
<template>
  <meta content="no-referrer" name="referrer" />
  <div class="creative_box w100">
    <div class="img_box w100 h100">
      <video v-if="imgInfo.type?.toLowerCase() == 'video'" controls>
        <source :src="imgInfo.url" />
        您的浏览器不支持播放该视频！
      </video>
      <img v-else-if="imgInfo.url" :src="imgInfo.url" />
      <!-- 素材类型 -->
      <!-- <div class="img_mark flexCenter"></div> -->
    </div>
    <p class="creative_name textHide w100" :title="imgInfo.title">{{ imgInfo.title }}</p>
    <p class="status_box">
      <!-- 状态 -->
      <span class="status_icon status_icon_gray"></span>
      <span v-if="status">{{ status }}</span>
      <!-- 跳转 -->
      <a v-if="link.url" class="float-r" @click="viewDetail(link.url)">{{ link.text }} </a>
    </p>
  </div>
</template>
<script>
export default {
  props: {
    // 图片信息
    imgInfo: {
      type: Object,
      default: () => {
        return {};
      },
    },
    // 跳转链接
    link: {
      type: Object,
      default: () => {
        return {};
      },
    },
    // 状态
    status: {
      type: String,
      default: '',
    },
  },
  data() {
    return {};
  },
  methods: {
    // 查看详情
    viewDetail(url) {
      window.open(url);
    },
  },
};
</script>
<style lang="less" scoped>
.creative_box {
  width: 100%;
  height: 215px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #fff;
  border-radius: 4px;
  overflow: hidden;
  border: 1px solid rgb(246, 246, 246);
  .img_box {
    position: relative;
    height: 148px;
    .img_mark {
      position: absolute;
      top: 0;
      left: 0;
      color: #fff;
      width: 64px;
      height: 24px;
      border-radius: 4px;
      font-size: 12px;
      background: rgba(0, 0, 0, 0.7);
    }
  }
  .creative_name {
    font-size: 12px;
    color: #000;
    padding: 10px;
  }
  .status_box {
    padding: 10px;
    padding-top: 0;
    .status_icon_gray {
      color: #c0c0c0;
    }
  }
  img,
  video {
    width: 100%;
    height: 100%;
    object-fit: scale-down;
  }
}
</style>