<!--
 * @Author: Yran
 * @Date: 2021-11-09 18:44:36
 * @LastEditors: Yran
 * @LastEditTime: 2022-01-20 10:47:12
-->
<template>
  <div class="jlyq_advertise_table popularize-advertise-table" id="jlyq-advertise-table" ref="tableWrapper">
    <table-with-statistic :columns="columns" :statistic="statistic" v-bind="table" :data-source="dataSource" :loading="loading" :scroll="{ x: columns.length * 150, wrapperId: 'jlyq-advertise-table', otherHeight: 40 }" :row-selection="rowSelection" @change="sortAndPageChange" rowkey="campaign_id">
      <template #default="{ record, text, index, column }">
        <!-- 开关 -->
        <template v-if="column.dataIndex == 'switch'">
          <a-switch :loading="record.switchLoading" :disabled="switchStatus(record, 'disabled')" :checked="switchStatus(record, 'status')" size="small" @change="(changed, e) => switchChange(changed, e, record)" />
        </template>

        <!-- 操作 -->
        <template v-else-if="column.dataIndex == 'operate'">
          <span v-if="activeKey == 'product' && record.product_name == '未绑定产品'" style="color: #999">详情</span>
          <a v-else @click.stop="tableRowOperate('detail', record)"> 详情</a>
          <!-- <span class="cursorPoi" style="color: #2f70f4; padding: 0 24px 0 12px" @click="openHostDrawer(record)">自定义托管</span> -->
          <!-- <div class="hosting-status-icon">
                <svg-icon :icon-class="record.hostStatus ? 'hosting_icon' : 'unmanaged_icon'" class-name="status-icon"></svg-icon>
          </div> -->
        </template>

        <!-- 计划状态 -->
        <template v-else-if="column.dataIndex == 'ad_status'">
          {{ showUnitStatus(record) }}
          <div class="status_wrapper flexAlignCenter">
            <a-tooltip class="mr10">
              <template #title>{{ record.learn_status }}</template>
              <span class="status_box" v-if="record.learn_status && record.learn_status != '0'" :class="setLearnStatusClass('learnStatus', record.learn_status)">
                <CheckCircleOutlined v-if="setLearnStatusClass('learnStatus', record.learn_status) != 'status_box_blue'" class="status_box_icon" />
                <ClockCircleOutlined v-else class="status_box_icon" />
                学
              </span>
            </a-tooltip>
            <a-tooltip>
              <template #title>{{ record.cost_protect_status }}</template>
              <span class="status_box" v-if="record.cost_protect_status && record.cost_protect_status != '不需要赔付' && record.cost_protect_status != '0'" :class="setLearnStatusClass('compensateStatus', record.cost_protect_status)">
                <SafetyOutlined v-if="setLearnStatusClass('compensateStatus', record.cost_protect_status) != 'status_box_blue'" class="status_box_icon" />
                <ClockCircleOutlined v-else class="status_box_icon" />
                保
              </span>
            </a-tooltip>
          </div>
        </template>

        <!-- 可跳转到其他tab的且可以编辑列 -->
        <template v-else-if="columnShow(column) == 'jumpable&Editable'">
          <editable-column v-bind="{ record, text, index, column, activeKey, editable: !switchStatus(record, 'disabled') }">
            <template #default>
              <a-tooltip v-if="column.title.includes('名称')" arrow-point-at-center>
                <template #title>{{ text }}</template>
                <a @click.stop="toNextTab(record)" :class="{ 'textHide-2rows': column.title.includes('名称') }">{{ text }}</a>
              </a-tooltip>
              <a v-else @click.stop="toNextTab(record)">{{ text }}</a>
            </template>
          </editable-column>
        </template>

        <!--仅可跳转的列 -->
        <template v-else-if="columnShow(column) == 'onlyJumpable'">
          <a @click.stop="toNextTab(record)">{{ text }}</a>
          <p v-if="activeKey == 'account'">ID：{{ record.advertiser_id }}</p>
        </template>

        <!-- 仅可以编辑 -->
        <template v-else-if="columnShow(column) == 'onlyEditable'">
          <editable-column v-bind="{ record, text, index, column, activeKey, editable: !switchStatus(record, 'disabled') }"> </editable-column>
        </template>

        <template v-else-if="column.title == '广告组预算'">
          <div style="height: 32px">
            <p>{{ text }}</p>
            <p v-if="text != '不限'" style="color: #999">{{ record.adBudgetType }}</p>
          </div>
        </template>

        <!-- 创意图 -->
        <template v-else-if="column.dataIndex == 'creativity'">
          <div class="flexAlignCenter">
            <div v-if="record.url" style="flex-shrink: 0; margin-right: 8px" :class="(column.width = 250)">
              <meta content="no-referrer" name="referrer" />
              <video v-if="record.video_id" controls style="width: 80px; height: 45px; object-fit: scale-down">
                <source :src="record.url" />
              </video>
              <img v-else :src="record.url" :alt="record.ad_name" style="width: 80px; height: 45px; object-fit: scale-down" />
            </div>
            <p class="textHide-2rows" :title="record.title">{{ record.title || '-' }}</p>
          </div>
        </template>
      </template>
    </table-with-statistic>
  </div>
  <!-- 详情抽屉 -->
  <detail-drawer v-model:visible="drawerVisible" :row-data="rowData"></detail-drawer>
  <!-- 自定义托管抽屉 -->
  <host-drawer v-model:visible="hostDrawerVisible" :row-data="rowData" channel="TT"></host-drawer>
</template>

<script>
import DetailDrawer from '../detail/index.vue';
import HostDrawer from '@/views/popularize/components/HostDrawer.vue';
import { getTableDataTT } from '@/api/popularize/jlyq/index.js';
import TableWithStatistic from '@/components/TableWithStatistic/index.vue';
import EditableColumn from './EditableColumn.vue';
import { SafetyOutlined, CheckCircleOutlined, ClockCircleOutlined } from '@ant-design/icons-vue';
import { columnMap } from '@/utils/utils.js';
import columns from '@/views/popularize/assets/jlyqColumns.js';
import moment from 'moment';
export default {
  name: 'JlyqTabsTable',
  components: { DetailDrawer, EditableColumn, SafetyOutlined, CheckCircleOutlined, ClockCircleOutlined, HostDrawer, TableWithStatistic },
  props: {
    columns: { type: Array, default: () => [] },
    selectedRows: { type: Array, default: () => [] },
    loading: { type: Boolean, default: false },
    activeKey: { type: String, default: 'product' },
  },
  provide() {
    return { getTabActiveKey: () => this.activeKey, getMediaAccount: 'JLYQ' };
  },
  inject: ['getBaseFilter', 'jlyqDict'],
  emits: ['update:selectedRows', 'update:loading', 'switchChange', 'update:activeKey', 'updateFreshTime'],
  data() {
    return {
      selectedRowKeys: [], //当前选中行id
      rowData: {}, //点击详情的所在的行数据
      drawerVisible: false, //抽屉是否打开
      hostDrawerVisible: false, //自定义托管抽屉是否打开
      dataSource: [], //表格数据
      table: {
        bordered: true,
        size: 'small',
        showExpanded: false,
        pagination: { current: 1, pageSize: 10, total: 0, showTotal: total => `总计${total}条`, showSizeChanger: true, pageSizeOptions: ['10', '20', '30', '50'] },
      },
      baseFilters: {},
      bidDictionary: columns.bidDictionary,
      statistic: {},
    };
  },
  computed: {
    // 表格可选择配置
    rowSelection() {
      if (this.activeKey == 'product') {
        return null;
      }
      return {
        selectedRowKeys: this.selectedRowKeys,
        onChange: (selectedRowKeys, selectedRows) => {
          this.selectedRowKeys = selectedRowKeys;
          this.$emit('update:selectedRows', selectedRows);
        },
        getCheckboxProps: record => {
          return {
            // 状态是已删除的行不可选择
            disabled: this.switchStatus(record, 'disabled'),
          };
        },
      };
    },

    // 启停状态
    switchStatus() {
      // 当为NORMAL的时候表示开启；当为true的时候表示已删除，这一行除了跳转tab，其他都不可操作
      // mode: disabled:是否可编辑   status：状态

      //  广告组用的是 status。枚举值：
      // {value:'CAMPAIGN_STATUS_ENABLE',label: '启用'},
      // {value:'CAMPAIGN_STATUS_DISABLE',label: '暂停'},
      // {value:'CAMPAIGN_STATUS_DELETE',label: '删除'},
      // {value:'CAMPAIGN_STATUS_ALL',label: '所有包含已删除'},
      // {value:'CAMPAIGN_STATUS_NOT_DELETE',label: '所有不包含已删除'},
      // {value:'CAMPAIGN_STATUS_ADVERTISER_BUDGET_EXCEED',label: '超出广告主日预算'},

      // 广告计划操作状态 opt_status 枚举值：
      // {value:'AD_STATUS_ENABLE',label: '启用'},
      // {value:'AD_STATUS_DISABLE',label: '暂停'},

      // 广告创意也是操作状态  opt_status，枚举值：
      // {value:'CREATIVE_STATUS_ENABLE',label: '启用'},
      // {value:'CREATIVE_STATUS_DISABLE',label: '暂停'},
      // {value:'CREATIVE_STATUS_DELETE',label: '删除'},
      return (record, mode) => {
        let flag;
        switch (this.activeKey) {
          case 'campaign':
            if (mode == 'disabled') {
              flag = record.campaign_status == '删除';
            } else if (mode == 'status') {
              flag = record.campaign_status == '启用';
            }
            break;
          case 'ad':
            if (mode == 'disabled') {
              flag = false;
            } else if (mode == 'status') {
              flag = record.opt_status == 'AD_STATUS_ENABLE';
            }
            break;
          case 'creativity':
            if (mode == 'disabled') {
              flag = record.opt_status == 'CREATIVE_STATUS_DELETE';
            } else if (mode == 'status') {
              flag = record.opt_status == 'CREATIVE_STATUS_ENABLE';
            }
            break;
        }
        return flag;
      };
    },
  },
  methods: {
    /**
     * @description: 打开自定义托管侧边栏
     * @param {*} record 选中的行数据
     * @return {*}
     */
    openHostDrawer(record) {
      this.drawerVisible = false;
      this.hostDrawerVisible = true;
      this.rowData = record;
    },
    /** 遍历取值
     * @param {Array} columnOptions 遍历数据源
     * @param {String} columnValue 比较的数据
     * @param {String} key 比较的字段
     * @param {String} text 返回的字段
     * @return {*}
     */
    columnMap(columnOptions, columnValue, key = 'value', text = 'text') {
      if (columnValue) {
        const returnOpt = columnOptions?.filter(item => {
          return item[key] == columnValue;
        })?.[0];
        return returnOpt && returnOpt[text];
      }
    },
    /** 设置出价展示
     * @param {*} record
     * @return {*}
     */
    setBidColumn(record) {
      const cjList = record.cj?.split('|');
      if (cjList && cjList.length > 0) {
        const bidMode = this.columnMap(this.bidDictionary.bid_mode, cjList[1]);
        const optimizationGoal = this.columnMap(this.bidDictionary.optimization_goal, cjList[3]);
        const smartBidType = cjList[4];
        if (smartBidType == 'SYSTEMATIC') {
          return `自动出价（${bidMode}/${optimizationGoal}）`;
        } else {
          const bidShow = `${bidMode || ''} ${cjList[2] / 100 || 0}元${optimizationGoal ? '/' : ''}${optimizationGoal || ''}`;
          return bidShow != '' ? bidShow : '-';
        }
      } else {
        return '-';
      }
    },
    /** 設置計劃顯示
     * @description:
     * @param {*} record
     * @return {*}
     */
    showUnitStatus(record) {
      if (record.ad_status) {
        const statusList = record.ad_status.split(':');
        const adStatusOptions = [
          { value: 'AD_STATUS_DELIVERY_OK', label: '投放中' },
          { value: 'AD_STATUS_DISABLE', label: '计划暂停' },
          { value: 'AD_STATUS_AUDIT', label: '新建审核中' },
          { value: 'AD_STATUS_REAUDIT', label: '修改审核中' },
          { value: 'AD_STATUS_DONE', label: '已完成（投放达到结束时间）' },
          { value: 'AD_STATUS_CREATE', label: '计划新建' },
          { value: 'AD_STATUS_AUDIT_DENY', label: '审核不通过' },
          { value: 'AD_STATUS_BALANCE_EXCEED', label: '账户余额不足' },
          { value: 'AD_STATUS_BUDGET_EXCEED', label: '超出预算' },
          { value: 'AD_STATUS_NOT_START', label: '未到达投放时间' },
          { value: 'AD_STATUS_NO_SCHEDULE', label: '不在投放时段' },
          { value: 'AD_STATUS_CAMPAIGN_DISABLE', label: '已被广告组暂停' },
          { value: 'AD_STATUS_CAMPAIGN_EXCEED', label: '广告组超出预算' },
          { value: 'AD_STATUS_DELETE', label: '已删除' },
          { value: 'AD_STATUS_ALL', label: '所有包含已删除' },
          { value: 'AD_STATUS_NOT_DELETE', label: '所有不包含已删除' },
          { value: 'AD_STATUS_ADVERTISER_BUDGET_EXCEED', label: '超出广告主日预算' },
          { value: 'AD_STATUS_LIVE_CANNOT_LANUCH', label: '关联直播间不可投放' },
          { value: 'AD_STATUS_CAMPAIGN_PRE_OFFLINE_BUDGET', label: '广告组接近预算' },
          { value: 'AD_STATUS_PRE_OFFLINE_BUDGET', label: '广告接近预算' },
          { value: 'AD_STATUS_PRE_ONLINE', label: '预上线' },
          { value: 'AD_STATUS_DSP_AD_DISABLE', label: '已被广告计划暂停' },
          { value: 'AD_STATUS_AUDIT_STATUS_ERROR', label: '异常' },
          { value: 'AD_STATUS_DRAFT', label: '草稿' },
          { value: 'AD_STATUS_ADVERTISER_PRE_OFFLINE_BUDGET', label: '账户接近预算' },
          { value: 'AD_STATUS_CANNOT_EDIT', label: '不可编辑' },
          { value: 'AD_STATUS_EXTERNAL_URL_DISABLE', label: '落地页暂不可用' },
          { value: 'AD_STATUS_LIVE_ROOM_OFF', label: '关联直播间未开播' },
          { value: 'AD_STATUS_AWEME_ACCOUNT_PUNISHED', label: '关联抖音账号封禁不可投放' },
          { value: 'AD_STATUS_AWEME_ACCOUNT_DISABLED', label: '关联抖音号不可投' },
          { value: 'AD_STATUS_PRODUCT_OFFLINE', label: '关联商品不可投' },
        ];
        const studyStatusOptions = [
          { label: '非学习期中', value: 'DEFAULT' },
          { label: '学习期失败', value: 'LEARN_FAILED' },
          { label: '学习期结束', value: 'LEARNED' },
          { label: '学习期中', value: 'LEARNING' },
        ];
        const compensateStatusOptions = [
          { value: '成本保障生效中', label: '成本保障生效中' },
          { value: '成本保障已失效', label: '成本保障已失效' },
          { value: '成本保障计算中', label: '成本保障计算中' },
          { value: '成本保障已到账', label: '成本保障已到账' },
          { value: '成本保障已结束', label: '成本保障已结束' },
        ];
        record.learn_status = columnMap(studyStatusOptions, statusList[1], 'value', 'label') || statusList[1] || null;
        record.cost_protect_status = columnMap(compensateStatusOptions, statusList[2], 'value', 'label') || statusList[2] || null;
        return columnMap(adStatusOptions, statusList[0], 'value', 'label') || statusList[0] || '-';
      } else {
        return '-';
      }
    },
    /**
     * @description: 设置状态icon类名
     * @param {*} type 状态类别
     * @param {*} status 状态
     * @return {*}
     */
    setLearnStatusClass(type, status) {
      const list = {
        learnStatus: {
          LEARN_FAILED: 'status_box_grey',
          LEARNED: 'status_box_green',
          LEARNING: 'status_box_blue',
        },
        sceneText: {
          LEARN_FAILED: 'status_box_grey',
          LEARNED: 'status_box_green',
          LEARNING: 'status_box_blue',
        },
      };
      return list[type]?.[status] || 'status_box_grey';
    },
    /** 設置計劃顯示
     * @description:
     * @param {*} record
     * @return {*}
     */
    showAdStatus(record) {
      if (record.ad_status) {
        const adStatusOptions = localStorage.adStatusCodesOptionsTT ? JSON.parse(localStorage.adStatusCodesOptionsTT) : [];
        return columnMap(adStatusOptions, record.ad_status, 'value', 'label') || '-';
      } else {
        return '-';
      }
    },
    // 获取表格数据 filters：筛选条件，sorterFilters：排序条件
    async getTableData({ filters, sorterFilters }) {
      this.$emit('update:loading', true); //tableLoading开始
      //生成请求参数
      const getParams = () => {
        if (filters) {
          this.activeKey == 'creativity' && (filters.adId = filters.ad);
          this.baseFilters = { ...filters };
        }
        // 排序数据 （产品和账户默认降序）
        if (sorterFilters) {
          this.baseFilters.order = sorterFilters.order;
          this.baseFilters.orderField = sorterFilters.orderField;
        } else if (['product', 'account'].includes(this.activeKey)) {
          this.baseFilters.order = 'desc';
          this.baseFilters.orderField = 'cost';
        } else {
          this.baseFilters.order = undefined;
          this.baseFilters.orderField = undefined;
        }
        let isNotData = this.baseFilters.isNotData ? 1 : 0;
        const { current: page, pageSize: limit } = this.table.pagination;
        const elseParams = {
          isNotData: isNotData,
          page,
          limit: limit,
          tempName: this.baseFilters.tempName || '自定义列表', //模板
          userId: this.$store.state.oauth.userInfo.userId,
        };
        const params = { ...this.baseFilters, ...elseParams, mediaChannel: 'TT' };
        return params;
      };
      const params = getParams();
      try {
        sessionStorage.setItem('jlyqGetTableParams', JSON.stringify(params));
        const res = await getTableDataTT(params, this.activeKey);
        const data = res.data?.list || [];
        const rowKeyDict = {
          product: 'key',
          account: 'advertiser_id',
          campaign: 'campaign_id',
          ad: 'ad_id',
          creativity: 'creative_id',
        };
        const rowkey = rowKeyDict[this.activeKey];
        const statistics = res.data?.statistics || { key: rowkey };
        if (res.data?.statistics?.data_time) {
          const time = moment(res.data.statistics.data_time).format('YYYY-MM-DD HH:mm:ss');
          this.$emit('updateFreshTime', time);
        }

        const columnsDataIndexs = this.columns.map(column => column.dataIndex);
        columnsDataIndexs.forEach(key => (statistics[key] = statistics[key] || '-'));
        const key = this.columns.find(column => column.dataIndex != 'switch')?.dataIndex;
        statistics[key] = `总计：${res.data?.totalCount || 0}`;
        this.statistic = statistics;

        const columnDict = {
          adStatus: {}, // 广告状态
          targetType: {}, // 推广目标
          promote: {}, // 推广目标
          systemStatus: {
            NORMAL: '有效',
            PENDING: '待审核',
            DENIED: '审核不通过',
            PARTIALLY_PENDING: '部分审核中',
            PARTIALLY_NORMAL: '部分有效',
            PREPARE: '准备中(当投放视频广告时，该状态代表视频转码中)',
            DELETED: '已删除',
            INVALID: '异常(当投放视频广告时，该状态代表视频转码失败)',
          },
        };
        JSON.parse(localStorage.getItem('advertiseStatusCodesOptionsTT') || '[]').forEach(item => {
          columnDict.adStatus[item.value] = item.label;
        });
        JSON.parse(localStorage.getItem('targetTypeCodesOptionsTT') || '[]').forEach(item => {
          columnDict.targetType[item.value] = item.label;
        });
        JSON.parse(localStorage.getItem('promoteCodesOptionsTT') || '[]').forEach(item => {
          columnDict.promote[item.value] = item.label;
        });
        data.forEach((item, index) => {
          // 创意状态
          if (item.creative_status) {
            item.creative_status = this.jlyqDict.creativityStatus(item.creative_status);
          }
          // 首选位置
          if (item.inventory_type) {
            const list = JSON.parse(item.inventory_type) || [];
            const arr = list.map(item => this.jlyqDict.inventoryType(item));
            item.inventory_type = arr.join('，');
          }
          // 推广目的
          if (item.landing_type) {
            item.landing_type = this.jlyqDict.landingType(item.landing_type);
          }
          // 广告组状态
          if (item.campaign_status) {
            item.campaign_status = this.jlyqDict.campaignStatus(item.campaign_status);
          }
          // 广告组类型
          if (item.campaignType) {
            item.campaignType = this.jlyqDict.campaignType(item.campaignType);
          }
          // 账户预算
          if (item.bugdet == 0) {
            item.bugdet = '不限';
          } else if (item.bugdet) {
            item.bugdet += ' 元/天';
          } else {
            item.bugdet = '-';
          }
          // 广告组预算
          if (item.budget_type) {
            const list = item.budget_type?.split(':') || [];
            item.adBudgetType = this.jlyqDict.budgetMode(list[1]);
            item.budget_type = this.jlyqDict.budgetType(item.budget_type);
          }
          // 广告计划预算
          if (item.bad_budget) {
            const list = item.bad_budget?.split(':') || [];
            item.campaignBudgetType = this.jlyqDict.budgetMode(list[1]);
            item.bad_budget = this.jlyqDict.budgetType(item.bad_budget);
          }
          // 广告计划下载方式
          if (item.download_type) {
            item.download_type = this.jlyqDict.downloadType(item.download_type);
          }
          // 广告计划投放范围
          if (item.deliveryRange) {
            item.deliveryRange = this.jlyqDict.deliveryRange(item.deliveryRange);
          }
          // 广告计划深度出价
          if (item.deep_bid_type_deep_cpabid) {
            const cjList = item.deep_bid_type_deep_cpabid?.split(':');
            let str = '-';
            if (cjList && cjList.length > 0) {
              const bidMode = this.jlyqDict.deepPriceType(cjList[0]);
              const price = cjList[1] ? `${cjList[1] / 100}元` : '';
              str = bidMode + price;
            }
            item.deep_bid_type_deep_cpabid = str;
          }
          if (item.external_actions) {
            let list = item.external_actions.split(':')?.slice(0, 2);
            const result = list.reduce((arr, item) => {
              if (item) {
                arr.push(this.jlyqDict.convertType(item));
              }
              return arr;
            }, []);
            item.external_actions = result.length ? result.join('、') : '-';
          }
          if (item.deep_external_action) {
            item.deep_external_action = this.jlyqDict.convertType(item.deep_external_action);
          }
          // 广告出价
          if (item.ad_pricing) {
            const cjList = item.ad_pricing?.split(':');
            let str = '-';
            if (cjList && cjList.length > 0) {
              const bidMode = cjList[0];
              const price = cjList[1] ? `${cjList[1]}元` : '';
              str = this.jlyqDict.adPricingType(bidMode) || '' + price || '-';
            } else {
              str = '-';
            }
            item.ad_pricing = str;
          }

          // 创意状态
          if (item.smart_bid_type_flow_control_mode) {
            item.smart_bid_type_flow_control_mode = this.jlyqDict.smartBidTypeFlowControlMode(item.smart_bid_type_flow_control_mode);
          }
          item.key = item[rowkey] || this.$UUID.v1();
        });
        this.dataSource = data;
        this.table.pagination.total = res.data?.totalCount || 0;
      } catch (err) {
        console.log(err);
      } finally {
        this.$emit('update:loading', false);
      }
    },
    // 表格分页和排序变化
    sortAndPageChange(pagination, filters, sorter) {
      // 除非更改页数，其他的筛选条件变化都会将当前页重置为1
      if (this.table.pagination.current != pagination.current) {
        this.table.pagination.current = pagination.current;
      } else {
        this.table.pagination.current = 1;
      }
      this.table.pagination.pageSize = pagination.pageSize;
      const sorterDict = { ascend: 'asc', descend: 'desc' };
      let sorterFilters = {
        order: this.baseFilters.order,
        orderField: this.baseFilters.orderField,
      };
      if (sorter.order) {
        sorterFilters = { orderField: sorter.field, order: sorterDict[sorter.order] };
      }
      this.getTableData({ sorterFilters: sorterFilters });
    },
    // 表格行操作(type:detail/edit)
    tableRowOperate(type, rowData) {
      this.rowData = rowData;
      if (type == 'detail' && this.drawerVisible) {
        //
      } else if (!this.drawerVisible) {
        this.hostDrawerVisible = false;
        this.drawerVisible = true;
      }
    },
    // 启停
    switchChange(changed, e, record) {
      this.$emit('switchChange', changed, record);
    },
    // 跳转到下一个tab页
    toNextTab(record) {
      const tabs = ['product', 'account', 'campaign', 'ad', 'creativity'];
      const nextTab = tabs[tabs.indexOf(this.activeKey) + 1];
      this.$emit('update:activeKey', nextTab);
      const filterOptions = this.getBaseFilter();
      this.selectedRowKeys = [];
      switch (nextTab) {
        case 'account':
          filterOptions.productCodes = record.productCode;
          filterOptions.filterName && !filterOptions.productCodes && (filterOptions.productName = record.product_name);
          break;
        case 'campaign':
          filterOptions.filterName = 'advertiser';
          filterOptions[filterOptions.filterName] = record.advertiser_id;
          break;
        case 'ad':
          filterOptions.filterName = 'campaign';
          filterOptions[filterOptions.filterName] = record.campaign_id;
          break;
        case 'creativity':
          filterOptions.filterName = 'ad';
          filterOptions[filterOptions.filterName] = record.id;
          break;
        default:
          break;
      }
    },
    // 控制列是否展示
    columnShow(column, record) {
      // 可以跳转到其他tab的列
      let jumpableColumn = { product: 'product_name', account: 'advertiser_name', campaign: 'campaign_name', ad: 'ad_name' };
      // 可编辑列
      let editableColumn = {
        account: ['bugdet', 'remark'],
        campaign: ['campaign_name', 'budget_type'],
        ad: ['ad_name', 'cj', 'ad_pricing', 'bad_budget'],
      };
      let flag = 'else';
      if (column.dataIndex == jumpableColumn[this.activeKey] && editableColumn[this.activeKey]?.includes(column.dataIndex)) {
        flag = 'jumpable&Editable'; //可跳转且可编辑
        column.width = '20em';
      } else if (column.dataIndex == jumpableColumn[this.activeKey] && !editableColumn[this.activeKey]?.includes(column.dataIndex)) {
        flag = 'onlyJumpable'; // 可跳转但不能编辑
        column.width = '20em';
      } else if (column.dataIndex != jumpableColumn[this.activeKey] && editableColumn[this.activeKey]?.includes(column.dataIndex)) {
        flag = 'onlyEditable'; // 不可跳转但能编辑
      } else if (column.dataIndex == 'advertiser_id' && this.activeKey == 'account') {
        flag = '';
      }
      return flag;
    },
  },
};
</script>

