<!--
 * @Author: Yran
 * @Date: 2021-06-09 09:47:18
 * @LastEditors: Yran
 * @LastEditTime: 2021-11-22 16:49:05
-->
<template>
  <div style="display: flex">
    <a-select v-model:value="activeTemp" option-label-prop="label" placeholder="自定义指标" style="width: 5rem" ref="tempSelect" :dropdown-match-select-width="false" show-search :filter-option="true" option-filter-prop="label" @change="tempChange" :disabled="disabled" dropdown-class-name="template_select">
      <template #suffixIcon><GroupOutlined /></template>
      <a-select-opt-group style="display: none">
        <template #label> </template>
        <a-select-option style="display: none" value="默认推荐" :style="{ paddingLeft: activeTemp == '默认推荐' || activeTemp == '默认指标' ? '7px' : '24px' }"> <CheckOutlined v-if="activeTemp == '默认推荐' || activeTemp == '默认指标'" class="icon-check mr5" />默认推荐 </a-select-option>
      </a-select-opt-group>

      <a-select-opt-group label="常用自定义列">
        <template #label>常用自定义列</template>
        <template v-for="(temp, index) in tempArr" :key="index">
          <a-select-option :value="temp.tempName" :label="temp.tempName" :style="{ paddingLeft: activeTemp == temp.tempName ? '7px' : '24px' }">
            <CheckOutlined v-if="activeTemp == temp.tempName" class="icon-check mr5" />
            <div class="flexJustifyBetween">
              {{ temp.tempName }}
              <div>
                <PlusCircleOutlined v-if="index == 0 && !showAddIcon" class="select-icon ml10" @click.stop="addTemp()" />
                <HighlightOutlined class="select-icon ml10" @click.stop="editTemp(temp.tempName)" />
                <DeleteOutlined class="select-icon ml10" @click.stop="openDelectModal(temp.tempName)" />
              </div>
            </div>
          </a-select-option>
        </template>
      </a-select-opt-group>
      <template #notFoundContent>
        <a-empty :image="simpleImage">
          <template #description>
            <span>暂无数据</span>
          </template>
        </a-empty>
      </template>
      <template #dropdownRender="{ menuNode: menu }">
        <v-nodes :vnodes="menu" />
        <a-divider v-if="showAddIcon" style="margin: 4px 0" />
        <div v-if="showAddIcon" style="padding: 4px 24px; cursor: pointer" @click="addTemp" :disabled="disabled">自定义</div>
      </template>
    </a-select>
    <!-- 删除提示弹窗 -->
    <DelectModal :delect-modal-visible="delectModalVisible" @onOkClick="onDelectModalOk" @onCancelClick="onDelectModalCancel" />
  </div>
</template>
<script>
import { Empty } from 'ant-design-vue';
import { HighlightOutlined, CheckOutlined, DeleteOutlined, GroupOutlined, PlusCircleOutlined } from '@ant-design/icons-vue';
import DelectModal from '@/components/DelectModal/index.vue';
export default {
  components: {
    HighlightOutlined,
    CheckOutlined,
    DeleteOutlined,
    GroupOutlined,
    PlusCircleOutlined,
    DelectModal,
    VNodes: (_, { attrs }) => {
      return attrs.vnodes;
    },
  },
  props: {
    // 下拉选中
    activeTempName: {
      type: String,
      default: null,
    },
    // 下拉列表
    tempList: {
      type: Array,
      default: () => {
        return [];
      },
    },
    // 是否显示新增icon
    showAddIcon: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      delectModalVisible: false,
      simpleImage: Empty.PRESENTED_IMAGE_SIMPLE,
      // 下拉列表
      tempArr: [],
      // 选中的模板
      activeTemp: null,
      // 暂存的模板名称
      tempName: null,
    };
  },
  watch: {
    tempList(newVal) {
      this.tempArr = newVal;
      this.activeTemp = this.activeTempName || (this.tempArr[0] && this.tempArr[0].tempName);
    },
    activeTempName(newVal) {
      this.activeTemp = newVal || (this.tempArr[0] && this.tempArr[0].tempName);
    },
  },
  mounted() {
    this.tempArr = this.tempList;
    this.activeTemp = this.activeTempName || (this.tempArr[0] && this.tempArr[0].tempName);
  },
  emits: ['getTempName', 'addTemp', 'editTemp', 'deleteTemp'],
  methods: {
    /** 打开删除确认弹窗
     * @param {String} tempName 点击的模板名称
     * @return {*}
     */
    openDelectModal(tempName) {
      if (tempName == '自定义列表') {
        this.$message.warn('请勿删除默认自定义列表');
        return;
      }
      this.tempName = tempName;
      this.delectModalVisible = true;
    },
    /** 选中模板发生变化
     * @param {*}
     * @return {*}
     */
    tempChange() {
      this.$emit('getTempName', this.activeTemp);
    },
    /** 新增模板
     * @param {*}
     * @return {*}
     */
    addTemp() {
      this.$emit('addTemp');
    },
    /** 编辑模板
     * @param {*} tempName
     * @return {*}
     */
    editTemp(tempName) {
      this.$emit('editTemp', tempName);
    },
    /** 删除模板
     * @param {*} tempName
     * @return {*}
     */
    onDelectModalOk() {
      this.delectModalVisible = false;
      this.$emit('deleteTemp', this.tempName);
    },
    onDelectModalCancel() {
      this.delectModalVisible = false;
    },
  },
};
</script>
<style lang="less" scoped>
.add-temp-btn {
  font-size: 0.375rem;
  border-left: none;
}
.select-icon:hover {
  color: #2f70f4;
}
</style>
<style lang="less">
.template_select {
  .ant-select-item-group:first-child {
    display: none;
  }

  .ant-select-item-option-content {
    display: flex;
    align-items: center;
    .flexJustifyBetween {
      flex: 1;
    }
  }
}
</style>