/*
 * @Author: Yran
 * @Date: 2021-11-29 17:21:52
 * @LastEditors: Yran
 * @LastEditTime: 2021-12-13 13:57:31
 */
import axios, { advert_prefix, host_prefix, getUrl } from '@/utils/axios';

// 获取自定义托管规则列表
export function getRulesTableList(params) {
  return axios.post(host_prefix + '/rule/getList', params);
}
// 删除自定义托管规则
export function deleteRules(params) {
  return axios.post(host_prefix + '/rule/delete', params);
}
// 获取涉及产品筛选项
export function getProductOptions(params) {
  return axios.get(getUrl('/advert/promote/product/getList', params));
}
// 获取涉及账户筛选项
export function getAccountOptions(params) {
  return axios.get(getUrl('/advert/promote/account/getList', params));
}
// 新增或编辑自定义托管规则
export function saveOrUpdateRules(params) {
  return axios.post(host_prefix + '/rule/saveOrUpdate', params);
}
// 启停自定义托管规则
export function updateRulesStatus(params) {
  return axios.post(host_prefix + '/rule/update_status', params);
}
// 获取自定义托管规则模板列表
export function getRuleStstemList(params) {
  return axios.post(host_prefix + '/rule/getSystemList', params);
}
// 获取自定义托管执行记录
export function getExecutionTableList(params) {
  return axios.get(getUrl(host_prefix + '/monitor/records', params));
}
// 获取自定义托管创建人筛选项
export function getUserList(params) {
  return axios.get(getUrl(advert_prefix + '/promote/user/getList', params));
}
// 托管范围获取产品列表
export function getProductList(params, channel) {
  const url = {
    GDT: advert_prefix + '/tencent/productTable/getList',
    TT: advert_prefix + '/ocean/productTable/getList',
    KS: advert_prefix + '/ks/productTable/getList',
  };
  return axios.post(url[channel], params);
}
// 托管范围获取账户列表
export function getAccountList(params) {
  const url = host_prefix + '/rule/base/getAdvertiserList';
  return axios.post(url, params);
}
// 托管范围获取推广计划列表
export function getCampaignList(params) {
  const url = host_prefix + '/rule/base/getCampaignList';
  return axios.post(url, params);
}
// 托管范围获取广告列表
export function getAdvertiseList(params) {
  const url = host_prefix + '/rule/base/getAdList';
  return axios.post(url, params);
}
//
// 查询触发条件列表
export function getRulesCondition(params) {
  const url = host_prefix + '/rule/monit/getList';
  return axios.post(url, params);
}
// 查询规则操作列表
export function getRulesExec(params) {
  const url = host_prefix + '/ruleExec/getList';
  return axios.get(getUrl(url, params));
}
