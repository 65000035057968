<!--
 * @Author: LiuXin
 * @Date: 2021-10-19 11:50:21
 * @LastEditors: Yran
 * @LastEditTime: 2021-12-22 16:35:11
-->
<template>
  <a-modal @ok="handleOK" @cancel="handleCancel" v-bind="modal" :visible="visible" :title="typeDict[type]" :width="1024" centered :ok-button-props="{ loading: btnLoading }">
    <a-radio-group v-model:value="editMode" class="mb10" @change="editModeChange">
      <a-radio value="many" :disabled="updateBudgetDisabled">统一修改</a-radio>
      <a-radio value="one">分别修改</a-radio>
    </a-radio-group>
    <a-table v-bind="table" :columns="columns">
      <!-- 腾讯广告原出价 -->
      <template #originPrice="{ record }">{{ setBidColumn(record) }}</template>
      <!-- 修改后的出价/深度出价 -->
      <template #updatedPrice="{ record }">
        <span>{{ record.updatedPrice || record.ad_pricing }} </span>
        <span v-if="record.varietyRate > -Infinity">
          <span class="up" v-if="record.varietyRate > 0"> <svg-icon icon-class="upArrow" /> {{ record.varietyRate }}%</span>
          <span class="down" v-else-if="record.varietyRate < 0"> <svg-icon icon-class="downArrow" /> {{ record.varietyRate }}%</span>
        </span>
        <template> </template>
      </template>
      <!-- 编辑出价/深度出价 -->
      <template #editPrice="{ record }">
        <p :class="{ edit_many: editMode == 'many' }">
          <a-select style="width: 100px" v-model:value="record.priceType" :options="priceSelect"> </a-select>
          <a-space>
            <a-input v-model:value="record.startNumber" style="width: 100px" onkeyup="this.value=this.value.replace(/[^\d.]/g,'')" @change="priceInputChange(record)" />
            <template v-if="record.priceType == 6">
              <span> 至 </span>
              <a-input v-model:value="record.endNumber" style="width: 100px" onkeyup="this.value=this.value.replace(/[^\d.]/g,'')" />
            </template>
          </a-space>
        </p>
      </template>
      <!-- 日预算 -->
      <template #budget="{ text, record }">
        <div>
          <p>{{ text }}</p>
          <p v-if="text != '不限'" style="color: #999">{{ record.adBudgetType }}</p>
        </div>
      </template>
      <!-- 编辑日预算 -->
      <template #editBudget="{ record }">
        <p :class="{ edit_many: editMode == 'many', [mediaChannel]: true }">
          <!--  巨量引擎广告组和计划tab  -->
          <span v-if="mediaChannel == 'TT' && ['campaign', 'ad'].includes(this.getActiveKey())">
            <template v-if="!record.disabled">
              <span class="h100">日预算：</span>
              <a-input v-model:value="record.dailyBudget" suffix="元/天" style="width: 150px; margin-top: 10px" />
            </template>
            <template v-else> 不允许修改 </template>
          </span>
          <!-- 其他 -->
          <a-radio-group v-model:value="record.editBudgetMode" @change="e => radioChange(e, record)" v-else>
            <a-radio value="noLimited">不限</a-radio>
            <a-radio value="customize">
              <span v-show="editMode == 'many' && mediaChannel != 'KS'" style="margin-right: 5px">自定义</span>
              <a-input v-model:value="record.dailyBudget" suffix="元/天" style="width: 120px" :disabled="record.editBudgetMode != 'customize'" />
            </a-radio>
            <!-- 磁力引擎分日预算 -->
            <a-radio value="byDay" class="edit_by_day_radio" v-if="mediaChannel == 'KS'">
              <a-input-group compact class="edit_by_day_select">
                <div @mousedown="e => e.preventDefault()">
                  <a-select style="width: 190px" :disabled="record.editBudgetMode != 'byDay'" :placeholder="record.budgetByDay.total">
                    <template #dropdownRender>
                      <div class="dropdown_budget_wrapper">
                        <div class="option_item" v-for="(item, index) in record.budgetByDay.list" :key="index">
                          <span>{{ item.text }}</span>
                          <!-- 不要删这里的record.budgetByDay.handleTotal()方法的括号，会改变引用关系导致报错 -->
                          <a-input v-model:value="item.value" placeholder="若为空则为不限" @change="record.budgetByDay.handleTotal()" />
                        </div>
                      </div>
                    </template>
                  </a-select>
                </div>
              </a-input-group>
            </a-radio>
          </a-radio-group>
        </p>
      </template>
    </a-table>
  </a-modal>
</template>

<script>
import columnsGDT from '@/views/popularize/assets/txggColumns.js';
import columnsTT from '@/views/popularize/assets/jlyqColumns.js';
import columnsKS from '@/views/popularize/assets/clyqColumns.js';
import { txggDict } from '../assets/dict.js';
import _ from 'lodash';
export default {
  name: 'EditModal',
  props: {
    visible: { type: Boolean, default: false }, //弹框可视状态
    type: { type: String, default: 'price' }, //批量修改类型（预算：budget，出价：price,深度出价：deepPrice）
    tableData: {
      // 表格数据
      type: Array,
      default: () => {
        return [];
      },
    },
    optionName: { type: String, default: '' },
  },
  inject: ['mediaChannel', 'getActiveKey'],
  emits: ['afterOk', 'update:visible', 'dailyBudgetChange'],

  data() {
    return {
      typeDict: { deepPrice: '批量修改深度出价', price: '批量修改出价', budget: '批量调整日预算' },
      // 弹框配置
      btnLoading: false,
      modal: {
        class: 'batch_edit_modal',
        width: 1400,
        maskClosable: false,
      },
      editMode: 'one', //修改模式（统一修改：many,分别修改：one）
      //  表格配置
      table: {
        dataSource: [],
        size: 'small',
        rowKey: 'index1',
        pagination: false,
        scroll: { y: 500 },
        bordered: true,
        rowKey: 'key',
      },
      priceSelect: [
        { value: 1, label: '设置为(元)' },
        { value: 2, label: '提高(元)' },
        { value: 3, label: '降低(元)' },
        { value: 4, label: '提高(%)' },
        { value: 5, label: '降低(%)' },
      ],
      updateBudgetDisabled: false,
      columnsGDT,
      columnsTT,
      columnsKS,
    };
  },
  computed: {
    columns() {
      const flag = this.editMode == 'many';
      return this[`columns${this.mediaChannel}`].batchEdit[this.type](flag, this.table.dataSource.length)?.[this.optionName];
    },
  },
  watch: {
    visible(val) {
      if (val) {
        this.editMode = 'one';
        this.getTableData();
        this.editModeChange();
      } else {
        this.btnLoading = false;
      }
    },
  },

  methods: {
    editModeChange() {
      const type = this.type.indexOf('price') > -1 ? 'price' : 'budget';
      if (this.editMode == 'one') {
        this.table.dataSource.forEach(item => {
          item.priceType = 1;
        });
      }
      this.priceSelect =
        type == 'price' && this.editMode == 'many'
          ? [
              { value: 1, label: '设置为(元)' },
              { value: 2, label: '提高(元)' },
              { value: 3, label: '降低(元)' },
              { value: 4, label: '提高(%)' },
              { value: 5, label: '降低(%)' },
              { value: 6, label: '随机范围' },
            ]
          : [
              { value: 1, label: '设置为(元)' },
              { value: 2, label: '提高(元)' },
              { value: 3, label: '降低(元)' },
              { value: 4, label: '提高(%)' },
              { value: 5, label: '降低(%)' },
            ];
    },
    radioChange(e, record) {
      record.dailyBudget = null;
    },
    handleCancel() {
      this.$emit('update:visible', false);
    },
    handleOK() {
      this.btnLoading = true;
      if (this.editMode == 'many' && this.typeDict[this.type] == '批量修改出价') {
        if (this.table.dataSource[0].priceType == 6 && (!this.table.dataSource[0].startNumber || !this.table.dataSource[0].endNumber)) {
          this.$message.warning('请输入完整随机范围！');
          this.btnLoading = false;
          return;
        }
      }
      if (this.editMode == 'many') {
        this.table.dataSource.forEach(item => {
          item.editBudgetMode = this.table.dataSource[0].editBudgetMode;
        });
      }
      this.$emit('dailyBudgetChange', this.table.dataSource, this.type, this.editMode);
    },
    // 出价输入框改变
    priceInputChange(record) {
      if (!record.startNumber) {
        record.updatedPrice = record.startNumber;
        record.varietyRate = 0;
        return;
      }
      let origin = Number(record.ad_pricing); //原始出价
      let updatedPrice = Number(record.ad_pricing); //修改后的出价
      let varietyRate = 0; //变化幅度
      switch (record.priceType) {
        case 1: //设置为(元)
          updatedPrice = Number(record.startNumber);
          varietyRate = updatedPrice / origin - 1;
          break;
        case 2: //提高(元)
          updatedPrice += Number(record.startNumber);
          varietyRate = updatedPrice / origin - 1;
          break;
        case 3: //降低(元)
          updatedPrice -= Number(record.startNumber);
          varietyRate = -(Number(record.startNumber) / origin);
          break;
        case 4: //提高(%)
          varietyRate = Number(record.startNumber / 100);
          updatedPrice = origin * (1 + Number(record.startNumber) / 100);
          break;
        case 5: //降低(%)
          varietyRate = -Number(record.startNumber / 100);
          updatedPrice = origin * (1 - Number(record.startNumber) / 100);
          break;
      }
      record.updatedPrice = Number(updatedPrice).toFixed(2);
      record.varietyRate = Number((varietyRate * 100).toFixed(2));
    },
    // 获取表格数据
    getTableData() {
      let tableData = _.cloneDeep(this.tableData);
      const obj = {
        list: [
          { value: undefined, text: '周一' },
          { value: undefined, text: '周二' },
          { value: undefined, text: '周三' },
          { value: undefined, text: '周四' },
          { value: undefined, text: '周五' },
          { value: undefined, text: '周六' },
          { value: undefined, text: '周日' },
        ],
        total: '', //下拉框回显数据
        dayBudgetSchedule: Array(7).fill(0), //最终分日预算数据
        handleTotal: function () {
          let str = '';
          this.list.forEach((item, index) => {
            item.value && (str += `${item.text}:${item.value},`);
            this.dayBudgetSchedule[index] = item.value || 0;
          });
          this.total = str;
        },
      };
      // 磁力引擎分日预算
      tableData.forEach(item => {
        item.editBudgetMode = 'customize';
        item.updatedValue = ''; //注意这里！！！！
        item.priceType = 1;
        if (this.mediaChannel == 'KS') {
          item.budgetByDay = _.cloneDeep(obj);
        }
        if (this.mediaChannel == 'TT') {
          switch (this.getActiveKey()) {
            case 'campaign': {
              this.updateBudgetDisabled = item.budget_type == '不限';
              item.disabled = this.updateBudgetDisabled;
              break;
            }
            case 'ad': {
              this.updateBudgetDisabled = item.bud_type == '不限';
              item.disabled = this.updateBudgetDisabled;
              break;
            }
          }
        }
      });
      this.table.dataSource = tableData;
    },
    // 设置腾讯广告出价方式
    setBidColumn(record) {
      const cjList = record.cj?.split('|');
      if (cjList && cjList.length > 0) {
        const bidMode = cjList[1];
        const optimizationGoal = txggDict.optimizationGoal(cjList[3]);
        const smartBidType = cjList[4];
        if (smartBidType == 'SYSTEMATIC') {
          return `自动出价（${bidMode}/${optimizationGoal}）`;
        } else {
          const bidShow = `${bidMode || ''} ${cjList[2] / 100 || 0}元${optimizationGoal ? '/' : ''}${optimizationGoal || ''}`;
          return bidShow != '' ? bidShow : '-';
        }
      } else {
        return '-';
      }
    },
  },
};
</script>

<style lang="less">
.batch_edit_modal {
  .ant-table {
    // 编辑区域样式
    p {
      .ant-input-affix-wrapper {
        height: 30px;
        .ant-input {
          height: auto;
        }
        .ant-input-suffix {
          clear: #919191;
        }
      }
      .ant-input {
        height: 30px;
      }
      .ant-select {
        height: 30px;
        margin-right: 5px;
        margin-bottom: 5px;
      }
      &.edit_many {
        display: flex;
        flex-direction: column;
      }
      &.KS .ant-radio-group {
        display: flex;
        flex-direction: column;
        .edit_by_day_radio .ant-radio {
          transform: translateY(5px);
        }
        & > * {
          margin-bottom: 5px;
        }
      }
    }
    .ant-radio-wrapper {
      height: 32px;
      line-height: 32px;
    }
    .up {
      color: rgb(255, 56, 56);
    }
    .down {
      color: rgb(35, 204, 127);
    }
  }
}
</style>
