<!--
 * @Author: Yran
 * @Date: 2021-06-01 14:13:49
 * @LastEditors: LiuXin
 * @LastEditTime: 2021-11-17 11:28:41
-->
<template>
  <div class="time-selection">
    <table border="1" @mouseleave="mouseUp">
      <tr>
        <td colspan="1" rowspan="2" class="week-time">星期/时间</td>
        <td :colspan="gridNumOfDay / 2" class="period">00:00 - 12:00</td>
        <td :colspan="gridNumOfDay / 2" class="period">12:00 - 24:00</td>
      </tr>
      <tr>
        <td :colspan="gridNumOfDay / 24" v-for="index in 24" :key="index" class="time-box">{{ index - 1 }}</td>
      </tr>
      <tr v-for="(item, row) in activeKeyList" :key="row">
        <td class="week-label selectNone">
          {{ columnMap(weekOptions, row) }}
        </td>
        <td v-for="(childItem, col) in item" :key="col" class="time-choose-box cursorPoi" :class="{ hovers: getArrayIndex('hoverKeyList', row, col), active: getArrayIndex('activeKeyList', row, col) }" @mousedown="mouseDown(row, col)" @mouseup="mouseUp" @mousemove="addList(row + 1, col)"></td>
      </tr>
    </table>
    <p class="mt10">
      <span style="line-height: 14px; vertical-align: top; margin-right: 10px">
        <a-button type="primary" style="width: 14px; height: 14px; padding: 0; margin-right: 5px; visibility: visible"> </a-button>
        <span>已选</span>
      </span>
      <span style="line-height: 14px; vertical-align: top">
        <a-button style="width: 14px; height: 14px; padding: 0; margin-right: 5px; visibility: visible"> </a-button>
        <span>未选</span>
      </span>
      <a @click="reset" class="float-r">清空</a>
    </p>
  </div>
</template>
<script>
export default {
  props: {
    // 时段存储
    activeList: {
      type: Array,
      default: () => {
        return [];
      },
    },
    // 一天分成的格子数量
    gridNumOfDay: { type: Number, default: 48 },
  },
  emits: ['update:activeList'],
  data() {
    return {
      // 星期配置
      weekOptions: [
        { label: '星期一', value: 0, key: '0' },
        { label: '星期二', value: 1, key: '1' },
        { label: '星期三', value: 2, key: '2' },
        { label: '星期四', value: 3, key: '3' },
        { label: '星期五', value: 4, key: '4' },
        { label: '星期六', value: 5, key: '5' },
        { label: '星期日', value: 6, key: '6' },
      ],
      // 选中的key值数组
      activeKeyList: Array(7),
      // 框选的key值数组
      hoverKeyList: Array(7),
      // 已选周数
      chooseWeekList: { 星期一: [], 星期二: [], 星期三: [], 星期四: [], 星期五: [], 星期六: [], 星期日: [] },
      // 首次点击的key值
      firstKey: '',
      // 鼠标按下选中
      chooseMouseFlag: false,
      // 鼠标按下删除
      deleteMouseFlag: false,
    };
  },
  created() {
    // 禁止页面字体选中拖拽，因选择时段是会默认选中旁边的星期几，从而触发文本拖动
    document.body.onselectstart = document.body.ondrag = function () {
      return false;
    };
    for (let i = 0; i < this.activeKeyList.length; i++) {
      this.activeKeyList[i] = new Array(this.gridNumOfDay).fill(0);
    }
    for (let i = 0; i < this.hoverKeyList.length; i++) {
      this.hoverKeyList[i] = new Array(this.gridNumOfDay).fill(0);
    }
  },
  watch: {
    activeList: {
      handler(newVal) {
        if (newVal && newVal.length > 0) {
          this.activeKeyList = JSON.parse(JSON.stringify(newVal));
        }
      },
      immediate: true,
    },
  },
  methods: {
    /** 数据字典映射
     * @param {Array} optionlist 数据配置列表
     * @param {String}} key 匹配的key值
     * @return {*}
     */
    columnMap(optionlist, key) {
      if (key || key === 0) {
        return optionlist.filter(item => {
          return item.value == key;
        })[0].label;
      }
    },
    /** 判断当前索引是否在折叠数组中
     * @param {String} arr 查询的数组
     * @param {Number} index 索引
     * @return {*}
     */
    getArrayIndex(arr, row, col) {
      return this[arr][row][col];
    },

    /** 鼠标按下事件
     * @param {Number} week 星期数
     * @param {Number} index 时间值
     * @return {*}
     */
    mouseDown(row, col) {
      this.firstKey = [row, col];
      this.hoverKeyList[row][col] = 1;
      this.getArrayIndex('activeKeyList', row, col) ? (this.deleteMouseFlag = true) : (this.chooseMouseFlag = true);
    },

    /** 鼠标弹起事件
     * @param {*}
     * @return {*}
     */
    mouseUp() {
      if (this.chooseMouseFlag || this.deleteMouseFlag) {
        for (var i = 0; i < this.hoverKeyList.length; i++) {
          for (var j = 0; j < this.hoverKeyList[i].length; j++) {
            if (this.chooseMouseFlag && this.hoverKeyList[i][j]) {
              this.activeKeyList[i][j] = 1;
            } else if (this.deleteMouseFlag && this.hoverKeyList[i][j]) {
              this.activeKeyList[i][j] = 0;
            }
          }
        }
        this.hoverKeyList = Array(7);
        for (let i = 0; i < this.hoverKeyList.length; i++) {
          this.hoverKeyList[i] = new Array(48).fill(0);
        }
        this.getTime();
        this.$emit('update:activeList', this.activeKeyList);
        this.chooseMouseFlag = false;
        this.deleteMouseFlag = false;
      }
    },

    /** 添加框选部分
     * @param {Number} week 星期数
     * @param {Number} index 时间值
     * @return {*}
     */
    addList(week, index) {
      if (this.chooseMouseFlag || this.deleteMouseFlag) {
        let firstWeek = (this.firstKey && this.firstKey[0]) + 1;
        let firstIndex = this.firstKey && this.firstKey[1];
        firstWeek > week ? ([firstWeek, week] = [week, firstWeek]) : ([firstWeek, week] = [firstWeek, week]);
        firstIndex > index ? ([firstIndex, index] = [index, firstIndex]) : ([firstIndex, index] = [firstIndex, index]);
        this.hoverKeyList = Array(7);
        for (let i = 0; i < this.hoverKeyList.length; i++) {
          this.hoverKeyList[i] = new Array(48).fill(0);
        }
        for (let i = firstWeek; i <= week; i++) {
          for (let j = firstIndex; j <= index; j++) {
            this.hoverKeyList[i - 1][j] = 1;
          }
        }
      }
    },

    /** 清空
     * @param {*}
     * @return {*}
     */
    reset() {
      this.activeKeyList = Array(7);
      this.hoverKeyList = Array(7);
      for (let i = 0; i < this.activeKeyList.length; i++) {
        this.activeKeyList[i] = new Array(48).fill(0);
      }
      for (let i = 0; i < this.hoverKeyList.length; i++) {
        this.hoverKeyList[i] = new Array(48).fill(0);
      }
      this.chooseWeekList = { 星期一: [], 星期二: [], 星期三: [], 星期四: [], 星期五: [], 星期六: [], 星期日: [] };
    },

    /** 组合时间数据
     * @param {String} timeStr 时间字段
     * @return {*}
     */
    getTime(timeStr) {
      let startArr = {};

      this.chooseWeekList = {
        星期一: [],
        星期二: [],
        星期三: [],
        星期四: [],
        星期五: [],
        星期六: [],
        星期日: [],
      };
      for (var i = 0; i < this.activeKeyList.length; i++) {
        let flag = false;
        for (var j = 0; j < this.activeKeyList[i].length; j++) {
          if (!flag && this.activeKeyList[i][j]) {
            startArr[i] ? startArr[i].push(j) : (startArr[i] = [j]);
            flag = true;
          } else if (flag && !this.activeKeyList[i][j]) {
            startArr[i].push(j);
            flag = false;
          }
        }
      }
      Object.keys(startArr).forEach(key => {
        let week = Number(key);
        week = this.columnMap(this.weekOptions, week);
        for (let i = 0; i < startArr[key].length; i += 2) {
          let startIndex = startArr[key][i];
          let lastIndex = startArr[key][i + 1];
          let startTime = this.setTime(startIndex);
          let lastTime = this.setTime(lastIndex);
          this.chooseWeekList[week].push(startTime + '~' + lastTime);
        }
      });
    },
    setTime(time) {
      let hour = (time / 2) | 0;
      let min = time / 2 - hour > 0 ? '30' : '00';
      hour = hour >= 10 ? hour : '0' + hour;
      return hour + ':' + min + ':' + '00';
    },
  },
};
</script>
<style lang="less" scoped>
.time-selection {
  width: 70%;
  .week-time {
    text-align: center;
    width: 118px;
    color: #666;
    font-size: 12px;
  }
  .period {
    text-align: center;
  }
  .time-box {
    text-align: center;
  }
  .week-label {
    text-align: center;
    color: #666;
    font-size: 12px;
  }

  .time-choose-box {
    height: 28px;
    width: 14px;
    &.active.hovers,
    &.hovers {
      background: rgb(171, 203, 246);
    }
    &.active {
      background: blue;
    }
  }
  .selectNone {
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    -khtml-user-select: none;
    user-select: none;
  }
}
</style>