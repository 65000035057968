<!--
 * @Author: LiuXin
 * @Date: 2021-10-30 18:15:28
 * @LastEditors: LiuXin
 * @LastEditTime: 2022-01-11 15:04:44
-->
<template>
  <!-- 可以编辑的列 -->
  <a-popover :title="`修改${column.title}`" trigger="click" :visible="visible">
    <template #content>
      <!-- 名称编辑 -->
      <template v-if="column.title.indexOf('名称') > -1 || column.title.indexOf('备注') > -1">
        <a-textarea v-model:value="text" show-count :maxlength="60" style="width: 240px" />
      </template>
      <template v-else-if="column.title.indexOf('预算') > -1">
        <div class="editBudget">
          <a-radio-group v-model:value="updateBudgetMode" class="mb20">
            <a-radio :disabled="getActiveKey() == 'ad'" value="BUDGET_MODE_INFINITE">不限</a-radio>
            <a-radio :disabled="getActiveKey() == 'ad'" value="BUDGET_MODE_DAY">自定义</a-radio>
          </a-radio-group>
          <div v-show="updateBudgetMode == 'BUDGET_MODE_DAY'" class="mb10">
            <a-input v-model:value="text" :suffix="text.includes('元') ? '' : '元/天'" placeholder="请输入日预算"></a-input>
            <p class="suggestion">预算最低设置不能低于1000元，不超过9999999.99元</p>
          </div>
        </div>
      </template>
      <template v-else-if="column.title == '计划出价' || column.title == '深度转化出价'">
        <a-input v-model:value="text" placeholder="请输入出价"></a-input>
      </template>
      <template v-else-if="column.title == '投放日期'">
        <a-date-picker v-model:value="postDate.beginDate" v-if="postDate.checked" value-format="YYYY-MM-DD"></a-date-picker>
        <a-range-picker v-model:value="postDate.range" v-else value-format="YYYY-MM-DD"> </a-range-picker>
        <div style="margin: 10px 0">
          <a-checkbox v-model:checked="postDate.checked">长期投放(仅设置开始日期) </a-checkbox>
        </div>
      </template>
      <template v-else-if="column.title == '投放时间'">
        <a-radio-group v-model:value="updatePostDate" class="mb20">
          <a-radio value="noLimited">长投放</a-radio>
          <a-radio value="customize">具体时间</a-radio>
          <a-date-picker v-model:value="postDate.beginDate" v-if="updatePostDate == 'customize'" value-format="YYYY-MM-DD"></a-date-picker>
        </a-radio-group>
      </template>
      <!-- 底部按钮 -->
      <a-row type="flex" justify="center" class="mt10">
        <a-button @click="handleCancel" class="mr15">取消</a-button>
        <a-button type="primary" @click="handleOk">确定</a-button>
      </a-row>
    </template>
    <span class="flexBetweenCenter" style="height:32px">
      <slot>
        <div v-if="column.title == '广告组预算'">
          <p>{{ text }}</p>
          <p v-if="text != '不限'" style="color: #999">{{ record.adBudgetType }}</p>
        </div>
        <div v-if="column.title == '计划预算'">
          <p>{{ text }}</p>
          <p v-if="text != '不限'" style="color: #999">{{ record.campaignBudgetType }}</p>
        </div>
        <span v-else :class="{ 'textHide-2rows': column.title.includes('名称') }">{{ text }}</span>
      </slot>
      <!-- 总计行和状态为已删除的不可编辑 -->
      <EditOutlined v-if="isEditAble(record)" @click="visible = true" />
    </span>
  </a-popover>
</template>

<script>
import { EditOutlined } from '@ant-design/icons-vue';
import { updateAdPlanNameTT, advertiserUpdatePostDateTT, advertiserUpdateDailyBudgetTT, adgroupUpdateDailyBudgetTT, updateBidAmountTT, campaignUpdateDailyBudgetTT, updateCampaignsNameTT, advertiserUpdateRemarkTT } from '@/api/popularize/jlyq/index.js';
import columns from '@/views/popularize/assets/jlyqColumns.js';
export default {
  name: 'EditableColumn',
  components: { EditOutlined },
  inject: ['getActiveKey', 'jlyqDict'],
  props: {},
  data() {
    return {
      record: this.$attrs.record,
      text: this.$attrs.text,
      index: this.$attrs.index,
      column: this.$attrs.column,
      editable: this.$attrs.editable,
      visible: false,
      cjValue: null,
      postDate: { checked: false, range: [], beginDate: '' }, //投放日期数据
      updateBudgetMode: this.$attrs.text == '不限' ? 'BUDGET_MODE_INFINITE' : 'BUDGET_MODE_DAY',
      updatePostDate: 'noLimited', // 投放时间
      activeKey: this.$attrs.activeKey,
      bidDictionary: columns.bidDictionary,
    };
  },
  computed: {},
  mounted() {},
  methods: {
    async handleOk() {
      let params = {};
      let res = {};
      // 账户tab页下单独操作
      if (this.column.title == '账户预算') {
        params = [{ advertiserId: this.record.advertiser_id, budgetMode: this.updateBudgetMode, number: this.updateBudgetMode == 'BUDGET_MODE_INFINITE' ? 0 : (this.text + '')?.split(' 元')?.[0] }];
        res = await advertiserUpdateDailyBudgetTT(params);
      } else if (this.column.title == '账户备注') {
        params = [{ advertiserId: this.record.advertiser_id, remark: this.text }];
        res = await advertiserUpdateRemarkTT(params);
      }

      // 广告组tab页下单独操作
      else if (this.column.title == '广告组预算') {
        params = [{ campaignId: this.record.campaign_id, dailyBudget: this.updateBudgetMode == 'BUDGET_MODE_INFINITE' ? 0 : (this.text + '')?.split(' 元')?.[0], budgetMode: this.updateBudgetMode }];
        res = await campaignUpdateDailyBudgetTT(params);
      } else if (this.column.title == '广告组名称') {
        params = [{ campaignId: this.record.campaign_id, campaignName: this.text }];
        res = await updateCampaignsNameTT(params);
      }

      // 投放日期和深度出价目标
      else if (this.column.title == '投放日期') {
        let params = { adIds: [this.record.adgroup_id], beginDate: this.postDate.range[0], endDate: this.postDate.range[1] };
        if (this.postDate.checked) {
          params.beginDate = this.postDate.beginDate;
          delete params.endDate;
        }
        res = await advertiserUpdatePostDateTT(params);
      } else if (this.column.title == '深度目标出价') {
        params = [{ adGroupId: this.record.adgroup_id, number: this.text || 0, type: 1 }];
        res = await updateBid(params);
      }

      // 计划tab页下单独操作
      else if (this.column.title == '计划名称') {
        params = [{ adId: this.record.id, name: this.text }];
        res = await updateAdPlanNameTT(params);
      } else if (this.column.title == '计划出价') {
        params = [{ adId: this.record.id, bid: this.text || 0 }];
        res = await updateBidAmountTT(params);
      } else if (this.column.title == '计划预算') {
        params = [{ adId: this.record.id, budget: this.updateBudgetMode == 'BUDGET_MODE_INFINITE' ? 0 : (this.text + '')?.split(' 元')?.[0] }];
        res = await adgroupUpdateDailyBudgetTT(params);
      }
      if (res.code == 0) {
        this.$message.success('编辑成功');
        this.text = this.$attrs.text;
      } else {
        const idList = {
          account: 'advertiser_id',
          campaign: 'campaign_id',
          ad: 'adgroup_id',
        };
        const errorId = this.record?.[idList[this.getActiveKey()]];
        let errorMsg = res?.data?.[0]?.[errorId];
        this.$message.error(res?.data?.[0]?.errorMsg || errorMsg);
        this.text = this.$attrs.text;
      }
      this.visible = false;
    },
    handleCancel() {
      this.record[this.column.dataIndex] = this.$attrs.text;
      this.text = this.$attrs.text;
      this.visible = false;
    },
    isEditAble(record) {
      const cjList = record.cj?.split('|');
      const smartBidType = cjList && cjList.length > 0 ? cjList[4] : 'CUSTOM';
      this.cjValue = cjList && cjList.length > 0 ? cjList[2] / 100 : 0;
      return this.editable && !(this.column.title == '广告出价' && smartBidType == 'SYSTEMATIC') && !(this.column.title.indexOf('预算') != -1 && record.budget_type == '不限');
    },
    /** 遍历取值
     * @param {Array} columnOptions 遍历数据源
     * @param {String} columnValue 比较的数据
     * @param {String} key 比较的字段
     * @param {String} text 返回的字段
     * @return {*}
     */
    columnMap(columnOptions, columnValue, key = 'value', text = 'text') {
      if (columnValue) {
        const returnOpt = columnOptions.filter(item => {
          return item[key] == columnValue;
        })[0];
        return returnOpt && returnOpt[text];
      }
    },
  },
};
</script>

<style scoped lang="less">
.yellow {
  color: rgb(245, 159, 112);
}
.grew {
  color: #999;
}
.editBudget {
  width: 300px;
  .suggestion {
    margin: 10px 0;
    color: #999;
  }
}
.deepPrice {
  width: 300px;
}
</style>
