<!--
 * @Author: Yran
 * @Date: 2021-10-26 10:51:39
 * @LastEditors: Yran
 * @LastEditTime: 2022-01-06 14:29:16
-->
<template>
  <a-modal class="customize-column" v-model:visible="customizeModalVisible" :title="null" :footer="null" width="1261px" centered :mask-closable="false" @cancel="onCancelClick">
    <a-card :bordered="false" style="width: 100%">
      <template #title>
        <span class="font16 weight">自定义列</span> <span class="title-tips" v-if="titleTips">{{ titleTips }}</span>
      </template>

      <a-row class="customize-top">
        <a-col :span="19" class="customize-left">
          <a-input-search class="mb10 customize-left-search" v-model:value="searchValue" placeholder="搜索指标" allow-clear @search="onSearchValue" />
          <a-row class="customize-content">
            <a-col :span="5">
              <a-menu id="menuLists" class="menu-list" v-model:selectedKeys="selectedKeys" v-model:openKeys="openKeys" mode="inline" @openChange="openKeyChange">
                <template v-for="menu in menuArray" :key="menu[oneCloumnKey]">
                  <a-sub-menu v-if="isThreeLevelMenu(menu)" :key="menu[oneCloumnKey]">
                    <template #expandIcon>
                      <span style="display: none">1</span>
                    </template>
                    <template #title>
                      <a :href="`#${menu[oneCloumnKey]}`" class="color85">{{ menu[oneCloumnName] }}</a>
                    </template>
                    <a-menu-item v-for="childMenu in menu[menuChildKey]" :key="childMenu[twoCloumnKey]">
                      <a :href="`#${childMenu[twoCloumnKey]}`">{{ childMenu[twoCloumnName] }}</a>
                    </a-menu-item>
                  </a-sub-menu>
                  <a-menu-item v-else :key="menu[oneCloumnKey]">
                    <a :href="`#${menu[oneCloumnKey]}`">{{ menu[oneCloumnName] }}</a>
                  </a-menu-item>
                </template>
              </a-menu>
            </a-col>
            <a-col :span="19" class="customize-list" v-if="loadCheckBox">
              <a-card v-for="menu in menuArray" v-show="!menu.hide" :key="menu[oneCloumnKey]" :id="menu[oneCloumnKey]" :bordered="false" @click.stop="selectedKey(menu[oneCloumnKey])" style="100%">
                <template #title>
                  <a-checkbox v-if="!isThreeLevelMenu(menu)" v-model:checked="menu.checkedAll" :indeterminate="isIndeterminate(menu)" @change="checkAllChange(menu)"> </a-checkbox>
                  <span class="font14 weight cursorPoi pl10">{{ menu[oneCloumnName] }}</span>
                  <span v-if="!isThreeLevelMenu(menu)" style="color: #666; font-size: 0.375rem">{{ `（已选${checkLength(menu)}）` }}</span>
                </template>
                <div v-if="isThreeLevelMenu(menu)">
                  <a-card v-for="childMenu in menu[menuChildKey]" v-show="!childMenu.hide" :key="childMenu[twoCloumnKey]" :id="childMenu[twoCloumnKey]" :bordered="false" style="100%">
                    <template #title>
                      <div class="flexJustifyBetween">
                        <div>
                          <a-checkbox :disabled="childMenu.disabled || isSearch" v-model:checked="childMenu.checkedAll" :indeterminate="isIndeterminate(childMenu)" @change="checkAllChange(childMenu)"> </a-checkbox>
                          <span class="font14 weight cursorPoi pl10">{{ childMenu[twoCloumnName] }}</span>
                          <span style="color: #666; font-size: 0.375rem">{{ `（已选${checkLength(childMenu)}）` }}</span>
                        </div>
                      </div>
                    </template>
                    <a-row class="checkbox-wrapper">
                      <a-col :span="8" v-show="!gradeMenu.hide" v-for="gradeMenu in childMenu[menuChildKey]" :key="gradeMenu[threeCloumnKey]">
                        <a-checkbox v-model:checked="gradeMenu.checked" :disabled="gradeMenu.disabled" @change="checkedChange(gradeMenu)">
                          <a-tooltip>
                            <template #title>
                              {{ gradeMenu[threeCloumnName] }}
                            </template>
                            <span class="textHide check-label">{{ gradeMenu[threeCloumnName] }}</span>
                          </a-tooltip>
                        </a-checkbox>
                      </a-col>
                    </a-row>
                  </a-card>
                </div>
                <div v-else>
                  <a-row class="checkbox-wrapper">
                    <a-col :span="8" v-show="!childMenu.hide" v-for="childMenu in menu[menuChildKey]" :key="childMenu[threeCloumnKey]">
                      <a-checkbox v-model:checked="childMenu.checked" :disabled="childMenu.disabled" @change="checkedChange(childMenu)">
                        <a-tooltip>
                          <template #title>
                            {{ childMenu[threeCloumnName] }}
                          </template>
                          <span class="textHide check-label">{{ childMenu[threeCloumnName] }}</span>
                        </a-tooltip>
                      </a-checkbox>
                    </a-col>
                  </a-row>
                </div>
              </a-card>
            </a-col>
            <a-col :span="18" class="customize-list-empty" v-if="!loadCheckBox">
              <a-spin style="width: 100%; height: 100%; padding: 50%" size="large"></a-spin>
            </a-col>
          </a-row>
        </a-col>
        <a-col :span="5" class="customize-right pt20">
          <span class="customize_title font18 weight pl10">已选指标({{ draggableList.length + fixedList.length }})<span class="clear_all pr10 cursorPoi" @click="clearAll">清空</span></span>
          <div class="font14 pl10 mb10" style="color: rgba(0, 0, 0, 0.45)">拖动可自定义指标顺序</div>
          <div v-if="fixedList.length > 0">
            <div class="draggable-box" v-for="(item, index) in fixedList" :key="index">
              <div class="flexJustifyBetween flexAlignCenter">
                <MenuOutlined class="mr10" />
                <div style="word-break: break-all">{{ item.label }}</div>
              </div>
            </div>
            <a-divider><span style="color: rgba(0, 0, 0, 0.45)">以上指标将横向固定</span></a-divider>
          </div>
          <Draggable :draggable-list="draggableList" @getDraggableList="getDraggableList" @deleteDraggable="deleteDraggable" />
        </a-col>
      </a-row>
    </a-card>
    <div class="customize-footer flexJustifyBetween">
      <div class="footer-left flexJustifyBetween flexAlignStart">
        <a-checkbox v-if="showCheck" v-model:checked="saveChecked" @change="saveCheckedChange">保存为常用自定义列</a-checkbox>
        <a-form ref="columnNameRef" :model="columnNameForm" :rules="columnNameRules">
          <a-form-item class="mb0" name="columnName">
            <a-input v-if="saveChecked || !showCheck" v-model:value="columnNameForm.columnName" placeholder="自定义列名" :maxlength="10" class="mr10" style="width: 6rem; height: 0.8rem" @blur="inputBlur('自定义列名', 'columnNameForm', 'columnName')"></a-input>
          </a-form-item>
        </a-form>
        <span class="font12 color45">设置后将应用于所有报表</span>
      </div>
      <div class="footer-right">
        <a-button class="footer-btn cancel-btn mr20" @click="onCancelClick">取消</a-button>
        <a-button type="primary" class="footer-btn ok-btn" @click="onOkClick">确定</a-button>
      </div>
    </div>
    <a-spin :spinning="spinLoading" size="large" class="loading" />
  </a-modal>
</template>

<script>
import _ from 'lodash';
import { MenuOutlined } from '@ant-design/icons-vue';
import Draggable from '../../../components/Customize/dragSort.vue';
export default {
  emits: ['getDraggableList', 'getTempData', 'update:columnName'],
  props: {
    // 弹窗开关
    modalVisible: {
      type: [Boolean, String],
      default: false,
    },
    // 所有指标列表
    menuList: {
      type: Array,
      default: () => {
        return [];
      },
    },
    // 已选指标数组
    selectedIndicator: {
      type: Array,
      default: () => {
        return [];
      },
    },
    // 固定指标
    fixedList: {
      type: Array,
      default: () => {
        return [];
      },
    },
    // 标题旁说明文字
    titleTips: {
      type: String,
      default: null,
    },
    // 是否显示选框
    showCheck: {
      type: Boolean,
      default: true,
    },
    // 模板名
    columnName: {
      type: String,
      default: '',
    },
    // 一级目录key
    oneCloumnKey: {
      type: String,
      default: 'key',
    },
    // 一级目录Name
    oneCloumnName: {
      type: String,
      default: 'name',
    },
    // 二级目录key
    twoCloumnKey: {
      type: String,
      default: 'key',
    },
    // 二级目录Name
    twoCloumnName: {
      type: String,
      default: 'name',
    },
    // 三级目录key
    threeCloumnKey: {
      type: String,
      default: 'columnKey',
    },
    // 三级目录Name
    threeCloumnName: {
      type: String,
      default: 'columnValue',
    },
    // 菜单children字段
    menuChildKey: {
      type: String,
      default: 'info',
    },
  },
  components: { Draggable, MenuOutlined },
  data() {
    return {
      // 弹窗打开
      customizeModalVisible: false,
      // 所有指标列表
      menuArray: [],
      // 菜单展开值
      openKeys: [],
      openKeyList: [],
      // 菜单选中值
      selectedKeys: [],
      //是否加载checkbox部分
      loadCheckBox: true,
      // 排序数组
      draggableList: [],
      // 是否处于搜索状态
      isSearch: false,
      // 搜索绑定值
      searchValue: '',
      // 是否保存为常用自定义列
      saveChecked: false,
      // 自定义列名
      columnNameForm: {
        columnName: null,
      },
      // 自定义列名校验
      columnNameRules: {
        columnName: [{ required: true, message: '请输入自定义列名', trigger: 'blur' }],
      },
      // 弹窗loading
      spinLoading: false,
    };
  },
  watch: {
    modalVisible(newVal, oldVal) {
      this.customizeModalVisible = newVal === 'loading' ? true : newVal;
      if (newVal === 'loading') {
        this.spinLoading = false;
        return false;
      }
      if (this.customizeModalVisible) {
        this.initModal(this.menuList);
        setTimeout(() => {
          this.loadCheckBox = true;
        }, 50);
      }
      if (oldVal === true && newVal === false) {
        // this.onCancelClick();
        this.spinLoading = false;
      }
    },

    menuList(newVal) {
      this.initModal(newVal);
    },
    columnName(newVal) {
      this.columnNameForm.columnName = newVal || '';
      this.saveChecked = !!this.columnName;
    },
    fixedList(newVal) {
      this.initModal(this.menuList);
    },
  },
  computed: {},
  mounted() {
    this.customizeModalVisible = this.modalVisible;
    this.menuArray = _.cloneDeep(this.menuList);
  },
  methods: {
    /** 清空所有
     * @param {*}
     * @return {*}
     */
    clearAll() {
      this.draggableList = [];
      this.menuArray.forEach(item => {
        this.openKeys.push(item[this.oneCloumnKey]);
        if (this.isThreeLevelMenu(item)) {
          item[this.menuChildKey]?.forEach(childItem => {
            childItem[this.menuChildKey].forEach(gradeItem => {
              // 设置非固定指标取消选中
              if (this.isNotBelongArray(gradeItem)) {
                gradeItem.checked = false;
              }
            });
            // 设置复选框状态
            this.isIndeterminate(childItem);
          });
        } else {
          item[this.menuChildKey]?.forEach(childItem => {
            if (this.isNotBelongArray(childItem)) {
              childItem.checked = false;
            }
          });
          this.isIndeterminate(item);
        }
      });
    },
    /** 计算已选字数
     * @param {*}
     * @return {*}
     */
    checkLength(menu) {
      let count = 0;
      menu[this.menuChildKey].forEach(item => {
        item.checked && count++;
      });
      return count;
    },
    /** 弹窗确定
     * @param {*}
     * @return {*}
     */
    onOkClick() {
      const _this = this;
      if (this.saveChecked) {
        this.$refs.columnNameRef.validate().then(res => {
          this.spinLoading = true;
          if (_this.draggableList.length == 0) {
            this.$message.error('请至少选择一项指标');
            this.spinLoading = false;
            return false;
          }
          this.$emit('getTempData', _this.draggableList, this.columnNameForm.columnName);
          this.searchValue = null;
        });
      } else {
        this.spinLoading = true;
        if (_this.draggableList.length == 0) {
          this.$message.error('请至少选择一项指标');
          this.spinLoading = false;
          return false;
        }
        this.$emit('getTempData', _this.draggableList, this.columnNameForm.columnName);
        this.searchValue = null;
      }
    },

    /** 保存自定义列复选框变化
     * @param {Object} e 回调数据
     * @return {*}
     */
    saveCheckedChange(e) {
      this.columnNameRules.columnName[0].required = e.target.checked;
      if (!e.target.checked) {
        this.$refs.columnNameRef.validate();
      }
    },
    /** 输入框失去焦点校验前后空格
     * @param {String} inputName 输入框的label
     * @param {String} obj 输入框的值所属对象, 如果非对象中的字段请传递null
     * @param {String} val 输入框的值对应字段
     * @return {*}
     */
    inputBlur(inputName, obj, val) {
      let value = obj ? this[obj][val] : this[val];
      if (value && (value.indexOf(' ') === 0 || value.lastIndexOf(' ') === value.length - 1)) {
        this.$message.warning(`请不要在${inputName}前后输入空格！`);
        obj ? (this[obj][val] = this[obj][val].trim()) : (this[val] = this[val].trim());
      }
      if (value === '默认指标') {
        this[obj][val] = null;
        this.$message.warning('请不要输入默认指标');
      }
    },

    /** 初始化弹窗
     * @param {*} newVal
     * @return {*}
     */
    initModal(newVal) {
      this.menuArray = _.cloneDeep(newVal);
      this.selectedKeys = [newVal[this.oneCloumnKey]];

      this.openKeys = Array.isArray(this.openKeys) ? this.openKeys : [];
      this.draggableList = [];
      this.menuArray.forEach(item => {
        this.openKeys.push(item[this.oneCloumnKey]);
        if (this.isThreeLevelMenu(item)) {
          item[this.menuChildKey]?.forEach(childItem => {
            childItem[this.menuChildKey].forEach(gradeItem => {
              // 设置已选中指标选中
              if (this.isBelongArray(gradeItem)) {
                gradeItem.checked = true;
              }
              // 设置固定指标选中
              if (!this.isNotBelongArray(gradeItem)) {
                !gradeItem.checked && (gradeItem.checked = true);
              }
              // 将已选中指标添加到排序队列
              gradeItem.checked && this.isNotBelongArray(gradeItem) && this.draggableList.push(gradeItem);
            });
            // 设置复选框状态
            this.isIndeterminate(childItem);
          });
        } else {
          item[this.menuChildKey]?.forEach(childItem => {
            if (this.isBelongArray(childItem)) {
              childItem.checked = true;
            }
            childItem.checked && this.isNotBelongArray(childItem) && this.draggableList.push(childItem);
          });
          this.isIndeterminate(item);
        }
      });
      this.draggableList = _.uniqWith(this.draggableList, _.isEqual);
      this.openKeyList = _.cloneDeep(this.openKeys);
    },
    /** 是否为三级目录
     * @param {*} menu
     * @return {*}
     */
    isThreeLevelMenu(menu) {
      return menu?.[this.menuChildKey]?.[0]?.[this.menuChildKey]?.[0];
    },
    /** 是否处于半选状态
     * @param {*} menu
     * @return {*}
     */
    isIndeterminate(menu) {
      let checkedAll = menu[this.menuChildKey]?.every(item => {
        return item.checked;
      });
      let checked = menu[this.menuChildKey]?.some(item => {
        return item.checked;
      });
      if (!checked) {
        menu.checkedAll = false;
      } else if (checkedAll) {
        menu.checkedAll = true;
      }
      return checked && !checkedAll;
    },
    /** 全选框变化
     * @param {*}
     * @return {*}
     */
    checkAllChange(menu) {
      if (menu[this.menuChildKey]) {
        menu[this.menuChildKey]?.forEach(childMenu => {
          // 排除固定列表中指标状态变化
          this.isNotBelongArray(childMenu) && (childMenu.checked = menu.checkedAll);

          menu.checkedAll
            ? this.draggableList.push(childMenu)
            : (this.draggableList = this.draggableList.filter(item => {
                return item[this.threeCloumnKey] != childMenu[this.threeCloumnKey];
              }));
        });
        this.draggableList = _.uniqWith(this.draggableList, _.isEqual);
      }
    },
    /** 值是否不属于固定列表
     * @param {*}
     * @return {*}
     */
    isNotBelongArray(menu) {
      let isNotBelong = this.fixedList.every(item => {
        let value = menu[this.threeCloumnKey] || menu[this.twoCloumnKey];
        return value != (item[this.threeCloumnKey] || item[this.twoCloumnKey]);
      });
      return isNotBelong;
    },
    /** 值是否属于已选中列表
     * @param {*} menu
     * @return {*}
     */
    isBelongArray(menu) {
      let isBelong = this.selectedIndicator.some(item => {
        let value = menu[this.threeCloumnKey] || menu[this.twoCloumnKey];
        return value == (item[this.threeCloumnKey] || item[this.twoCloumnKey]);
      });
      return isBelong;
    },
    /** 单选框发生变化
     * @param {*} menu
     * @return {*}
     */
    checkedChange(menu) {
      menu.checked
        ? this.draggableList.push(menu)
        : (this.draggableList = this.draggableList.filter(item => {
            return item[this.threeCloumnKey] != menu[this.threeCloumnKey];
          }));
      this.draggableList = _.uniqWith(this.draggableList, _.isEqual);
    },

    /** 删除右边已选指标
     * @param {Object} value 选中的指标对象
     * @return {*}
     */
    deleteDraggable(value) {
      if (value) {
        this.draggableList = this.draggableList.filter(item => {
          return item != value;
        });
        this.menuArray.forEach(item => {
          if (this.isThreeLevelMenu(item)) {
            item[this.menuChildKey].forEach(childItem => {
              childItem[this.menuChildKey].forEach(gradeItem => {
                gradeItem[this.threeCloumnKey] == value[this.threeCloumnKey] && (gradeItem.checked = false);
              });
            });
          } else {
            item[this.menuChildKey].forEach(childItem => {
              childItem[this.threeCloumnKey] == value[this.threeCloumnKey] && (childItem.checked = false);
            });
          }
        });
      }
    },
    /** 获取排序后的已选指标数组
     * @param {Array} list 已选指标数组
     * @return {*}
     */
    getDraggableList(list) {
      this.$emit('getDraggableList', list);
    },
    /** 菜单栏收缩变化
     * @param {*} type
     * @return {*}
     */
    openKeyChange(type) {
      this.openKeys = _.cloneDeep(this.openKeyList);
    },
    /** 搜索
     * @param {string*} value 搜索的值
     * @return {*}
     */
    onSearchValue(value) {
      if (value) {
        this.isSearch = true;
        this.menuArray.forEach((item, index) => {
          if (this.isThreeLevelMenu(item)) {
            item[this.menuChildKey]?.forEach((childItem, childIndex) => {
              childItem[this.menuChildKey]?.forEach(gradeItem => {
                gradeItem.hide = true;
                if (gradeItem[this.threeCloumnName].indexOf(value) !== -1) {
                  gradeItem.oneColumnKeys = item[this.oneCloumnKey];
                  gradeItem.hide = false;
                }
              });
              childItem.hide = childItem[this.menuChildKey]?.every(value => {
                return value.hide;
              });
            });
          } else {
            item[this.menuChildKey].forEach(childItem => {
              childItem.hide = true;
              if (childItem[this.threeCloumnName].indexOf(value) !== -1) {
                childItem.hide = false;
              }
            });
          }
          item.hide = item[this.menuChildKey]?.every(value => {
            return value.hide;
          });
        });
      } else {
        this.isSearch = false;
        this.menuArray.forEach(item => {
          if (this.isThreeLevelMenu(item)) {
            item[this.menuChildKey]?.forEach((childItem, childIndex) => {
              childItem[this.menuChildKey]?.forEach(gradeItem => {
                gradeItem.hide = false;
              });
              childItem.hide = false;
            });
          } else {
            item[this.menuChildKey].forEach(childItem => {
              childItem.hide = false;
            });
          }
          item.hide = false;
        });
      }
    },
    /** 弹窗关闭取消
     * @param {*}
     * @return {*}
     */
    onCancelClick() {
      this.$refs.columnNameRef.resetFields();
      setTimeout(() => {
        let _this = this;
        this.searchValue = '';
        this.draggableList = [];
        this.selectedKeys = [];
        this.saveChecked = false;
        this.$emit('getTempData', null, this.columnNameForm.columnName);
        this.columnNameForm.columnName = null;
        this.$emit('update:columnName', null);
      }, 50);
    },
    /** 列表选中右侧菜单
     * @param {String} key 选中的菜单key值
     * @param {String} parentKey 展开的菜单父key值
     * @return {*}
     */
    selectedKey(key, parentKey) {
      this.selectedKeys = key ? [key] : [parentKey];
    },
  },
};
</script>

<style lang="less" scoped>
.color45 {
  color: rgba(0, 0, 0, 0.45);
}
.color85 {
  color: rgba(0, 0, 0, 0.85);
}
.customize-column {
  position: relative;
  .loading {
    width: 200px;
    height: 200px;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -100px;
    margin-top: -100px;
  }
  // .customize-top {
  //   box-shadow: 0px 6px 5px -5px #decfcf;
  // }
  .customize-left-search {
    width: calc(100% - 0.375rem);
    margin-right: 0.375rem;
  }
  .customize-content {
    height: 16.125rem;
    width: calc(100% - 0.375rem);
    margin-right: 0.375rem;
    border: 1px solid #f0f0f0;
    border-radius: 0.125rem;
    .menu-list {
      height: 16rem !important;
      overflow-y: auto;
      overflow-x: hidden;
    }
    .customize-list {
      height: 100%;
      overflow: auto;
    }
  }
  .customize-right {
    padding: 10px 15px 0 13px;
    background: #f7f7f7;
    display: flex;
    flex-direction: column;
    .customize_title {
      display: flex;
      justify-content: space-between;
    }
    .clear_all {
      font-size: 14px;
      color: #197afb;
    }
    .ant-divider-horizontal.ant-divider-with-text-center::before,
    .ant-divider-horizontal.ant-divider-with-text-center::after {
      border-top: 1px solid #dadada;
      top: 0;
    }
    .draggable-box {
      width: 100%;
      min-height: 0.75rem;
      background: #fff;
      padding: 0.1875rem 0.375rem;
      margin-bottom: 0.25rem;
      display: flex;
      cursor: all-scroll;
      justify-content: space-between;
      align-items: center;
    }
  }
  .customize-footer {
    padding: 0.13rem;
    .flexAlignStart {
      align-items: center;
    }
    .footer-btn {
      width: 3rem;
    }
  }
}
</style>
<style lang="less">
.search-result-box {
  margin-right: 0.375rem;
  .ant-card-body {
    padding: 10px 24px 24px;
  }
}
.ant-menu-submenu-selected {
  .ant-menu-submenu-title {
    a {
      color: #1890ff !important;
    }
  }
}
.customize-column {
  .ant-modal-content {
    min-height: 20.31rem;
    .ant-modal-body {
      padding: 12px 14px 14px 12px;
      .ant-card-head {
        padding: 0;
        height: 36px;
        min-height: 36px;
        .ant-card-head-title {
          padding: 0;
          flex: 0.6;
          .title-tips {
            font-size: 0.375rem;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #4e9aff;
          }
        }
        .ant-card-extra {
          padding: 0;
          flex: 0.4;
        }
      }
      .ant-card-body {
        padding: 0.375rem 0;
        .customize-content {
          .menu-list {
            .ant-menu-item {
              margin-top: 0;
            }
            .ant-menu-item:first-child {
              border-radius: 0.125rem 0 0 0;
            }
            .ant-menu-item-selected {
              background: #197afb;
              & > a {
                color: #fff;
                font-size: 0.4375rem;
              }
            }
          }
        }

        .customize-list {
          .ant-card-head {
            min-height: 1.125rem;
            padding: 0.175rem 0.375rem 0.125rem;
            background: #f5f7fa;
          }
          .ant-card-body {
            padding: 24px 0.375rem;
          }
        }
      }
    }
  }
  .checkbox-wrapper {
    width: 100%;
    .ant-col {
      margin-bottom: 0.6315rem;
    }
    .ant-checkbox-wrapper {
      width: 100%;
      display: flex;
      align-items: center;
      .ant-checkbox + span {
        width: 100%;
      }
      .check-label {
        display: inline-block;
        width: 85%;
      }
    }
  }
  .ant-modal-close-x {
    width: 30px;
    height: 30px;
    line-height: 30px;
  }
  .search-result-box {
    border-radius: 0.125rem;
    .ant-card-body {
      padding: 0 !important;
    }
    .search-label {
      min-height: 1.125rem;
      padding: 0.175rem 0.375rem 0.125rem;
      background: #f5f7fa;
    }
    .search-result {
      padding: 0.375rem;
    }
  }
}
</style>