<!--
 * @Author: LiuXin
 * @Date: 2021-10-11 16:57:00
 * @LastEditors: LiuXin
 * @LastEditTime: 2021-10-14 09:48:02
-->
<template>
  <div class="trend_data w100 h100 filter_chart_table">
    <!-- 筛选 -->
    <div class="trend_data_filter tab_filter ">
      <a-space style="margin-bottom: 11px">
        <a-range-picker value-format="YYYY-MM-DD" separator="至" />
        <span> 对比 </span>
        <a-switch></a-switch>
        <a-range-picker value-format="YYYY-MM-DD" separator="至" />
      </a-space>
      <a-space>
        <a-select></a-select>
        <a-select></a-select>
        <a-select></a-select>
      </a-space>
    </div>
    <!-- 趋势图 -->
    <div class="trend_data_chart_wrapper w100 tab_chart_wrapper">
      <base-chart :chart-option="options" title="趋势图" style="height: 296px">
        <template #filter>
          <a-select></a-select>
          <a-select></a-select>
        </template>
      </base-chart>
    </div>
    <!-- 表格 -->
    <div class="trend_data_table_wrapper w100 tab_table_wrapper">
      <p class="module_title" style="padding-left: 12px">
        <span class="title_content"> 数据报表 </span>
        <span class="select_group">
          <a-button>自定义列</a-button>
          <a-button>下载报表</a-button>
        </span>
      </p>
      <base-table :table="table"></base-table>
    </div>
  </div>
</template>

<script>
import BaseChart from '@/views/popularize/components/BaseChart.vue';
import BaseTable from '@/views/popularize/components/BaseTable.vue';
export default {
  name: 'TrendData',
  components: { BaseTable, BaseChart },
  inject: ['advertiseDetail'],
  data() {
    return {
      options: {},
      table: {
        columns: this.advertiseDetail.trendData,
      },
    };
  },
  mounted() {
    this.getOptions();
  },
  methods: {
    // 初始化图表
    getOptions() {
      const options = {
        trigger: 'item',
        xAxis: {
          type: 'category',
          data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
        },
        yAxis: {
          type: 'value',
        },
        tooltip: { show: true, trigger: 'item' },
        legend: { data: ['line1', 'line2'], bottom: 0, left: 'center', icon: 'circle', itemWidth: 10, itemHeight: 10, textStyle: { fontSize: 12 } },

        grid: { left: 50, bottom: 50, top: 10, right: 30 },
        series: [
          {
            name: 'line1',
            data: [820, 932, 901, 934, 1290, 1330, 1320],
            type: 'line',
            smooth: true,
          },
          {
            name: 'line2',
            data: [520, 332, 501, 1034, 290, 1530, 920],
            type: 'line',
            smooth: true,
          },
        ],
      };
      this.options = options;
    },
  },
};
</script>

<style scoped lang='less' >
</style>