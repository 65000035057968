/*
 * @Author: Yran
 * @Date: 2021-11-09 14:23:37
 * @LastEditors: Yran
 * @LastEditTime: 2021-12-22 17:03:10
 */
import { siteSetOptions, promotionGoalList } from '@/config/globalData';
import { getCustomerListTT, getProductListTT, getAccountListTT, getPromoteDeptListTT, getPromoteUserListTT, getDictListTT } from '@/api/popularize/jlyq/index.js';
const columns = {
  product: [
    { dataIndex: 'cost2', title: '广告名称', slots: { customRender: 'name' } },
    { dataIndex: 'cost3', title: '操作', slots: { customRender: 'operate' } },
    { dataIndex: 'cost4', title: '账户ID' },
    { dataIndex: 'cost5', title: '产品' },
    { dataIndex: 'cost6', title: '优化师' },
    { dataIndex: 'cost7', title: '日预算(元)', slots: { customRender: 'budget' } },
    { dataIndex: 'cost8', title: '状态' },
    { dataIndex: 'cost9', title: '创建时间' },
    { dataIndex: 'cost10', title: '更新时间' },
  ],
  account: [
    { dataIndex: 'cost1', title: '', slots: { customRender: 'switch' } },
    { dataIndex: 'cost2', title: '账户名称', slots: { customRender: 'name' } },
    { dataIndex: 'cost3', title: '操作', slots: { customRender: 'operate' } },
    { dataIndex: 'cost4', title: '账户ID' },
    { dataIndex: 'cost5', title: '产品' },
    { dataIndex: 'cost6', title: '优化师' },
    { dataIndex: 'cost7', title: '日预算(元)', slots: { customRender: 'budget' } },
    { dataIndex: 'cost8', title: '状态' },
    { dataIndex: 'cost9', title: '创建时间' },
    { dataIndex: 'cost10', title: '更新时间' },
  ],
  campaign: [
    { dataIndex: 'switch', title: '', slots: { customRender: 'switch' } },
    { dataIndex: 'campaign_name', title: '推广计划名称', slots: { customRender: 'name' } },
    { dataIndex: 'operation', title: '操作', slots: { customRender: 'operate' } },
    { dataIndex: 'product_name', title: '产品' },
    { dataIndex: 'cost', title: '消耗' },
  ],
  ad: [
    { dataIndex: 'cost1', title: '', slots: { customRender: 'switch' } },
    { dataIndex: 'cost2', title: '广告名称', slots: { customRender: 'name' } },
    { dataIndex: 'cost3', title: '操作', slots: { customRender: 'operate' } },
    { dataIndex: 'cost4', title: '账户ID' },
    { dataIndex: 'cost5', title: '产品' },
    { dataIndex: 'cost6', title: '优化师' },
    { dataIndex: 'cost7', title: '日预算(元)', slots: { customRender: 'budget' } },
    { dataIndex: 'cost8', title: '状态' },
    { dataIndex: 'cost9', title: '创建时间' },
    { dataIndex: 'cost10', title: '更新时间' },
  ],
  creativity: [
    { dataIndex: 'cost1', title: '', slots: { customRender: 'switch' } },
    { dataIndex: 'cost2', title: '创意名称', slots: { customRender: 'name' } },
    { dataIndex: 'cost3', title: '操作', slots: { customRender: 'operate' } },
    { dataIndex: 'cost4', title: '账户ID' },
    { dataIndex: 'cost5', title: '产品' },
    { dataIndex: 'cost6', title: '优化师' },
    { dataIndex: 'cost7', title: '日预算(元)', slots: { customRender: 'budget' } },
    { dataIndex: 'cost8', title: '状态' },
    { dataIndex: 'cost9', title: '创建时间' },
    { dataIndex: 'cost10', title: '更新时间' },
  ],

  advertiseDetail: {
    trendData: [
      {
        dataIndex: 'cost1',
        title: '日期(对比)',
        slots: { customRender: 'operate' },
      },
      { dataIndex: 'cost2', title: '曝光量' },
      { dataIndex: 'cost3', title: '点击量' },
      { dataIndex: 'cost4', title: '点击率' },
      { dataIndex: 'cost5', title: '可转化点击次数' },
      { dataIndex: 'cost6', title: '可转化点击率' },
      { dataIndex: 'cost7', title: '可转化点击成本' },
      { dataIndex: 'cost8', title: '千次展现均价' },
    ],
    targetGroup: [
      { dataIndex: 'cost1', title: '日期(对比)' },
      { dataIndex: 'cost2', title: '曝光量' },
      { dataIndex: 'cost3', title: '点击量' },
      { dataIndex: 'cost4', title: '点击率' },
      { dataIndex: 'cost5', title: '可转化点击次数' },
      { dataIndex: 'cost6', title: '可转化点击率' },
      { dataIndex: 'cost7', title: '可转化点击成本' },
      { dataIndex: 'cost8', title: '千次展现均价' },
    ],
  },
  operationLog: [
    { dataIndex: 'cost1', title: '操作时间' },
    { dataIndex: 'cost2', title: '操作者' },
    { dataIndex: 'cost4', title: '操作平台' },
    { dataIndex: 'cost3', title: '操作' },
    { dataIndex: 'cost6', title: '操作项' },
    {
      dataIndex: 'cost7',
      title: '操作结果',
      slots: {
        customRender: 'operateResult',
      },
    },
    {
      dataIndex: 'open',
      title: '展开',
      slots: {
        customRender: 'open',
      },
    },
  ],
  // 批量修改
  batchEdit: {
    // 出价 merge:是否合并， rowSpan：合并行数
    price: (merge, rowSpan) => {
      const customCell = (record, rowIndex) => {
        return { style: { display: rowIndex == 0 ? '' : 'none', 'vertical-align': 'top' }, rowSpan: rowSpan };
      };
      return {
        advertise: [
          { dataIndex: 'adgroup_name', title: '广告名称' },
          { dataIndex: 'index2', title: '转化成本', width: 100 },
          { dataIndex: 'index3', title: '广告当前出价', width: 100 },
          { dataIndex: 'updatedPrice', title: '修改后的出价', slots: { customRender: 'updatedPrice' } },
          { dataIndex: 'editPrice', title: '修改出价', slots: { customRender: 'editPrice' }, customCell: merge ? customCell : null },
          { dataIndex: 'index6', title: '广告当前深度出价' },
          { dataIndex: 'index7', title: '深度转化成本', width: 100 },
        ],
        ad: [
          { dataIndex: 'ad_name', title: '广告名称' },
          { dataIndex: 'convert_cost', title: '转化成本', width: 100 },
          { dataIndex: 'ad_pricing', title: '广告当前出价', width: 100 },
          { dataIndex: 'updatedPrice', title: '修改后的出价', slots: { customRender: 'updatedPrice' } },
          { dataIndex: 'editPrice', title: '修改出价', slots: { customRender: 'editPrice' }, customCell: merge ? customCell : null },
          { dataIndex: 'deep_bid_type_deep_cpabid', title: '广告当前深度出价' },
          { dataIndex: 'deep_convert_cost', title: '深度转化成本', width: 100 },
        ],
      };
    },
    // 预算 merge:是否合并， rowSpan：合并行数
    budget: (merge, rowSpan) => {
      const customCell = (record, rowIndex) => {
        return { style: { display: rowIndex == 0 ? '' : 'none', 'vertical-align': 'top' }, rowSpan: rowSpan };
      };
      return {
        account: [
          { dataIndex: 'advertiser_id', title: '账户ID' },
          { dataIndex: 'daily_budget', title: '账户日预算（元）', slots: { customRender: 'budget' } },
          { dataIndex: 'cost', title: '账户当日花费（元）' },
          { dataIndex: 'editBudget', title: '修改日预算', slots: { customRender: 'editBudget' }, customCell: merge ? customCell : null, width: 300 },
        ],
        campaign: [
          { dataIndex: 'campaign_name', title: '广告组名称' },
          { dataIndex: 'budget_type', title: '广告组预算（元）', slots: { customRender: 'budget' } },
          { dataIndex: 'cost', title: '广告组当日花费（元）' },
          { dataIndex: 'editBudget', title: '修改预算', slots: { customRender: 'editBudget' }, customCell: merge ? customCell : null, width: 300 },
        ],
        ad: [
          { dataIndex: 'ad_name', title: '计划名称' },
          { dataIndex: 'bugdet', title: '计划预算（元）', slots: { customRender: 'budget' } },
          { dataIndex: 'cost', title: '计划当日花费（元）' },
          { dataIndex: 'editBudget', title: '修改预算', slots: { customRender: 'editBudget' }, customCell: merge ? customCell : null, width: 300 },
        ],
      };
    },
  },
  // 筛选
  filterMenus: [
    {
      value: '产品数据',
      label: '产品数据',
      filter: [
        {
          type: 'select',
          placeholder: '客户名称',
          value: 'customerCodes',
          searchValue: 'customerName',
          options: [],
          lazyLoad: true,
          loadOver: false,
          page: 0,
          allPages: 1,
          limit: 100,
          total: 100,
          listLabel: 'name',
          listValue: 'customerCode',
          params: {
            mediaChannel: 'TT',
          },
          func: getCustomerListTT,
        },
        {
          type: 'select',
          placeholder: '产品名称',
          value: 'productCodes',
          searchValue: 'productName',
          options: [],
          lazyLoad: true,
          loadOver: false,
          page: 0,
          allPages: 1,
          limit: 100,
          total: 1000,
          listLabel: 'productName',
          listValue: 'productCode',
          params: {
            mediaChannel: 'TT',
          },
          func: getProductListTT,
        },
      ],
    },
    {
      value: '账户数据',
      label: '账户数据',
      filter: [
        {
          type: 'select',
          placeholder: '主体名称',
          value: 'companyNames',
          searchValue: 'accountName',
          options: [],
          lazyLoad: true,
          loadOver: false,
          page: 0,
          allPages: 1,
          limit: 100,
          total: 0,
          listLabel: 'accountName',
          listValue: 'accountName',
          params: {
            mediaChannel: 'TT',
          },
          func: getAccountListTT,
        },
        {
          type: 'select',
          placeholder: '运营人员',
          value: 'userIds',
          searchValue: 'userCode',
          options: [],
          lazyLoad: true,
          loadOver: false,
          page: 0,
          allPages: 1,
          limit: 100,
          total: 0,
          listLabel: 'userCode',
          listValue: 'userId',
          func: getPromoteUserListTT,
          params: {},
        },
        {
          type: 'select',
          placeholder: '运营部门',
          value: 'deptIds',
          searchValue: 'deptName',
          options: [],
          lazyLoad: true,
          loadOver: false,
          page: 0,
          allPages: 1,
          limit: 100,
          total: 0,
          listLabel: 'deptName',
          listValue: 'deptId',
          func: getPromoteDeptListTT,
          params: {},
        },
      ],
    },
    {
      value: '广告组',
      label: '广告组',
      filter: [
        {
          type: 'select',
          placeholder: '广告组状态',
          value: 'campaignStatusCodes',
          searchValue: 'campaignStatusCodes',
          options: [],
          lazyLoad: false,
          loadOver: false,
          page: 0,
          allPages: 1,
          limit: 100,
          total: 0,
          listLabel: 'value',
          listValue: 'code',
          params: {
            type: 'CAMPAIGN_STATUS',
          },
          func: getDictListTT,
        },
        {
          type: 'select',
          placeholder: '推广目的',
          value: 'campaignLandingTypeCodes',
          searchValue: 'campaignLandingTypeCodes',
          options: [],
          lazyLoad: false,
          loadOver: false,
          page: 0,
          allPages: 1,
          limit: 100,
          total: 0,
          mode: 'multiple',
          listLabel: 'value',
          listValue: 'code',
          params: {
            type: 'CAMPAIGN_LANDINGTYPE',
          },
          func: getDictListTT,
        },
      ],
    },
    {
      value: '计划',
      label: '计划',
      filter: [
        {
          type: 'select',
          placeholder: '计划状态',
          value: 'adStatusCodes',
          searchValue: 'adStatusCodes',
          options: [],
          lazyLoad: false,
          loadOver: false,
          page: 0,
          allPages: 1,
          limit: 100,
          total: 0,
          listLabel: 'value',
          listValue: 'code',
          params: {
            type: 'AD_STATUS',
          },
          func: getDictListTT,
        },
        {
          type: 'select',
          placeholder: '首选位置',
          value: 'adInventoryTypeCodes',
          searchValue: 'adInventoryTypeCodes',
          options: [],
          lazyLoad: false,
          loadOver: false,
          page: 0,
          allPages: 1,
          limit: 100,
          total: 0,
          mode: 'multiple',
          listLabel: 'value',
          listValue: 'code',
          params: {
            type: 'AD_INVENTORY_TYPE',
          },
          func: getDictListTT,
        },
        {
          type: 'select',
          placeholder: '平台',
          value: 'adPlatformCodes',
          searchValue: 'adPlatformCodes',
          options: [],
          lazyLoad: false,
          loadOver: false,
          page: 0,
          allPages: 1,
          limit: 100,
          total: 0,
          listLabel: 'value',
          listValue: 'code',
          params: {
            type: 'AD_PLATFORM',
          },
          func: getDictListTT,
        },
        {
          type: 'select',
          placeholder: '审核意见',
          value: 'adRejectCodes',
          searchValue: 'adRejectCodes',
          options: [],
          lazyLoad: false,
          loadOver: false,
          page: 0,
          allPages: 1,
          limit: 100,
          total: 0,
          mode: 'multiple',
          listLabel: 'value',
          listValue: 'code',
          params: {
            type: 'BID',
          },
          func: getDictListTT,
        },
        {
          type: 'select',
          placeholder: '学习期状态',
          value: 'adLearningPhaseCodes',
          searchValue: 'adLearningPhaseCodes',
          options: [],
          lazyLoad: false,
          loadOver: false,
          page: 0,
          allPages: 1,
          limit: 100,
          total: 0,
          listLabel: 'value',
          listValue: 'code',
          mode: 'multiple',
          params: {
            type: 'AD_LEARN_PHASE',
          },
          func: getDictListTT,
        },
        {
          type: 'select',
          placeholder: '诊断状态',
          value: 'adSceneTextCodes',
          searchValue: 'adSceneTextCodes',
          options: [],
          lazyLoad: false,
          loadOver: false,
          page: 0,
          allPages: 1,
          limit: 100,
          total: 0,
          listLabel: 'value',
          listValue: 'code',
          mode: 'multiple',
          params: {
            type: 'AD_SCENE_TEXT',
          },
          func: getDictListTT,
        },
        { type: 'rangePicker', title: '创建时间', placeholder: '创建时间', value: 'ADUploadTIme' },
      ],
    },
    // {
    //   value: '高级筛选',
    //   label: '高级筛选',
    //   type: 'Array',
    //   filter: [
    //     {
    //       type: 'array',
    //       value: 'advancedList',
    //       key: '0',
    //       options: [
    //         { type: 'select', placeholder: '请选择指标', value: 'indexKey', options: [{ label: '今日消耗', value: 'text' }] },
    //         {
    //           type: 'select',
    //           placeholder: '大于',
    //           value: 'type',
    //           width: '90px',
    //           options: [
    //             { label: '大于', value: '1' },
    //             { label: '小于', value: '2' },
    //             { label: '等于', value: '3' },
    //             { label: '大于等于', value: '4' },
    //             { label: '小于等于', value: '5' },
    //           ],
    //         },
    //         { type: 'input', placeholder: '请输入数值', value: 'value' },
    //       ],
    //     },
    //   ],
    // },
  ],
  // 固定项
  fixedOptions: {
    product: [{ aliasKey: 'product_name', columnKey: 'product_name', columnValue: '产品名称', id: 481, isDefault: 1, necessary: 'product', oneColumnKeys: 'SXZB', orderName: 2, tabOrder: 1 }],
    account: [
      {
        aliasKey: 'advertiser_name',
        columnKey: 'advertiser_name',
        columnValue: '账户名称',
        id: 483,
        isDefault: 1,
        necessary: 'account',
        oneColumnKeys: 'SXZB',
        orderName: 1,
        tabOrder: 2,
      },
      { aliasKey: 'advertiser_id', columnKey: 'advertiser_id', columnValue: '账户ID', id: 484, isDefault: 1, necessary: 'account', oneColumnKeys: 'SXZB', orderName: 2, tabOrder: 2 },
    ],
    campaign: [
      {
        aliasKey: 'campaign_name',
        columnKey: 'campaign_name',
        columnValue: '广告组名称',
        id: 812,
        isDefault: 1,
        necessary: 'campaign',
        oneColumnKeys: 'SXZB',
        orderName: 2,
        tabOrder: 3,
      },
      {
        aliasKey: 'campaign_status',
        columnKey: 'status',
        columnValue: '广告组状态',
        id: 816,
        isDefault: 1,
        necessary: 'campaign',
        oneColumnKeys: 'SXZB',
        orderName: 6,
        tabOrder: 3,
      },
    ],
    ad: [
      {
        aliasKey: 'ad_name',
        columnKey: 'ad_name',
        columnValue: '计划名称',
        id: 818,
        isDefault: 2,
        necessary: 'ad',
        oneColumnKeys: 'SXZB',
        orderName: 1,
        tabOrder: 4,
      },
      { aliasKey: 'ad_status', columnKey: 'status', columnValue: '计划状态', id: 820, isDefault: 2, necessary: 'ad', oneColumnKeys: 'SXZB', orderName: 3, tabOrder: 4 },
    ],
    creativity: [
      {
        aliasKey: 'creative_status',
        columnKey: 'status',
        columnValue: '创意状态',
        id: 846,
        isDefault: 2,
        oneColumnKeys: 'SXZB',
        orderName: 2,
        tabOrder: 5,
      },
    ],
  },
  // 出价对应字典表
  bidDictionary: {
    bid_mode: [
      { text: 'CPC', value: 'CPC' },
      { text: 'CPA', value: 'CPA' },
      { text: 'CPM', value: 'CPM' },
      { text: 'oCPC', value: 'OCPC' },
      { text: 'oCPM', value: 'OCPM' },
    ],
    optimization_goal: [
      { value: 'NONE', text: 'none' },
      { value: 'BRAND_CONVERSION', text: '指定页面曝光' },
      { value: 'FOLLOW', text: '关注' },
      { value: 'CLICK', text: '点击' },
      { value: 'IMPRESSION', text: '曝光' },
      { value: 'APP_DOWNLOAD', text: '下载' },
      { value: 'APP_ACTIVATE', text: '激活' },
      { value: 'APP_REGISTER', text: '注册' },
      { value: 'ONE_DAY_RETENTION', text: '次日留存' },
      { value: 'APP_PURCHASE', text: '付费次数' },
      { value: 'ECOMMERCE_ORDER', text: '下单' },
      { value: 'ECOMMERCE_CHECKOUT', text: 'H5 付费次数（待废弃）' },
      { value: 'LEADS', text: '表单预约（微信流量，待废弃）' },
      { value: 'ECOMMERCE_CART', text: '加入购物车' },
      { value: 'PROMOTION_CLICK_KEY_PAGE', text: 'H5 注册（待废弃）' },
      { value: 'VIEW_COMMODITY_PAGE', text: '商品详情页浏览' },
      { value: 'ONLINE_CONSULTATION', text: '在线咨询' },
      { value: 'TELEPHONE_CONSULTATION', text: '电话拨打' },
      { value: 'PAGE_RESERVATION', text: '表单预约' },
      { value: 'DELIVERY', text: '发货' },
      { value: 'MESSAGE_AFTER_FOLLOW', text: '公众号内发消息' },
      { value: 'CLICK_MENU_AFTER_FOLLOW', text: '公众号内点击菜单栏' },
      { value: 'PAGE_EFFECTIVE_ONLINE_CONSULT', text: '有效在线咨询(已废弃)' },
      { value: 'PAGE_EFFECTIVE_PHONE_CALL', text: '有效电话拨打(已废弃)' },
      { value: 'CONFIRM_EFFECTIVE_LEADS_CONSULT', text: '有效在线咨询（待废弃）' },
      { value: 'CONFIRM_EFFECTIVE_LEADS_PHONE', text: '有效电话拨打（待废弃）' },
      { value: 'LEADS_COLLECT', text: '综合线索收集' },
      { value: 'FIRST_PURCHASE', text: '首次付费' },
      { value: 'APPLY', text: '进件' },
      { value: 'PRE_CREDIT', text: '预授信' },
      { value: 'CREDIT', text: '授信' },
      { value: 'WITHDRAW_DEPOSITS', text: '提现' },
      { value: 'PROMOTION_VIEW_KEY_PAGE', text: '关键页面访问' },
      { value: 'MOBILE_APP_CREATE_ROLE', text: '小游戏创角' },
      { value: 'CANVAS_CLICK', text: '跳转按钮点击' },
      { value: 'PROMOTION_CLAIM_OFFER', text: '领券' },
      { value: 'ECOMMERCE_ADD_TO_WISHLIST', text: '商品收藏' },
      { value: 'CONFIRM_EFFECTIVE_LEADS_RESERVATION', text: '有效表单预约（待废弃）' },
      { value: 'PAGE_RECEIPT', text: '签收' },
      { value: 'PAGE_SCAN_CODE', text: '加企业微信客服' },
      { value: 'SELECT_COURSE', text: '选课' },
      { value: 'CONFIRM_POTENTIAL_CUSTOMER_PHONE', text: '电话潜在客户' },
      { value: 'MOBILE_APP_AD_INCOME', text: '广告变现' },
      { value: 'MOBILE_APP_ACCREDIT', text: '小游戏授权' },
      { value: 'PURCHASE_MEMBER_CARD', text: '首次会员购买' },
      { value: 'PAGE_CONFIRM_EFFECTIVE_LEADS', text: '有效综合线索' },
      { value: 'ADD_DESKTOP', text: '快应用加桌面' },
      { value: 'RESERVATION', text: '微信流量预约行为' },
      { value: 'FIRST_ECOMMERCE_ORDER', text: '首次下单' },
      { value: 'FIRST_TWENTY_FOUR_HOUR_ECOMMERCE_ORDER', text: '24 小时下单' },
      { value: 'LIKE', text: '点赞' },
      { value: 'EXTERNAL_LINK_CLICK', text: '外链点击' },
      { value: 'BUY_COUPONS', text: '购券' },
      { value: 'LEAVE_INFORMATION', text: '留资' },
      { value: 'CORE_ACTION', text: '关键行为' },
      { value: 'ONE_DAY_RETENTION_RATIO', text: '次留率' },
      { value: 'PROMOTION_READ_ARTICLE', text: '阅读文章' },
    ],
  },
};
export default columns;
