<!--
 * @Author: Yran
 * @Date: 2021-10-12 11:07:09
 * @LastEditors: Yran
 * @LastEditTime: 2021-12-22 10:42:51
-->
<template>
  <div class="filter_wrapper">
    <div class="basic_filter flexJustifyBetween w100">
      <div class="flex">
        <a-button class="mr12" style="120px" :class="{ active: showMoreFilters, fold_btn: showMoreFilters, unfold_btn: !showMoreFilters }" @click="changeMoreFilters" :disabled="disabled">
          <span class="btn_text_wrapper">
            <svg-icon icon-class="filter" class-name="menu_icon filter"></svg-icon>
            <svg-icon icon-class="filter_active" class-name="menu_icon filter_active"></svg-icon>
            <span class="color999 pl5 font12">所有筛选</span>
          </span>

          <DoubleRightOutlined class="fold-icon" style="color: #999" />
        </a-button>
        <a-select v-if="filterSource == 'TXGG'" :disabled="disabled" v-model:value="filterOptions.filterName" style="width: 88px" @change="filterOptions.filterValue = null">
          <a-select-option value="productName">产品</a-select-option>
          <a-select-option value="advertiser" v-if="!(selectTabKey == 'product')">账户</a-select-option>
          <a-select-option value="campaign" v-if="!(selectTabKey == 'product' || selectTabKey == 'account')">推广计划</a-select-option>
          <a-select-option value="adgroup" v-if="selectTabKey == 'creativity' || selectTabKey == 'advertise'">广告</a-select-option>
        </a-select>
        <a-select v-else-if="filterSource == 'JLYQ'" :disabled="disabled" v-model:value="filterOptions.filterName" style="width: 88px" @change="filterOptions.filterValue = null">
          <a-select-option value="productName">产品</a-select-option>
          <a-select-option value="advertiser" v-if="!(selectTabKey == 'product')">账户</a-select-option>
          <a-select-option value="campaign" v-if="!(selectTabKey == 'product' || selectTabKey == 'account')">广告组</a-select-option>
          <a-select-option value="ad" v-if="selectTabKey == 'creativity' || selectTabKey == 'ad'">计划</a-select-option>
        </a-select>
        <a-select v-if="filterSource == 'CLYQ'" :disabled="disabled" v-model:value="filterOptions.filterName" style="width: 88px" @change="filterOptions.filterValue = null">
          <a-select-option value="productName">产品</a-select-option>
          <a-select-option value="advertiser" v-if="!(selectTabKey == 'product')">账户</a-select-option>
          <a-select-option value="campaign" v-if="!(selectTabKey == 'product' || selectTabKey == 'account')">广告计划</a-select-option>
          <a-select-option value="unit" v-if="selectTabKey == 'creativity' || selectTabKey == 'ad'">广告组</a-select-option>
        </a-select>
        <a-input-search class="mr12" :disabled="disabled" v-model:value="filterValue" :placeholder="getFilterPlaceholder" allow-clear style="width: 176px" @search="onInputSearch" />
        <a-checkbox class="lineHeight32" :disabled="disabled" v-model:checked="filterOptions.isNotData" @change="onFilterBtnClick">过滤无数据</a-checkbox>
      </div>
      <a-range-picker v-model:value="filterOptions.uploadTime" style="height: 32px; width: 256px" :placeholder="['开始日期', '结束日期']" separator="至" :format="[dateRangeFormat, dateRangeFormat]" :disabled-date="disabledDate" @change="onFilterBtnClick" :allow-clear="false">
        <template #suffixIcon> <CalendarTwoTone /> </template>
      </a-range-picker>
    </div>
    <transition name="show-more">
      <div v-if="showMoreFilters" class="mark" @click.self="onFilterBtnClick">
        <div class="more_filter">
          <a-menu class="filter_menus" v-model:selectedKeys="selectedKeys" mode="inline" @click="changeFilterMenu">
            <a-menu-item v-for="menu in filterMenus" :key="menu.value">{{ menu.label }}</a-menu-item>
          </a-menu>
          <!-- 筛选 -->
          <div class="filter_box_wrapper">
            <div class="filter_box" id="filterBox">
              <div v-for="menu in filterMenus" :key="menu.value" :id="menu.value">
                <p class="menu_name" v-if="menu.filter">
                  {{ menu.value }}
                  <span class="add_filter cursorPoi" v-if="menu.label == '高级筛选'" @click="addNewFilterItem"><PlusCircleOutlined style="color: #5996ff" />添加条件</span>
                </p>
                <div class="flexWrap" v-if="menu.filter">
                  <div class="filter_form_item mr12 mb24" v-for="(filter, index) in menu.filter" :key="index">
                    <!-- 选择项title -->
                    <span class="filter_title" v-if="filter.title">{{ filter.title }}:</span>
                    <!-- 下拉框 -->
                    <SelectMultiple :disabled="disabled" :select-loading="filter.loading" :select-data="filterOptions[filter.value]" show-arrow :mode="filter.mode" :max-tag-count="1" class="select_multiple" :style="{ width: filter.width || '176px' }" v-if="filter.type == 'select'" v-model:value="filterOptions[filter.value]" :placeholder="filter.placeholder" :select-options="filter.options" option-label-prop="label" :allow-clear="true" show-search @search="value => handleSearch(value, filter)" :filter-option="false" :dropdown-match-select-width="false" :lazy-load="filter.lazyLoad" :load-over="filter.loadOver" @selectFocus="onSelectFocus(filter)" @popupScroll="filter.lazyLoad && (e => onSelectScroll(e, filter))" @blur="handleSearch(null, filter)"></SelectMultiple>
                    <!-- 日期选择框 -->
                    <a-range-picker :disabled="disabled" v-if="filter.type == 'rangePicker'" v-model:value="filterOptions[filter.value]" style="height: 32px" :placeholder="['开始日期', '结束日期']" separator="至" :format="[dateRangeFormat, dateRangeFormat]" :disabled-date="disabledDate" />
                    <!-- 输入框 -->
                    <a-input :disabled="disabled" :style="{ width: filter.width || '176px', height: '32px' }" v-if="filter.type == 'input'" v-model:value="filterOptions[filter.value]" :placeholder="filter.placeholder" />
                  </div>
                </div>
                <div v-if="menu.type == 'Array'">
                  <div class="flexAlignCenter mb24" v-for="(filter, index) in menu.filter" :key="index">
                    <div class="filter_form_item mr12" v-for="(filterChild, childIndex) in filter.options" :key="childIndex">
                      <!-- 选择项title -->
                      <span class="filter_title" v-if="filterChild.title">{{ filterChild.title }}</span>
                      <!-- 下拉框 -->
                      <SelectMultiple :disabled="disabled" :select-loading="filter.loading" :select-data="filterOptions[filter.value + '' + filter.key][filterChild.value]" show-arrow :mode="filterChild.mode" :max-tag-count="1" :style="{ width: filterChild.width || '176px' }" v-if="filterChild.type == 'select'" v-model:value="filterOptions[filter.value + '' + filter.key][filterChild.value]" :placeholder="filterChild.placeholder" :select-options="filterChild.options" option-label-prop="label" :dropdown-match-select-width="false" :allow-clear="true" show-search @search="value => handleSearch(value, filter)" :filter-option="false" @blur="handleSearch(null, filter)"></SelectMultiple>
                      <!-- 日期选择框 -->
                      <a-range-picker :disabled="disabled" v-if="filterChild.type == 'rangePicker'" v-model:value="filterOptions[filter.value + '' + filter.key][filterChild.value]" style="height: 32px" :placeholder="['开始日期', '结束日期']" separator="至" :format="[dateRangeFormat, dateRangeFormat]" :disabled-date="disabledDate" />
                      <!-- 输入框 -->
                      <a-input :style="{ width: filterChild.width || '176px', height: '32px' }" v-if="filterChild.type == 'input'" v-model:value="filterOptions[filter.value + '' + filter.key][filterChild.value]" :placeholder="filterChild.placeholder" />
                      <DeleteOutlined :disabled="disabled" v-if="filterChild.type == 'delete'" class="cursorPoi" @click="deleteFilter(filter.key)" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="filter_footer">
              <a-button class="btn filter-btn mr20" type="primary" @click="onFilterBtnClick" :disabled="disabled">确认</a-button>
              <a-button class="btn cancen-btn" @click="onResetBtnClick">重置</a-button>
            </div>
          </div>
        </div>
      </div>
    </transition>
    <div class="filter_show">
      <!-- 显示已筛选 -->
      <template v-for="menu in defaultFilterOptions[filterSource]" :key="menu.value">
        <a-tag v-if="filterOptions[menu.value]" :closable="!disabled" @close="e => closeTag(e, menu, 'noArray')" class="filter_show_tag">
          <div class="flexWrap">
            <!-- 选择项title -->
            <span class="filter_title">{{ menu.label }}：</span>
            <!-- 输入框 -->
            <a-input :disabled="disabled" :style="{ width: '120px', height: '30px !important', minHeight: '24px' }" v-model:value="filterOptions[menu.value]" placeholder="请输入" @keydown.enter="onFilterBtnClick" />
          </div>
        </a-tag>
      </template>
      <template v-for="menu in filterMenus" :key="menu.key">
        <template v-for="(filter, index) in menu.filter" :key="index">
          <a-tag :closable="!disabled" v-if="showFilterTag(menu, filter)" @close="e => closeTag(e, filter, 'noArray')" class="filter_show_tag">
            <div class="flexWrap" v-if="menu.filter">
              <!-- 选择项title -->
              <span class="filter_title" v-if="filter.placeholder">{{ filter.placeholder }}：</span>
              <!-- 下拉框 -->
              <SelectMultiple :disabled="disabled" :select-loading="filter.loading" :select-data="filterOptions[filter.value]" show-arrow :mode="filter.mode" :max-tag-count="1" :style="{ width: filter.mode == 'multiple' ? '200px' : filter.width || '100px', height: '30px !important', minHeight: '24px' }" v-if="filter.type == 'select'" v-model:value="filterOptions[filter.value]" :placeholder="filter.placeholder" :select-options="filter.options" option-label-prop="label" :lazy-load="filter.lazyLoad" :load-over="filter.loadOver" @selectFocus="onSelectFocus(filter)" @popupScroll="filter.lazyLoad && (e => onSelectScroll(e, filter))" show-search @search="value => handleSearch(value, filter)" :filter-option="false" :dropdown-match-select-width="false" @change="onFilterBtnClick" @blur="handleSearch(null, filter)"></SelectMultiple>
              <!-- 日期选择框 -->
              <a-range-picker :disabled="disabled" v-if="filter.type == 'rangePicker'" v-model:value="filterOptions[filter.value]" style="height: 30px !important; min-height: '24px'" :placeholder="['开始日期', '结束日期']" separator="至" :format="[dateRangeFormat, dateRangeFormat]" :disabled-date="disabledDate" @change="onFilterBtnClick" />
              <!-- 输入框 -->
              <a-input :disabled="disabled" :style="{ width: filter.width || '100px', height: '30px !important', minHeight: '24px' }" v-if="filter.type == 'input'" v-model:value="filterOptions[filter.value]" :placeholder="filter.placeholder" @keydown.enter="onFilterBtnClick" />
            </div>
          </a-tag>
        </template>
        <a-tag :closable="!disabled" v-if="menu.type == 'Array' && filterOptions.advancedList0.indexKey" @close="e => closeTag(e, menu, 'array')" class="filter_show_tag">
          <div class="flex filter_array">
            <span class="filter_title" v-if="menu.label">{{ menu.label }}：</span>
            <a-popconfirm placement="bottom" ok-text="确认" cancel-text="清空" @confirm="onFilterBtnClick" @cancel="onAdvancedCancel" overlay-class-name="filter_advanced_select">
              <template #icon> <span style="opacity: 0">icon</span> </template>
              <template #title>
                <!-- 选择项title -->
                <span class="add_filter cursorPoi" v-if="menu.label == '高级筛选'" @click="addNewFilterItem"><PlusCircleOutlined style="color: #5996ff" />添加条件</span>
                <div class="flexAlignCenter mb24 mt10" v-for="(filter, newIndex) in menu.filter" :key="newIndex">
                  <div class="filter_form_item mr12" v-for="(filterChild, childIndex) in filter.options" :key="childIndex">
                    <span class="filter_title" v-if="filterChild.title">{{ filterChild.title }}</span>
                    <!-- 下拉框 -->
                    <SelectMultiple :disabled="disabled" :select-loading="filter.loading" :select-data="filterOptions[filter.value + '' + filter.key][filterChild.value]" show-arrow :mode="filterChild.mode" :max-tag-count="1" :style="{ width: filterChild.width || '176px' }" v-if="filterChild.type == 'select'" v-model:value="filterOptions[filter.value + '' + filter.key][filterChild.value]" :placeholder="filterChild.placeholder" :select-options="filterChild.options" option-label-prop="label" :dropdown-match-select-width="false" show-search @search="value => handleSearch(value, filter)" :filter-option="false" @blur="handleSearch(null, filter)"></SelectMultiple>
                    <!-- 日期选择框 -->
                    <a-range-picker :disabled="disabled" v-if="filterChild.type == 'rangePicker'" v-model:value="filterOptions[filter.value + '' + filter.key][filterChild.value]" style="height: 32px" :placeholder="['开始日期', '结束日期']" separator="至" :format="[dateRangeFormat, dateRangeFormat]" :disabled-date="disabledDate" />
                    <!-- 输入框 -->
                    <a-input ref="input" :disabled="disabled" :style="{ width: filterChild.width || '176px', height: '32px' }" v-if="filterChild.type == 'input'" v-model:value="filterOptions[filter.value + '' + filter.key][filterChild.value]" :placeholder="filterChild.placeholder" />
                    <DeleteOutlined v-if="filterChild.type == 'delete'" class="cursorPoi" @click="deleteFilter(filter.key)" />
                  </div>
                </div>
              </template>
              <div class="filter_advanced">
                <span v-for="filterChild in menu.filter[0]?.options" :key="filterChild.value" class="pr5">{{ filterChild.options ? columnMap(filterChild.options, filterOptions[menu.filter[0].value + '' + menu.filter[0].key][filterChild.value], 'value', 'label') : filterOptions[menu.filter[0].value + '' + menu.filter[0].key][filterChild.value] }}</span>
                <span class="filter_advanced_num">+{{ menu.filter.length }}</span>
              </div>
            </a-popconfirm>
          </div>
        </a-tag>
      </template>
      <span v-if="showFilterClear" class="color999 font12 cursorPoi" style="height: 36px; line-height: 36px" @click="onResetBtnClick">清空</span>
    </div>
  </div>
</template>
<script>
import { DoubleRightOutlined, PlusCircleOutlined, DeleteOutlined, CalendarTwoTone } from '@ant-design/icons-vue';
import moment from 'moment';
import columns from '@/views/popularize/assets/jlyqColumns.js';

import SelectMultiple from '@/components/selectMultiple/index.vue';
export default {
  emits: ['toBottom', 'onSelectFocus', 'getFilterOptions', 'handleSearch', 'update:filterOptionsList'],
  components: { DoubleRightOutlined, PlusCircleOutlined, DeleteOutlined, CalendarTwoTone, SelectMultiple },
  props: {
    filterSource: {
      type: String,
      default: 'TXGG',
    },
    filterMenu: {
      type: Array,
      default: () => {
        return [];
      },
    },
    selectTabKey: {
      type: String,
      default: '',
    },
    filterOptionsList: {
      type: Object,
      default: () => {
        return {
          filterName: 'productName',
          advancedList0: {},
          advanced: null,
          uploadTime: [moment(), moment()],
        };
      },
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    getFilterPlaceholder() {
      let placeholderList = {
        productName: '请输入产品名称',
        advertiser: '请输入账户ID或名称',
        campaign: this.filterSource == 'TXGG' ? '请输入推广计划ID或名称' : this.filterSource == 'JLYQ' ? '请输入广告组ID或名称' : '请输入广告计划ID或名称',
        ad: this.filterSource == 'TXGG' ? '请输入广告ID或名称名称' : '请输入计划ID或名称',
        unit: '请输入广告组ID或名称',
      };

      return placeholderList[this.filterOptions.filterName] || '请输入产品名称';
    },
    /** 是否显示清空
     * @param {*}
     * @return {*}
     */
    showFilterClear() {
      let flag = false;
      this.filterMenus.forEach(menu => {
        menu.filter.forEach(item => {
          if (Array.isArray(this.filterOptions[item.value]) ? this.filterOptions[item.value][0] : this.filterOptions[item.value]) {
            flag = true;
          }
        });
      });
      Object.keys(this.filterOptions)?.forEach(key => {
        if (key.indexOf('advancedList') !== -1 && this.filterOptions[key]?.indexKey) {
          flag = true;
        }
      });
      if (this.filterOptions.adgroup || this.filterOptions.ad || this.filterOptions.campaign || this.filterOptions.advertiser || this.filterOptions.productName) {
        flag = true;
      }
      this.disabled && (flag = false);

      return flag;
    },
  },
  mounted() {
    let filterBox = document.getElementById('filterBox');
    if (filterBox) {
      filterBox.addEventListener('scroll', this.handleScroll, true);
    }
    this.filterMenus = this.filterMenu;
  },
  data() {
    return {
      newFilterItem: {
        type: 'array',
        value: 'advancedList',
        options: [
          {
            type: 'select',
            placeholder: '且',
            value: 'relation',
            width: '72px',
            options: [
              { label: '且', value: 'and' },
              { label: '或', value: 'or' },
            ],
          },
          { type: 'select', placeholder: '请选择指标', value: 'indexKey', options: [{ label: 'rest', value: 'text' }] },
          {
            type: 'select',
            placeholder: '大于',
            value: 'type',
            width: '90px',
            options: [
              { label: '大于', value: '1' },
              { label: '小于', value: '2' },
              { label: '等于', value: '3' },
              { label: '大于等于', value: '4' },
              { label: '小于等于', value: '5' },
            ],
          },
          { type: 'input', placeholder: '请输入数值', value: 'value' },
          { type: 'delete' },
        ],
      },
      tableLoading: false,
      // 筛选项
      filterValue: null,
      filterOptions: {
        filterName: 'productName',
        advancedList0: {},
        advanced: null,
        uploadTime: [moment(), moment()],
      },
      // 上方四则筛选项
      defaultFilterOptions: {
        TXGG: [
          { label: '产品', value: 'productName' },
          { label: '账户', value: 'advertiser' },
          { label: '推广计划', value: 'campaign' },
          { label: '广告', value: 'adgroup' },
        ],
        JLYQ: [
          { label: '产品', value: 'productName' },
          { label: '账户', value: 'advertiser' },
          { label: '广告组', value: 'campaign' },
          { label: '计划', value: 'ad' },
        ],
        CLYQ: [
          { label: '产品', value: 'productName' },
          { label: '账户', value: 'advertiser' },
          { label: '广告计划', value: 'campaign' },
          { label: '广告组', value: 'unit' },
        ],
      },
      // 时间格式化
      dateRangeFormat: 'YYYY-MM-DD',
      // 是否显示所有筛选
      showMoreFilters: false,
      // 选中的筛选项
      selectedKeys: ['产品数据'],
      // 所有筛选项列表
      filterMenus: [],
      timer: null,
    };
  },
  watch: {
    filterMenu: {
      handler(newVal) {
        this.filterMenus = this.filterMenu;
      },
      deep: true,
    },
    selectTabKey(newVal) {
      this.$parent.setFilterMenus(newVal);
      this.filterValue = null;
      this.filterOptions.filterValue = null;
      delete this.filterOptions.filterValue;
    },

    filterOptionsList: {
      handler(newVal) {
        this.filterOptions = _.cloneDeep(newVal);
        this.$emit('getFilterOptions', this.filterOptions);
      },
      deep: true,
    },
  },
  mounted() {
    this.$parent.setFilterMenus(this.selectTabKey);
  },
  methods: {
    /**
     * @description: 是否显示已筛选项
     * @param {Object} menu 筛选项父级
     * @param {Object} filter 筛选项
     * @return {*}
     */
    showFilterTag(menu, filter) {
      return menu.type != 'Array' && (Array.isArray(this.filterOptions[filter.value]) ? this.filterOptions[filter.value][0] : this.filterOptions[filter.value]);
    },
    /**
     * @description: 下拉框搜索
     * @param {String} value 搜索词条
     * @param {Object} filter 搜索的筛选项
     * @return {*}
     */
    handleSearch(value, filter) {
      let _this = this;
      if (!this.timer) {
        this.timer = setTimeout(() => {
          _this.$emit('handleSearch', value, filter);
          _this.timer = null;
        }, 300);
      }
    },
    /**
     * @description: 下拉框获取焦点
     * @param {Object} filter 获取焦点的筛选项
     * @return {*}
     */
    onSelectFocus(filter) {
      filter.options = [];
      this.$emit('onSelectFocus', filter);
    },
    /**
     * @description: 下拉框滚动
     * @param {Object} e 滚动元素
     * @param {Object} filter 滚动的筛选项
     * @return {*}
     */
    onSelectScroll(e, filter) {
      let scrollTop = e.target.scrollTop;
      let scrollHeight = e.target.scrollHeight;
      let clientHeight = e.target.clientHeight;
      let scrollBottom = scrollHeight - clientHeight - scrollTop;
      if (scrollBottom <= 0) {
        this.$emit('toBottom', filter);
      }
    },
    /**
     * @description: 删除筛选标签
     * @param {*} e
     * @param {*} value
     * @param {*} type
     * @return {*}
     */
    async closeTag(e, value, type) {
      if (type == 'array') {
        this.onAdvancedCancel();
      } else {
        this.filterOptions[value.value] = null;
        delete this.filterOptions[value.value];
        if (value.value == this.filterOptions.filterName) {
          this.filterOptions.filterValue = null;
          delete this.filterOptions.filterValue;
          this.filterValue = null;
        }
        this.onFilterBtnClick();
      }
    },
    /** 遍历取值
     * @param {Array} columnOptions 遍历数据源
     * @param {String} columnValue 比较的数据
     * @param {String} key 比较的字段
     * @param {String} text 返回的字段
     * @return {*}
     */
    columnMap(columnOptions, columnValue, key = 'value', text = 'text') {
      if (columnValue) {
        const returnOpt = columnOptions.filter(item => {
          return item[key] == columnValue;
        })[0];
        return returnOpt && returnOpt[text];
      }
    },
    /**
     * @description: 筛选框搜索事件
     * @return {*}
     */
    onInputSearch() {
      this.onFilterBtnClick();
      this.filterValue = '';
    },
    /** 筛选
     * @param {*}
     * @return {*}
     */
    onFilterBtnClick() {
      let advanced = this.setAdvancedData();
      this.filterOptions.advanced = advanced;
      this.showMoreFilters = false;
      this.filterOptions.filterValue = this.filterValue;
      this.$emit('update:filterOptionsList', this.filterOptions);
      this.$emit('getFilterOptions', this.filterOptions);
    },
    /** 清空高级数据
     * @param {*}
     * @return {*}
     */
    onAdvancedCancel() {
      Object.keys(this.filterOptions)?.forEach(key => {
        if (key.indexOf('advancedList') !== -1) {
          delete this.filterOptions[key];
        }
      });
      this.filterOptions.advanced = null;
      delete this.filterOptions.advanced;
      this.filterOptions.advancedList0 = {};
      this.onFilterBtnClick();
    },
    /** 处理高级筛选数据
     * @param {*}
     * @return {*}
     */
    setAdvancedData() {
      let advanced = [];
      let advancedList = {};
      Object.keys(this.filterOptions)?.forEach(key => {
        if (key.indexOf('advancedList') !== -1) {
          advancedList[key] = this.filterOptions[key];
        }
      });
      Object.keys(advancedList).forEach(key => {
        if (advancedList[key].indexKey) {
          advanced.push({ ...advancedList[key], relation: advancedList[key].relation || 'and' });
        }
      });
      return advanced;
    },
    /** 重置筛选
     * @param {*}
     * @return {*}
     */
    onResetBtnClick() {
      this.filterOptions = {
        filterName: this.filterOptions.filterName,
        advancedList0: {},
        advanced: null,
        uploadTime: this.filterOptions.uploadTime,
      };
      this.$emit('update:filterOptionsList', this.filterOptions);
      this.filterValue = null;
      this.$parent.setFilterMenus();
      this.onFilterBtnClick();
    },
    /** 修改是否显示所有筛选
     * @param {*}
     * @return {*}
     */
    changeMoreFilters() {
      this.showMoreFilters = !this.showMoreFilters;
      setTimeout(() => {
        let filterBox = document.getElementById('filterBox');
        if (filterBox) {
          filterBox.addEventListener('scroll', this.handleScroll, true);
        }
      }, 0);
    },
    /** 选择筛选菜单项
     * @param {Object} value 选中的菜单项
     * @return {*}
     */
    changeFilterMenu(value) {
      let index = this.filterMenus?.findIndex(item => {
        return item.value == value.key;
      });

      let firstOffset = document.getElementById(this.filterMenus[0]?.value)?.offsetTop;
      let valueOffset = document.getElementById(value.key)?.offsetTop;
      let filterBox = document.getElementById('filterBox');
      if (filterBox && firstOffset && valueOffset) {
        filterBox.scrollTop = valueOffset - firstOffset;
      }
      setTimeout(() => {
        this.selectedKeys = [value.key];
      }, 100);
    },
    /** 禁止选择时间
     * @param {*} current
     * @return {*}
     */
    disabledDate(current) {
      return current > moment().endOf('day');
    },
    /** 监听筛选项滚动
     * @param {*}
     * @return {*}
     */
    handleScroll() {
      // 滾動時隱藏下拉框
      let dropdownList = document.querySelectorAll('.ant-select-dropdown');
      [].forEach.call(dropdownList, function (dropdown) {
        dropdown.style.display = 'none';
      });
      let filterBox = document.getElementById('filterBox');
      if (filterBox) {
        let index = Math.floor(filterBox.scrollTop / 74);
        if (index >= this.filterMenus.length) {
          index = this.filterMenus.length - 1;
        }
        this.selectedKeys = [this.filterMenus[index]?.value];
      }
    },
    /** 添加高级筛选项
     * @param {*}
     * @return {*}
     */
    addNewFilterItem() {
      let advancedFilter = this.filterMenus[this.filterMenus.length - 1];
      if (advancedFilter && advancedFilter.filter) {
        this.newFilterItem.key = this.$UUID.v1();
        advancedFilter?.filter?.push({ ...this.newFilterItem });
        this.filterOptions[this.newFilterItem.value + '' + this.newFilterItem.key] = { type: '1', relation: 'and' };
      }
      setTimeout(() => {
        let filterBox = document.getElementById('filterBox');
        if (filterBox) {
          filterBox.scrollTop = filterBox.scrollHeight;
        }
      }, 0);
    },
    /** 删除高级筛选项
     * @param {*} key
     * @return {*}
     */
    deleteFilter(key) {
      let advancedFilter = this.filterMenus[this.filterMenus.length - 1];
      advancedFilter.filter = advancedFilter?.filter?.filter(item => {
        return item.key !== key;
      });
      delete this.filterOptions[advancedFilter.filter.value + '' + key];
    },
  },
};
</script>
<style lang="less" scoped>
.mr12 {
  margin-right: 12px;
}
.mb24 {
  margin-bottom: 24px;
}
.lineHeight32 {
  line-height: 32px;
}
.color999 {
  color: #999;
}
.filter_wrapper {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  padding: 8px 0 0px;
  .basic_filter {
    padding: 0 24px;
    margin-bottom: 8px;
    .unfold_btn .fold-icon {
      transform: rotateZ(90deg);
    }
    .fold_btn .fold-icon {
      transform: rotateZ(-90deg);
    }
  }
  .filter_form_item {
    display: flex;
    position: relative;
  }
  .show-more-enter-active,
  .show-more-leave-active {
    transition: opacity 0.3s;
  }
  .show-more-enter,
  .show-more-leave-to {
    opacity: 0;
  }
  .ant-btn.active {
    .btn_text_wrapper > span,
    .anticon {
      color: #5996ff !important;
    }
    .filter {
      display: none;
    }
    .filter_active {
      display: block;
    }
  }
  .ant-btn:hover .btn_text_wrapper > span,
  .ant-btn:hover .anticon,
  .ant-btn:focus .btn_text_wrapper > span,
  .ant-btn:focus .anticon {
    color: #5996ff !important;
  }
  .ant-btn:hover .filter,
  .ant-btn:focus .filter {
    display: none;
  }
  .ant-btn:hover .filter_active,
  .ant-btn:focus .filter_active {
    display: block;
  }

  .ant-btn,
  .btn_text_wrapper {
    display: flex;
    align-items: center;
    .filter_active {
      display: none;
    }
  }
  .mark {
    width: 100%;
    height: 95vh;
    position: absolute;
    bottom: -89vh;
    left: 0;
    background: transparent;
    z-index: 999;
  }
  .more_filter {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    position: absolute;
    background: #fff;
    z-index: 1000;
    top: 1.75rem;
    padding: 0;
    border: 1px solid rgb(240, 240, 240);
    border-left: none;
    border-right: none;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
    border-radius: 0 0 8px 8px;

    .filter_menus {
      width: 128px;
      height: 260px;
      background: rgba(248, 248, 250, 1);
      .ant-menu-item {
        cursor: pointer;
        color: #333;
        font-size: 12px;
        height: 36px;
        line-height: 36px;
        padding: 0 24px !important;
        margin: 0;
      }
      .ant-menu-item-selected {
        color: #5996ff;
      }
    }
    .filter_box_wrapper {
      padding-top: 10px;
      height: 260px;
      flex: 1;
      .filter_box {
        height: 80%;
        padding-left: 16px;
        overflow-y: auto;
        padding-bottom: 30px;
      }
      .menu_name {
        font-size: 12px;
        text-align: left;
        color: #999999;
        margin: 0 0 3px !important;
        .add_filter {
          padding-left: 10px;
          font-size: 12px;
          color: #5996ff;
        }
      }

      .filter_footer {
        width: 100%;
        padding-left: 16px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        box-shadow: 0 -1px 1px #e5e9ef;
        padding-top: 10px;
      }
    }
  }

  .filter_title {
    color: #5a4f4f;
    font-size: 12px;
    text-align: left;
    padding-right: 10px;
    display: flex;
    align-items: center;
  }
  .filter_show {
    width: 100%;
    padding: 0 24px;
    z-index: 12;
    display: flex;
    flex-wrap: wrap;
    .filter_show_tag {
      height: 34px;
      border: none;
      border-radius: 2px;
      margin-bottom: 10px;
      background: #eef2f6;
      display: flex;
      align-items: center;
      :deep(.ant-select),
      :deep(.ant-calendar-picker) {
        display: flex;
        align-items: center;
      }
      :deep(.ant-select-multiple) {
        .ant-select-selector {
          height: 30px !important;
        }
        .ant-select-selection-item {
          padding: 0;
          height: 22px !important;
          margin-top: 0;
          margin-bottom: 0;
          line-height: 22px;
        }
      }

      :deep(.ant-select-selector) {
        height: 24px !important;
        padding-top: 0;
        padding-bottom: 0;
        input {
          height: 22px !important;
        }
        .ant-select-selection-item {
          padding: 0;
          height: 22px !important;
          margin-top: 0;
          margin-bottom: 0;
          line-height: 22px;
        }
      }
      :deep(.ant-select-selection-placeholder) {
        line-height: 22px;
      }
      :deep(.ant-input) {
        height: 24px !important;
        line-height: 14px;
      }
      .filter_array {
        .filter_advanced {
          background: #fff;
          height: 24px;
          padding: 0 12px;
          line-height: 24px;
          cursor: pointer;
          position: relative;
        }
        .filter_advanced_num {
          padding: 0 10px;
          border-radius: 10px;
          background: #f5f5f5;
        }
      }
      &:hover {
        opacity: 1 !important;
      }
    }
  }
}
</style>
<style lang="less">
.filter_advanced_select {
  .ant-popover-inner-content {
    padding: 8px;
  }
}
.filter_box {
  .name_dropdown_wrapper {
    top: 36px !important;
  }
}
</style>
