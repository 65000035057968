/*
 * @Author: LiuXin
 * @Date: 2021-10-14 11:48:50
 * @LastEditors: LiuXin
 * @LastEditTime: 2021-12-24 15:28:32
 */
const options = {
  trend: data => {
    return {
      trigger: 'item',
      legend: {
        itemHeight: 2,
        data: data.legend.data,
        top: '10',
        selected: data.legend.selected,
      },
      xAxis: { type: 'category', boundaryGap: 10, axisTick: { show: false }, data: data.xAxisData },
      yAxis: [{ axisLabel: { show: true, color: '#999', formatter: value => (value >= 10000 ? value / 10000 + ' 万' : value) } }, { axisLabel: { show: true, color: '#999' } }],
      tooltip: {
        show: true,
        trigger: 'axis',
        axisPointer: { type: 'cross', animation: false },
      },
      grid: { left: 45, bottom: 25, top: 50, right: 40 },
      series: [
        {
          name: data.series1.name,
          data: data.series1.data,
          type: 'line',
          smooth: true,
          itemStyle: { color: '#197AFB' },
          yAxisIndex: '0',
          symbol: 'circle',
          symbolSize: 0,
          emphasis: {
            color: '#197AFB', //hover拐点颜色定义
            borderWidth: 5, //拐点边框大小
            lineStyle: {
              width: 2, // hover时的折线宽度
            },
          },
        },
        {
          name: data.series2.name,
          data: data.series2.data,
          type: 'line',
          smooth: true,
          itemStyle: { color: '#06C86F' },
          yAxisIndex: '1',
          symbol: 'circle',
          symbolSize: 0,
          emphasis: {
            color: '#06C86F', //hover拐点颜色定义
            borderWidth: 5, //拐点边框大小
            lineStyle: {
              width: 2, // hover时的折线宽度
            },
          },
        },
        {
          name: data.compare1.name,
          data: data.compare1.data,
          type: 'line',
          smooth: true,
          itemStyle: { color: '#197AFB' },
          lineStyle: { type: 'dashed' },
          yAxisIndex: '0',
          symbol: 'circle',
          symbolSize: 0,
          emphasis: {
            color: '#197AFB', //hover拐点颜色定义
            borderWidth: 5, //拐点边框大小
            lineStyle: {
              width: 2, // hover时的折线宽度
            },
          },
        },
        {
          name: data.compare2.name,
          data: data.compare2.data,
          type: 'line',
          smooth: true,
          itemStyle: { color: '#06C86F' },
          lineStyle: { type: 'dashed' },
          yAxisIndex: '1',
          symbol: 'circle',
          symbolSize: 0,
          emphasis: {
            color: '#06C86F', //hover拐点颜色定义
            borderWidth: 5, //拐点边框大小
            lineStyle: {
              width: 2, // hover时的折线宽度
            },
          },
        },
      ],
    };
  },
  china: data => {
    return {
      backgroundColor: '#FFFFFF',
      tooltip: { trigger: 'item' },
      grid: { left: 0, right: 0, top: 0, bottom: 0 },
      visualMap: {
        show: true,
        x: 'left',
        y: 'bottom',
        splitList: [
          { start: 500, end: 600 },
          { start: 400, end: 500 },
          { start: 300, end: 400 },
          { start: 200, end: 300 },
          { start: 100, end: 200 },
          { start: 0, end: 100 },
        ],
        color: ['#66CC33', '#00FF00', '#66FF33', '#339900', '#33CC00', '#00CC00'],
      },
      series: [
        {
          name: '随机数据',
          type: 'map',
          map: 'china',
          roam: true,
          label: { show: false },
          emphasis: { label: { show: true } },
          data: data.data,
        },
      ],
    };
  },
  province: data => {
    return {
      tooltip: { trigger: 'axis', axisPointer: { type: 'shadow' } },
      grid: { left: 50, right: 10, top: 10, bottom: 30 },
      xAxis: { type: 'value', show: false },
      yAxis: { type: 'category', data: data.yData, axisTick: { show: false }, axisLine: { show: false } },
      series: data.series,
    };
  },
  rate: data => {
    return {
      tooltip: { trigger: 'item' },
      title: { subtext: data.title, left: 'left' },
      legend: { top: 'bottom', right: 'center' },
      series: [
        {
          type: 'pie',
          radius: ['30%', '50%'],
          avoidLabelOverlap: false,
          itemStyle: { borderRadius: 10, borderColor: '#fff', borderWidth: 2 },
          label: { show: false, position: 'center' },
          labelLine: { show: false },
          data: data.seriesData,
        },
      ],
    };
  },
};
export default options;
