/*
 * @Author: Yran
 * @Date: 2021-10-20 15:10:04
 * @LastEditors: Yran
 * @LastEditTime: 2021-11-23 15:05:02
 */
import axios, { advert_prefix } from '@/utils/axios';

const apiUrl = {
  // 获取客户列表
  getCustomerList: '/advert/promote/customer/getList',
  // 获取产品列表
  getProductList: '/advert/promote/product/getList',
  // 获取主体列表
  getAccountList: '/advert/promote/account/getList',
  // 获取运营部门列表
  getPromoteDeptList: '/advert/promote/dept/getList',
  // 获取运营人员列表
  getPromoteUserList: '/advert/promote/user/getList',
  // 获取推广计划操作状态(PLAN)/推广目标类型(TARGET_TYPE)/广告操作状态(ADVERTISE)/广告状态(ADVERTISE_STATUS)/投放版位(PROMOTE)/自动扩量(EXPANSION)/出价策略(BID)/学习状态(LEARN)/赔付状态(CLAIM)
  getDictList: '/sys/dict/list',

  getTrendList: '/tencent/trend/getList', //获取趋势图数据列表
  // 获取表格数据
  getTableData: {
    product: '/tencent/productTable/getList', ///产品
    account: '/tencent/advertiserTable/getList', //账户
    manage: '/tencent/campaigns', //推广计划
    advertise: '/tencent/adGroupTable/getList', //广告
    creativity: '/tencent/adcreativeTable/getList', //创意
  },
  // 获取所有空白指标
  getIndicatorList: '/indicator/list',
  // 获取常用模板列表
  getIndicatorTempList: '/indicator/temp/list/',
  // 根据模板名称添加新的用户模板
  getIndicatorTempByName: '/indicator/temp/',
  // 修改自定义列表信息
  updateIndicator: '/indicator/update',
  // 添加自定义列表
  addIndicator: '/indicator/add',
  // 删除自定义列表
  deleteIndicator: '/indicator/delete/',

  // 批量操作
  batchOperation: {
    product: '', ///产品
    account: '', //账户
    manage: '/tencent/campaigns/status/', //推广计划
    advertise: '/tencent/adgroup/status/', //广告
    creativity: '/tencent/adcreative/status/', //创意
  },
  // -------------------------------------------------账户-----------------------------------------
  // 批量调整账户日预算
  advertiserUpdateDailyBudget: '/tencent/advertiser/update_daily_budget',
  // 账户修改备注
  advertiserUpdateRemark: '/promote/advertiser/update_save_remark',

  // -------------------------------------------------广告-----------------------------------------
  // 批量调整广告日预算
  adgroupUpdateDailyBudget: '/tencent/adgroup/update/daily_budget',
  // 广告名称修改
  adgroupUpdateName: '/tencent/adgroup/update/',
  // 广告一键起量
  acquisitionEnabled: '/tencent/adgroup/acquisition/enabled',
  // 广告组批量修改深度出价
  updateBid: '/tencent/adgroup/update/behavior_bid',
  // 广告组批量修改出价策略
  updateStrategy: '/tencent/adgroup/update/bid_strategy',
  // 广告组批量修改投放时间
  updatePostTime: '/tencent/adgroup/serving/time/update',
  // 广告组批量修改投放日期
  updatePostDate: '/tencent/adgroup/serving/date/update',
  // 广告组批量修改出价
  updateBidAmount: '/tencent/adgroup/update/bid_amount',

  // -------------------------------------------------广告计划-----------------------------------------

  // 批量修改广告计划投放方式
  campaignsSpeedMode: '/tencent/campaigns/update_speed_mode',
  // 批量修改广告计划日预算
  campaignsUpdateDailyBudget: '/tencent/campaigns/update_daily_budget',
  // 批量修改广告计划名称
  campaignsUpdateName: '/tencent/campaigns/name/update',
};

// 拼接get请求
function getUrl(url, params) {
  let str = '';
  Object.keys(params)?.forEach(key => {
    if (params[key]) {
      str += `&${key}=${params[key]}`;
    }
  });
  return url + str.replace('&', '?');
}

// 获取客户列表
export function getCustomerList(params) {
  return axios.get(getUrl(apiUrl.getCustomerList, params));
}

// 获取产品列表
export function getProductList(params) {
  return axios.get(getUrl(apiUrl.getProductList, params));
}

// 获取主体列表
export function getAccountList(params) {
  return axios.get(getUrl(apiUrl.getAccountList, params));
}

// 获取运营部门列表
export function getPromoteDeptList(params) {
  return axios.get(getUrl(apiUrl.getPromoteDeptList, params));
}

// 获取运营人员列表
export function getPromoteUserList(params) {
  return axios.get(getUrl(apiUrl.getPromoteUserList, params));
}

// 获取推广计划操作状态(PLAN)/推广目标类型(TARGET_TYPE)/广告操作状态(ADVERTISE)/广告状态(ADVERTISE_STATUS)/投放版位(PROMOTE)/自动扩量(EXPANSION)/出价策略(BID)/学习状态(LEARN)/赔付状态(CLAIM)
export function getDictList(params) {
  return axios.get(getUrl(apiUrl.getDictList, params));
}
// 获取趋势图数据列表
export function getTrendList(params) {
  return axios.post(advert_prefix + apiUrl.getTrendList, params);
}
// 获取表格数据
export function getTableData(params, type) {
  let url = advert_prefix + apiUrl.getTableData[type];
  return axios.post(url, params);
}

// 获取所有空白指标
export function getIndicatorList(params) {
  return axios.post(advert_prefix + apiUrl.getIndicatorList, params);
}
// 获取常用模板列表
export function getIndicatorTempList(userId, channel) {
  return axios.get(advert_prefix + apiUrl.getIndicatorTempList + '' + userId + '/' + channel);
}
// 根据模板名称获取用户模板详细信息
export function getIndicatorTempByName(userId, tempName, channel) {
  return axios.get(advert_prefix + apiUrl.getIndicatorTempByName + '' + userId + '/' + tempName + '/' + channel);
}
// 修改自定义列表信息
export function updateIndicator(params) {
  return axios.post(advert_prefix + apiUrl.updateIndicator, params);
}
// 添加自定义列表
export function addIndicator(params) {
  return axios.post(advert_prefix + apiUrl.addIndicator, params);
}
// 删除自定义列表
export function deleteIndicator(userId, tempName, channel) {
  return axios.delete(advert_prefix + apiUrl.deleteIndicator + '' + userId + '/' + tempName + '/' + channel);
}

// 批量操作
export function batchOperation(params, mode, type) {
  let url = advert_prefix + apiUrl.batchOperation[type] + mode;
  return axios.post(url, params);
}
// 批量一键起量
export function acquisitionEnabled(params) {
  return axios.post(advert_prefix + apiUrl.acquisitionEnabled, params);
}
// -------------------------------------------------------------账户--------------------------------------------
// 批量调整广告主日预算
export function advertiserUpdateDailyBudget(params) {
  return axios.post(advert_prefix + apiUrl.advertiserUpdateDailyBudget, params);
}
// 批量调整账户备注
export function advertiserUpdateRemark(params) {
  return axios.post(advert_prefix + apiUrl.advertiserUpdateRemark, params);
}

// -------------------------------------------------------------广告----------------------------------------------
// 批量调整广告日预算
export function adgroupUpdateDailyBudget(params) {
  return axios.post(advert_prefix + apiUrl.adgroupUpdateDailyBudget, params);
}
// 批量调整广告投放时间
export function advertiserUpdatePostTime(params) {
  return axios.post(advert_prefix + apiUrl.updatePostTime, params);
}
// 批量调整广告投放日期
export function advertiserUpdatePostDate(params) {
  return axios.post(advert_prefix + apiUrl.updatePostDate, params);
}
// 广告组批量修改出价
export function updateBidAmount(params) {
  return axios.post(advert_prefix + apiUrl.updateBidAmount, params);
}
// 编辑广告名称
export function updateAdName(params) {
  let url = advert_prefix + apiUrl.adgroupUpdateName + `${params.adgroupId}/${params.adgroupName}`;
  return axios.get(url);
}
// 广告组批量修改深度出价
export function updateBid(params) {
  return axios.post(advert_prefix + apiUrl.updateBid, params);
}
// 广告组批量修改出价策略
export function updateStrategy(params) {
  return axios.post(advert_prefix + apiUrl.updateStrategy, params);
}

// -------------------------------------------------------------计划----------------------------------------------
// 推广计划批量修改投放方式
export function updateSpeedMode(params) {
  const url = advert_prefix + apiUrl.campaignsSpeedMode + '/' + params.speedMode;
  return axios.post(url, params.ids);
}
// 推广计划批量修改日预算
export function campaignsUpdateDailyBudget(params) {
  const url = advert_prefix + apiUrl.campaignsUpdateDailyBudget;
  return axios.post(url, params);
}
// 推广计划批量修改日预算
export function campaignsUpdateName(params) {
  const url = advert_prefix + apiUrl.campaignsUpdateName;
  return axios.post(url, params);
}
