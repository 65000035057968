<!--
 * @Author: LiuXin
 * @Date: 2021-10-13 15:29:39
 * @LastEditors: LiuXin
 * @LastEditTime: 2021-12-28 16:43:29
-->
<template>
  <div class="base_info_wrapper base-info-width-img">
    <div class="img_wrapper">
      <template v-if="imgList.length">
        <CreativeBox v-for="item in imgList" :key="item.url" v-bind="item" />
      </template>
      <a-empty v-else></a-empty>
    </div>

    <div class="baseinfo_text">
      <div class="baseInfo_group" v-for="group in groupList" :key="group.groupName">
        <p class="module_title w100">
          <span class="title_content"> {{ group.groupName }} </span>
        </p>
        <div class="info_item mb10" v-for="groupItem in group.groupItemList" :key="groupItem.label">
          <div class="info_label">{{ groupItem.label }}：</div>
          <div class="info_value" v-if="groupItem.label == '首选位置'">{{ setInventoryType(groupItem) || '-' }}</div>
          <div class="info_value" v-else-if="groupItem.label == '过滤已转化用户'">{{ jlyqDict.hideIfConverted(groupItem.value) || '-' }}</div>
          <div class="info_value" v-else-if="groupItem.hasOwnProperty('editValue')">
            <span>
              {{ groupItem.value || '-' }}
              <EditOutlined @click="groupItem.visible = true" />
            </span>
            <a-popover :title="`修改${groupItem.label}`" trigger="click" :visible="groupItem.visible">
              <template #content>
                <a-textarea v-model:value="groupItem.editValue" show-count :maxlength="60" style="width: 240px" />
                <a-row type="flex" justify="space-around" class="mt10">
                  <a-button @click="handleCancel(groupItem)"> 取消</a-button>
                  <a-button type="primary" @click="handleOk(groupItem)">确定</a-button>
                </a-row>
              </template>
            </a-popover>
          </div>
          <div class="info_value" v-else>{{ groupItem.value || '-' }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CreativeBox from '@/views/popularize/components/CreativeBox.vue';
import { EditOutlined } from '@ant-design/icons-vue';
import { getBaseInfo } from '@/api/popularize/jlyq/detail.js';
import { updateAdPlanNameTT, adgroupUpdateDailyBudgetTT, updateBidAmountTT, getTableDataTT } from '@/api/popularize/jlyq/index.js';

export default {
  emits: ['editAdGroup'],
  components: { CreativeBox, EditOutlined },
  inject: ['getActiveKey', 'jlyqDict'],
  props: {
    baseInfo: { type: Object, default: () => {} },
    rowData: { type: Object, default: () => {} },
  },
  data() {
    const siteDict = {};
    JSON.parse(localStorage.getItem('promoteCodesOptionsTT') || '[]').forEach(item => {
      siteDict[item.value] = item.label;
    });
    return {
      siteDict,
      groupList: [],
      imgList: [],
      // 广告组详情信息
      elseBaseInfo: {},
    };
  },
  watch: {
    'baseInfo.campaignId': {
      async handler(val) {
        if (val) {
          const res = await getBaseInfo({ type: 'campaign', id: this.baseInfo.campaignId });
          this.elseBaseInfo = res.data || {};
          this.getGroupList();
          this.getImgList();
        }
      },
      deep: true,
    },
  },
  methods: {
    async getImgList() {
      const params = { ...JSON.parse(sessionStorage.getItem('jlyqGetTableParams')), adId: this.baseInfo.adId };
      const res = await getTableDataTT(params, 'creativity');
      const creativityList = res.data?.list || [];
      const imgList = [];
      creativityList.forEach(item => {
        imgList.push({
          imgInfo: {
            url: item.url,
            type: item.video_id ? 'video' : 'image',
            title: item.title,
          },
          status: this.jlyqDict.creativityStatus(item.creative_status),
        });
      });
      this.imgList = imgList;
    },
    setInventoryType(obj) {
      let inventoryType = '';
      if (obj.value && Array.isArray(obj.value)) {
        obj.value.forEach((item, index) => {
          inventoryType += (this.jlyqDict.inventoryType(item) || '-') + (index == obj.value.length - 1 ? '' : ',');
        });
      }
      return inventoryType;
    },
    async handleOk(groupItem) {
      let params = {};
      let res = {};
      if (groupItem.label == '预算') {
        params = [{ adId: this.baseInfo.adId, budget: groupItem.editValue }];
        res = await adgroupUpdateDailyBudgetTT(params);
      } else if (groupItem.label == '计划名称') {
        params = [{ adId: this.baseInfo.adId, name: groupItem.editValue }];
        res = await updateAdPlanNameTT(params);
      } else if (groupItem.label == '出价') {
        params = [{ adId: this.baseInfo.adId, bid: groupItem.editValue }];
        res = await updateBidAmountTT(params);
      }
      if (res.code == 0) {
        this.$message.success('编辑成功');
        groupItem.value = groupItem.editValue;
      }
      groupItem.visible = false;
    },
    handleCancel(groupItem) {
      groupItem.editValue = groupItem.value;
      groupItem.visible = false;
    },
    beforeDrawerClosed() {
      this.groupList.forEach(item => {
        item.groupItemList.forEach(groupItem => {
          groupItem.hasOwnProperty('visible') && (groupItem.visible = false);
        });
      });
    },
    getGroupList() {
      const obj = {
        APP: [
          { value: this.baseInfo.downloadType, label: '下载方式' },
          { value: this.baseInfo.download_url, label: '下载链接' },
          { value: this.baseInfo.package, label: '应用包名' },
          { value: this.baseInfo.appType, label: '下载的应用类型必填' },
          { value: this.baseInfo.download_mode, label: '优先从系统应用商店下载（下载模式）' },
          { value: this.baseInfo.openUrl, label: '直达链接' },
          { value: this.baseInfo.ulink, label: '直达备用链接' },
        ],
        LINK: [
          {
            label: '直达链接',
            value: this.baseInfo.openUrl,
          },
          { label: '直达备用链接', value: this.baseInfo.ulink },
        ],
        QUICK_APP: [
          {
            label: '快应用链接',
            value: this.baseInfo.openUrl,
          },
        ],
        LIVE: [
          {
            label: '抖音号',
            value: this.baseInfo.awemeAccount,
          },
        ],
        AWEME: [
          { label: '投放内容', value: this.baseInfo.promotionType },
          { label: '抖音号', value: this.baseInfo.awemeAccount },
          { label: '直达链接', value: this.baseInfo.openUrl },
        ],
      };
      const arr = obj[this.baseInfo.landingType] || [];
      const groupList = [
        {
          groupName: '基本情况',
          groupItemList: [
            { label: '计划名称', value: this.baseInfo.name, editValue: this.baseInfo.name, visible: false },
            { label: '计划ID', value: this.baseInfo.adId },
          ],
        },
        {
          groupName: '优化目标',
          groupItemList: [
            { label: '营销链路', value: this.jlyqDict.marketingPurpose(this.elseBaseInfo.marketingPurpose) },
            { label: '数据对接方式', value: this.baseInfo.assetIds },
            { label: '推广内容', value: this.baseInfo.assetIds },
            { label: '优化目标', value: this.baseInfo.externalAction ? this.baseInfo.externalActions : this.baseInfo.convertType },
          ],
        },
        {
          groupName: '推广内容',
          groupItemList: [
            { label: '推广目的', value: this.jlyqDict.landingType(this.elseBaseInfo.landingType) },
            { label: '广告位置', value: this.jlyqDict.inventoryCatalog(this.baseInfo.inventoryCatalog) },
            { label: '应用商店直投', value: this.jlyqDict.downloadMode(this.baseInfo.downloadMode) },
            { label: '首选位置', value: this.baseInfo.inventoryType },
            { label: '搜索快投关键词', value: this.baseInfo.feedDeliverySearch },
            { label: '投放目标', value: this.baseInfo.convertId },
            { label: '转化目标', value: this.baseInfo.convertType ? this.jlyqDict.convertType(this.baseInfo.convertType) : '-' },
            { label: '深度转化目标', value: this.jlyqDict.convertType(this.baseInfo.deepExternalAction) },
          ].concat(arr),
        },
        {
          groupName: '预算与出价',
          groupItemList: [
            { label: '预算类型', value: this.baseInfo.budgetMode },
            { label: '预算', value: this.baseInfo.budget, editValue: this.baseInfo.budget, visible: false },
            { label: '投放时间', value: this.baseInfo.startTime },
            { label: '投放时段', value: this.baseInfo.scheduleTime },
            { label: '投放场景', value: this.baseInfo.smartBidType },
            { label: '竞价策略', value: this.baseInfo.flowControlMode },
            { label: '付费方式', value: this.baseInfo.pricing },
            { label: '出价', value: this.baseInfo.bid ? `${this.baseInfo.bid}元` : '-', editValue: this.baseInfo.bid, visible: false },
            { label: '深度优化方式', value: this.jlyqDict.deepPriceType(this.baseInfo.deepBidType) },
            { label: '深度转化出价', value: this.baseInfo.deepCpabid ? this.baseInfo.deepCpabid : '-' },
          ],
        },
        {
          groupName: '用户定向',
          groupItemList: [
            { label: '定向包名称', value: this.baseInfo.audience_package_id },
            // { label: '定向包明细', value: this.baseInfo.audience },
            { label: '年龄', value: this.jlyqDict.age(this.baseInfo.audience?.age) },
            // { label: '平台', value: this.jlyqDict.platForm(this.baseInfo.audience?.platform) },
            // { label: '过滤已转化用户', value: this.jlyqDict.hideIfConverted(this.baseInfo.audience?.hideIfConverted) },
            // { label: '智能放量', value: this.baseInfo.audience?.autoExtendEnabled == 0 ? '否' : '是' },
          ],
        },
        {
          groupName: '第三方监测链接',
          groupItemList: [
            { label: '展示', value: this.baseInfo.trackUrl?.[0] },
            { label: '有效触点', value: this.baseInfo.actionTrackUrl?.[0] },
            { label: '视频播放', value: this.baseInfo.videoPlayTrackUrl?.[0] },
            { label: '视频播完', value: this.baseInfo.videoPlayDoneTrackUrl?.[0] },
            { label: '视频有效播放', value: this.baseInfo.videoPlayEffectiveTrackUrl?.[0] },
          ],
        },
      ];
      this.groupList = groupList;
    },
  },
};
</script>

<style lang="less" scoped>
.editBudget {
  width: 300px;
  .suggestion {
    margin: 10px 0;
    color: #999;
  }
}
</style>>