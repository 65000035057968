<!--
 * @Author: Yran
 * @Date: 2021-11-25 15:52:54
 * @LastEditors: Yran
 * @LastEditTime: 2021-12-23 10:47:43
-->
<template>
  <div class="execution-record-wrapper">
    <div class="toolbar-wrapper flexJustifyBetween">
      <div class="filter-select">
        <execution-filters ref="executionFilters"></execution-filters>
      </div>
      <div class="filter-other flexAlignCenter">
        <a-input-search v-if="filterType == 'home'" v-model:value="filterOptions.name" placeholder="搜索规则名称" allow-clear @search="getHostTableData(filterOptions)" class="filter-other-input"></a-input-search>
        <a-range-picker v-model:value="filterOptions.updateTime" value-format="YYYY-MM-DD" @change="updateTimeChange">
          <template #suffixIcon><CalendarTwoTone /></template>
        </a-range-picker>
      </div>
    </div>
    <div class="execution-table-wrapper">
      <a-table v-bind="table" :data-source="tableData.dataSource" :loading="tableData.tableLoading" row-key="id" @change="tableChange">
        <template v-for="column in $attrs.columns" :key="column.dataIndex">
          <!--执行操作 -->
          <a-table-column v-if="column.dataIndex == 'ruleContent'" v-bind="column">
            <template #default="{ record }">
              <span>{{ setExecContent(record.ruleContent) }}</span>
            </template>
          </a-table-column>
          <!--执行时间 -->
          <a-table-column v-else-if="column.dataIndex == 'execDate'" v-bind="column">
            <template #default="{ record }">
              <span>{{ (record.execDate || '') + ' ' + (record.execTime || '') }}</span>
            </template>
          </a-table-column>
          <!--执行结果 -->
          <a-table-column v-else-if="column.dataIndex == 'result'" v-bind="column">
            <template #default="{ record }">
              <span>{{ actions[record.result] }}</span>
            </template>
          </a-table-column>
          <!--触发详情 -->
          <a-table-column v-else-if="column.dataIndex == 'resultContent'" v-bind="column">
            <template #default="{ record }">
              <span>{{ setResultContent(record.resultContent) }}</span>
            </template>
          </a-table-column>
          <!--执行范围详情 -->
          <a-table-column v-else-if="column.dataIndex == 'scopeDetails'" v-bind="column">
            <template #default="{ record }">
              <span>{{ setScopeDetails(record) }}</span>
            </template>
          </a-table-column>
          <!--通知结果 -->
          <a-table-column v-else-if="column.dataIndex == 'messageResult'" v-bind="column">
            <template #default="{ record }">
              <span>{{ record.messageResult == 0 ? '通知失败' : record.messageResult == 1 ? '通知成功' : '-' }}</span>
            </template>
          </a-table-column>
          <!-- 其他 -->
          <a-table-column v-else v-bind="column">
            <template #default="{ record }">
              <span> {{ record?.[column.dataIndex] || '-' }}</span>
            </template>
          </a-table-column>
        </template>
      </a-table>
    </div>
  </div>
</template>

<script>
import { ClockCircleOutlined, CalendarTwoTone, HighlightOutlined, DiffOutlined, DeleteOutlined } from '@ant-design/icons-vue';
import ExecutionFilters from './ExecutionFilters.vue';
export default {
  name: 'ExecutionRecordTable',
  components: { CalendarTwoTone, ExecutionFilters },
  provide() {
    return {
      filterOptions: this.filterOptions,
    };
  },
  inject: ['filterType', 'tableData', 'table', 'getHostTableData', 'setExecContent', 'setResultContent', 'setScopeDetails', 'getSelectRow'],
  data() {
    return {
      actions: {
        '-1': '执行失败',
        0: '执行中',
        1: '执行成功',
      },
      // 筛选数据
      filterOptions: { ruleId: this.getSelectRow()?.id, channel: this.getSelectRow()?.channel },
      timer: null,
    };
  },
  computed: {},
  watch: {},
  methods: {
    /**
     * @description: 表格页码切换
     * @param {*} pagination
     * @param {*} filters
     * @param {*} sorter
     * @return {*}
     */
    tableChange(pagination, filters, sorter) {
      this.table.pagination.page = pagination.current.toString();
      this.table.pagination.pageSize = pagination.pageSize;
      this.filterOptions.page = pagination.current.toString();
      this.filterOptions.limit = pagination.pageSize.toString();
      this.getHostTableData(this.filterOptions);
    },
    /**
     * @description: 时间变化
     * @param {*} dates
     * @return {*}
     */
    updateTimeChange(dates) {
      this.filterOptions.startDate = dates?.[0];
      this.filterOptions.endDate = dates?.[1];
      this.getHostTableData(this.filterOptions);
    },
  },
};
</script>

<style lang="less" scoped>
.execution-record-wrapper {
  .toolbar-wrapper {
    .filter-select {
      :deep(.filter-select-item) {
        width: 6.75rem;
        margin-bottom: 10px;
        .ant-select {
          width: 93%;
          .ant-select-selection-item {
            max-width: 65%;
          }
        }
      }
    }
    .filter-other {
      height: 32px;

      &-input {
        width: 224px;
        margin-right: 12px;
        height: 32px;
      }
    }
  }
  .execution-table-wrapper {
    .ant-table-row:first-child {
      td:not(:nth-child(2)) > span {
        color: #000;
      }
    }
    .ant-table-row {
      height: 48px !important;
      .ant-table-row-cell-break-word {
        height: 48px;
      }
    }
  }
}
</style>