/*
 * @Author: Yran
 * @Date: 2021-11-17 15:09:53
 * @LastEditors: Yran
 * @LastEditTime: 2021-12-22 14:05:57
 */
import _ from 'lodash';

/** 点击下拉列表编辑
 * @param {String} tempName 模板名称
 * @return {*}
 */
export const editTempFunc = async (_this, tempName) => {
  if (_this.menuList.length > 0) {
    _this.operatType = 'edit';
    _this.editTempName = tempName;
    await _this.getIndicatorTempByName(tempName, false);
    _this.modalVisible = true;
  } else {
    _this.$message.error('当前口径无可设置指标，请联系后台管理员！');
  }
};

/** 点击下拉列表删除
 * @param {String} tempName 模板名称
 * @return {*}
 */
export const deleteTempFunc = (_this, tempName, func, channel) => {
  _this.operatType = 'delete';
  func(_this.userId, tempName, channel).then(res => {
    if (res.code === 0) {
      _this.$message.success('删除成功');
      _this.activeTemp = null;
      _this.getIndicatorTempList();
    }
  });
};

/** 新增自定义指标
 * @param {*}
 * @return {*}
 */
export const addTempFunc = _this => {
  _this.operatType = 'add';
  _this.editTempName = null;
  if (_this.menuList.length > 0) {
    _this.editTempName = null;
    _this.selectedIndicator = _.cloneDeep(_this.selectedSource);
    _this.modalVisible = true;
  } else {
    _this.$message.error('当前口径无可设置指标，请联系后台管理员！');
  }
};

/** 模板下拉变化
 * @param {*} tempName
 * @return {*}
 */
export const getTempNameValueFunc = async (_this, tempName, channel) => {
  _this.activeTemp = tempName;
  localStorage['activeTemp' + channel] = tempName;
  await _this.getIndicatorTempByName();
  _this.getTableData();
};

/** 处理固定列表
 * @param {*} list
 * @return {*}
 */
export const setFixedListFunc = _this => {
  _this.fixedList = [];
  _this.menuList?.forEach(item => {
    item.info?.forEach(childItem => {
      childItem.info?.forEach(gradeItem => {
        gradeItem.disabled = !(!gradeItem.necessary || gradeItem.necessary == '') && _this.activeKey?.includes(gradeItem.necessary);
        if (gradeItem.disabled) {
          _this.fixedList.push({ ...gradeItem, key: gradeItem.columnKey, label: gradeItem.columnValue, id: gradeItem.id });
        }
      });
    });
  });
};

/** 处理默认数据
 * @param {*}
 * @return {*}
 */
export const setDefaultListFunc = _this => {
  _this.indicatorSource = [];
  const allIndexDict = {};
  _this.fixedList = [];
  _this.allIndicator?.forEach(item => {
    item.info?.forEach(childItem => {
      childItem.info?.forEach(gradeItem => {
        allIndexDict[gradeItem.columnKey] = gradeItem.columnValue;
        gradeItem.disabled = _this.activeKey?.includes(gradeItem.necessary);
        gradeItem.isDefault == 1 && _this.indicatorSource.push(gradeItem);
      });
    });
  });
  // _this.setShowTableColumn(_this.indicatorSource);
  const allIndexDict1 = JSON.parse(localStorage.getItem('gdtIndexDict') || '{}');
  _this.allIndexDict = allIndexDict;
  _this.menuList = _this.allIndicator;
  _this.setFixedList();
};

/** 获取所有模板名称
 * @param {*}
 * @return {*}
 */
export const getIndicatorTempListFunc = async (_this, func, channel) => {
  let res = await func(_this.userId, channel);
  if (res.code == 0) {
    _this.tempList = [];
    if (!res.data.includes('自定义列表')) {
      _this.operatType = 'add';
      await _this.getTempData(_this.indicatorSource, '自定义列表');
      return;
    }
    res.data.forEach(item => _this.tempList.push({ tempName: item }));
    _this.activeTemp = localStorage['activeTemp' + channel] || _this.activeTemp;
    !_this.activeTemp && (_this.activeTemp = '自定义列表');
    _this.getIndicatorTempByName(_this.activeTemp);
  }
};

/** 获取选中的模板已选中指标
 * @param {*}
 * @return {*}
 */
export const getIndicatorTempByNameFunc = async (_this, tempName, setColumn = true, func, channel) => {
  let res = await func(_this.userId, tempName || _this.activeTemp || '自定义列表', channel);
  _this.selectedIndicator = res.data;
  _this.selectedSource = _.cloneDeep(res.data);

  if (setColumn) {
    _this.setShowTableColumn(_this.selectedIndicator);
  }
};

/** 关闭弹窗
 * @param {*}
 * @return {*}
 */
export const getTempDataFunc = (_this, tempUserCustoms, tempName, addFunc, updateFunc, channel) => {
  if (tempUserCustoms) {
    let indicatorBos = [];
    let postData = {};
    if (!tempName) {
      tempName = '自定义列表';
      _this.editTempName = '自定义列表';
      _this.operatType = 'edit';
    }
    _this.fixedList.forEach((item, index) => {
      indicatorBos.push({ customConfigId: item.id, orderNum: index + 1 });
    });
    tempUserCustoms.forEach((item, index) => {
      indicatorBos.push({ customConfigId: item.id, orderNum: index + 1 });
    });
    if (!_this.operatType || _this.operatType === 'add') {
      postData = {
        userId: _this.userId,
        tempName: tempName,
        indicatorBos: indicatorBos,
        mediaChannel: channel,
      };
      addFunc(postData).then(res => {
        if (res.code === 0) {
          _this.activeTemp = tempName;
          localStorage['activeTemp' + channel] = tempName;
          _this.$message.success('新增成功');
          _this.modalVisible = false;
          _this.getIndicatorTempList();
        } else {
          _this.modalVisible = 'loading';
        }
      });
    } else if (_this.operatType === 'edit') {
      postData = {
        userId: _this.userId,
        tempName: tempName,
        oldTempName: _this.editTempName,
        indicatorBos: indicatorBos,
        mediaChannel: channel,
      };
      updateFunc(postData).then(res => {
        if (res.code === 0) {
          _this.activeTemp = tempName;
          localStorage['activeTemp' + channel] = tempName;
          _this.getTableData();
          _this.$message.success('修改成功');
          _this.modalVisible = false;
          _this.getIndicatorTempList();
          _this.editTempName = null;
        } else {
          _this.modalVisible = 'loading';
        }
        _this.editTempName = null;
      });
    }
  } else {
    _this.modalVisible = false;
    _this.selectedIndicator = [];
  }
  // _this.modalVisible = false;
};

/** 设置自定义列
 * @param {*}
 * @return {*}
 */
export const setColumnFunc = (_this, arr) => {
  _this.tableSetLoading = true;
  setTimeout(() => {
    let fixedColumns = [];
    let columns = [];
    // 添加非固定列
    arr?.forEach(item => {
      // const customRender = _this.editNameList.includes(item.columnValue) ? 'name' : _this.editBudgetList.includes(item.columnValue) ? 'budget' : item.columnKey;
      !_this.isBelongArray(item) &&
        columns?.push({
          title: item.columnValue,
          dataIndex: item.aliasKey,
          sorter: item.oneColumnKeys != 'SXZB',
          sortDirections: item.oneColumnKeys != 'SXZB' ? ['descend', 'ascend'] : [],
          slots: { customRender: item.columnKey },
          key: _this.$UUID.v1(),
        });
    });
    // 添加固定列
    _this.fixedOptions[_this.activeKey]?.forEach(item => {
      fixedColumns.push({
        title: item.columnValue,
        dataIndex: item.aliasKey,
        sorter: item.oneColumnKeys != 'SXZB',
        sortDirections: item.oneColumnKeys != 'SXZB' ? ['descend', 'ascend'] : [],
        slots: { customRender: item.columnKey },
        fixed: 'left',
        width: 120,
        key: _this.$UUID.v1(),
      });
    });
    // 添加创意
    if (_this.activeKey == 'creativity') {
      fixedColumns.unshift({ title: '创意', dataIndex: 'creativity', fixed: 'left', width: 160 });
    }
    // 添加开关
    _this.showSwitch.includes(_this.activeKey) && fixedColumns.unshift({ title: '', dataIndex: 'switch', fixed: 'left', width: 50 });
    columns = _.uniqWith(columns, _.isEqual);
    _this.tableColumns = _.cloneDeep([...fixedColumns, { dataIndex: 'operate', title: '操作', fixed: 'left', width: 140 }, ...columns]);
    // 请求数据
    _this.tableSetLoading = false;
  }, 10);
};
/** 设置表格根据不同tab页显示对应列名
 * @param {*}
 * @return {*}
 */
export const setShowTableColumnFunc = (_this, array) => {
  let arr = _.cloneDeep(array);
  let index = _this.tabs.findIndex(item => {
    return _this.activeKey.includes(item.key);
  });
  arr = arr.filter(item => {
    return item && (!item.tabOrder || item.tabOrder <= index + 1);
  });
  arr = arr.filter(item => {
    return item && (!item.columnValue.includes('状态') || item.columnValue == _this.columnMap(_this.tabs, _this.activeKey, 'key', 'name') + '状态');
  });
  _this.setColumn(arr);
};
