<!--
 * @Author: LiuXin
 * @Date: 2021-10-15 14:26:35
 * @LastEditors: LiuXin
 * @LastEditTime: 2021-12-23 15:21:46
-->
<template>
  <div class="base_info_wrapper filter_chart_table w100 h100">
    <div class="base_info">
      <p class="module_title">
        <span class="title_content">基本信息 </span>
      </p>
      <a-row class="w100">
        <a-col :span="12" v-for="info in infoList" :key="info.label">
          <a-form-item :label="info.label" label-align="left" :label-col="{ span: 8 }" :wrapper-col="{ span: 16 }">
            <div v-if="info.label == '当前运营人员'">
              <template v-if="Array.isArray(info.value)">
                <span v-for="person in info.value" :key="person">
                  <template v-if="person.userCode"> {{ person.userCode }}({{ person.deptName }})&nbsp;&nbsp; </template>
                </span>
              </template>
              <span v-else-if="info.value"> {{ info.value || '-' }}({{ baseInfo.deptName }}) </span>
              <span v-else> - </span>
              <a v-if="tableTabKeys == 'product'" class="nowrap" @click="accountAssign">账号分配 >></a>
            </div>
            <div v-else-if="info.label.includes('账户日预算')">
              <span> {{ info.value == 0 ? '不限' : info.value ? `${info.value} 元/天` : '-' }} </span>
            </div>
            <div v-else-if="info.label == '账户备注'">
              <span> {{ info.value }} <EditOutlined @click="visible = true" /> </span>
              <a-popover title="修改备注" trigger="click" :visible="visible">
                <template #content>
                  <a-textarea v-model:value="info.value" show-count :maxlength="60" style="width: 240px" />
                  <a-row type="flex" justify="space-around" class="mt10">
                    <a-button @click="visible = false">取消</a-button>
                    <a-button type="primary" @click="updateRemark(info)">确定</a-button>
                  </a-row>
                </template>
              </a-popover>
            </div>
            <!-- 其他 -->
            <div v-else>
              <span> {{ info.value || '-' }} </span>
            </div>
          </a-form-item>
        </a-col>
      </a-row>
    </div>
  </div>
</template>

<script>
import { advertiserUpdateRemarkTT } from '@/api/popularize/jlyq/index.js';
import { EditOutlined } from '@ant-design/icons-vue';
export default {
  name: 'BaseInfoForProductAndAccount',
  components: { EditOutlined },
  inject: ['getActiveKey', 'jlyqDict'],
  props: {
    baseInfo: { type: Object, default: () => {} },
    rowData: { type: Object, default: () => {} },
  },

  data() {
    return {
      tableTabKeys: this.getActiveKey(),
      activeAd: '',
      visible: false,
    };
  },
  methods: {
    accountAssign() {
      this.$router.push({ name: 'mediaAccount', params: { channel: 'TT' } });
    },
    async updateRemark(info) {
      const params = [{ advertiserId: this.rowData.advertiser_id, remark: info.value }];
      const res = await advertiserUpdateRemarkTT(params);
      if (res.code == 0) {
        this.visible = false;
        this.$message.success('编辑成功');
      } else {
        this.$message.error('编辑失败，请重试！');
      }
    },
    beforeDrawerClosed() {
      this.visible = false;
    },
  },
  computed: {
    // 基本信息数据字典
    infoList() {
      const key = this.getActiveKey();
      const dict = {
        product: [
          { label: '所属客户名称', value: this.baseInfo.customerName },
          { label: '所属行业分类', value: this.baseInfo.levelName },
          { label: '当前运营人员', value: this.baseInfo.userInfo },
        ],
        account: [
          { label: '所属客户名称:', value: this.baseInfo.customerName },
          { label: '所属产品', value: this.baseInfo.productName },
          { label: '账户日预算', value: this.baseInfo.budget },
          { label: '所属主体名称', value: this.baseInfo.invoiceName },
          { label: '账户总余额', value: this.baseInfo.balance },
          { label: '当前运营人员', value: this.baseInfo.userCode },
          { label: '账户备注', value: this.baseInfo.remark },
        ],
        campaign: [
          { label: '组名称', value: this.baseInfo.name },
          { label: '组ID', value: this.baseInfo.campaignId },
          { label: '营销链路', value: this.jlyqDict.marketingPurpose(this.baseInfo.marketingPurpose) },
          { label: '推广目的', value: this.jlyqDict.landingType(this.baseInfo.landingType) },
          { label: '广告组类型', value: this.jlyqDict.campaignType(this.baseInfo.campaignType) },
          { label: '预算类型', value: this.jlyqDict.budgetMode(this.baseInfo.budgetMode) },
        ],
      };
      return dict[key];
    },
  },
};
</script>
