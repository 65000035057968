<!--
 * @Author: Yran
 * @Date: 2021-11-25 15:45:48
 * @LastEditors: Yran
 * @LastEditTime: 2022-01-07 15:49:06
-->


<script>
import { h } from 'vue';
import RulesListTable from './RulesListTable';
import SideRulesListTable from './SideRulesListTable';
import ExecutionRecordTable from './ExecutionRecordTable';
export default {
  provide() {
    return {
      // 格式化监测范围
      setExecRange: this.setExecRange,
      // 格式化触发条件
      setCondition: this.setCondition,
      // 格式化执行操作
      setExecContent: this.setExecContent,
      // 格式化触发详情
      setResultContent: this.setResultContent,
      // 格式化执行范围详情
      setScopeDetails: this.setScopeDetails,
    };
  },
  inject: ['getActiveKey'],
  render() {
    return h(this.map.get(this.getActiveKey()), { columns: this.columns[this.getActiveKey()] });
  },
  setup() {
    const map = new Map();
    map.set('rulesList', RulesListTable);
    map.set('sideRulesList', SideRulesListTable);
    map.set('executionRecord', ExecutionRecordTable);
    // 表格列
    const columns = {
      rulesList: [
        { dataIndex: 'switch', title: '', fixed: 'left', width: 50 },
        { dataIndex: 'ruleName', title: '规则名称' },
        { dataIndex: 'id', title: '规则ID' },
        { dataIndex: 'execRange', title: '监测范围' },
        { dataIndex: 'condition', title: '触发条件' },
        { dataIndex: 'execContent', title: '执行操作' },
        { dataIndex: 'createUserName', title: '创建人' },
        { dataIndex: 'createTime', title: '创建时间' },
        { dataIndex: 'operate', title: '操作', width: 160 },
      ],
      sideRulesList: [
        { dataIndex: 'ruleName', title: '规则名称' },
        { dataIndex: 'id', title: '规则ID' },
        { dataIndex: 'execRange', title: '监测范围' },
        { dataIndex: 'condition', title: '触发条件' },
        { dataIndex: 'execContent', title: '执行操作' },
        { dataIndex: 'createUserName', title: '创建人' },
        { dataIndex: 'createTime', title: '创建时间' },
      ],
      executionRecord: [
        { dataIndex: 'execDate', title: '执行时间' },
        { dataIndex: 'ruleName', title: '执行规则' },
        { dataIndex: 'ruleId', title: '规则ID' },
        { dataIndex: 'ruleContent', title: '执行操作' },
        { dataIndex: 'resultContent', title: '触发详情' },
        { dataIndex: 'scopeDetails', title: '执行范围详情' },
        { dataIndex: 'result', title: '执行结果' },
        { dataIndex: 'messageResult', title: '通知结果' },
      ],
    };
    const conditionObj = {
      EQ: '=',
      LT: '<',
      GT: '>',
      LTE: '<=',
      GTE: '>=',
      NOTIN: '不包含',
      IN: '包含',
    };
    const valueTypeDict = { RMB: '元', '%': '%' };

    const referenceValueObj = {
      1: '上调',
      2: '下降',
      3: '增加',
      4: '减少',
      5: '调整至消耗',
      6: '调整至',
      7: '开启',
      8: '暂停',
      9: '仅通知',
      10: '调整至次日',
    };
    const compareModelObj = {
      1: '今日',
      2: '近3日',
      3: '近7日',
    };
    return { map, columns, conditionObj, valueTypeDict, referenceValueObj, compareModelObj };
  },
  methods: {
    /**
     * @description: 格式化监测范围
     * @param {String} level 层级
     * @param {String} execRange 监测范围返回值
     * @return {Number} 监测账户的个数
     */
    setExecRange(level, execRange) {
      const levels = { CP: '产品', ZH: { 1: '产品', 2: '账户' }, TGJH: { 1: '账户', 2: '计划' }, GG: { 1: '账户', 2: '广告' } };
      if (execRange) {
        execRange = JSON.parse(execRange);
        const info = execRange.info;
        return `${level == 'CP' ? levels[level] : levels[level]?.[execRange.levelWhere]}：${info?.length || 0}`;
      } else {
        return 0;
      }
    },
    /**
     * @description: 格式化触发条件
     * @param {String} condition 触发条件范围值
     * @return {String} 格式化后的触发条件字段
     */
    setCondition(condition) {
      if (condition) {
        let conditionFormat = '';
        condition = JSON.parse(condition);
        condition?.info?.forEach((item, index) => {
          const orAnd = index ? (item.orAnd == 'and' ? '且' : '或者') : '';
          const fieldName = item.fieldName || '';
          const conditionValue = this.conditionObj[item.condition] || '';
          let targetFieldName = '';
          if ((item.condition == 'IN' || item.condition == 'NOTIN') && Array.isArray(item.targetField)) {
            item.targetField?.forEach(childItem => {
              targetFieldName += childItem.label + '、';
            });
            targetFieldName = targetFieldName.replace(/.$/, '；');
          } else {
            targetFieldName = !item.targetFieldName || item.targetFieldName == item.fieldName ? '' : item.targetFieldName;
          }
          const value = item.condition == 'IN' || item.condition == 'NOTIN' ? '' : item.value || 0;
          const valueType = this.valueTypeDict[item.valueType] || '';
          const compareModel = item.compareModel ? this.compareModelObj[item.compareModel] || '' : '';
          conditionFormat += orAnd + compareModel + fieldName + conditionValue + targetFieldName + value + valueType;
        });
        return conditionFormat || '-';
      } else {
        return '-';
      }
    },
    /**
     * @description: 格式化触发详情
     * @param {String} resultContent 触发详情范围值
     * @return {String} 格式化后的触发详情字段
     */
    setResultContent(resultContent) {
      if (resultContent) {
        let conditionFormat = '';
        resultContent = JSON.parse(resultContent);
        resultContent?.forEach((item, index) => {
          const orAnd = index ? (item.details?.orAnd == 'and' ? '且' : '或者') : '';
          const fieldName = item.details?.fieldName || '';
          const orig = item.orig || '0';
          const conditionValue = this.conditionObj[item.details?.condition] || '';
          const targetFieldName = !item.details?.targetFieldName || item.details.targetFieldName == item.details.fieldName ? '' : item.details.targetFieldName;
          const target = item.target || 0;
          const valueType = this.valueTypeDict[item.details?.valueType] || '';
          conditionFormat += orAnd + fieldName + orig + valueType + conditionValue + targetFieldName + target + valueType;
        });
        return conditionFormat || '-';
      } else {
        return '-';
      }
    },
    /**
     * @description: 格式化执行范围详情
     * @param {String} record 表格数据
     * @return {String} 格式化后的执行范围详情字段
     */
    setScopeDetails(record) {
      const levels = { CP: '产品', ZH: '账户', TGJH: '计划', GG: '广告' };
      const channels = { GDT: '腾讯广告', TT: '巨量引擎', KS: '磁力引擎' };
      if (record) {
        let conditionFormat = '';
        conditionFormat = (record.channel ? channels[record.channel] : '') + '-' + (record.level ? levels[record.level] : '') + '：' + record.bid;
        return conditionFormat || '-';
      } else {
        return '-';
      }
    },
    /**
     * @description: 格式化执行操作
     * @param {String} execContent 执行操作返回值
     * @return {Number} 格式化后的执行操作字段
     */
    setExecContent(execContent) {
      if (execContent) {
        let execContentFormat = '';
        execContent = JSON.parse(execContent);
        execContent?.forEach(item => {
          if (item.referenceValue == 9) {
            execContentFormat = '仅通知';
          } else {
            const limitValue = item.limitValue || [];
            const valueType = this.valueTypeDict[item.valueType] || '';
            const fieldName = item.fieldName || '';
            const referenceValue = this.referenceValueObj[item.referenceValue] || '';
            const limitValueStr = limitValue.length == 2 ? (limitValue?.[0] || 0) + valueType + ' ~ ' + (limitValue?.[1] || 0) + valueType : limitValue?.[0] || 0;
            execContentFormat += fieldName + referenceValue + limitValueStr + ';';
          }
        });
        return execContentFormat || '-';
      } else {
        return '-';
      }
    },
  },
};
</script>

<style>
</style>