/*
 * @Author: Yran
 * @Date: 2021-10-20 15:10:04
 * @LastEditors: Yran
 * @LastEditTime: 2021-11-23 16:30:51
 */
import axios, { advert_prefix } from '@/utils/axios';

const apiUrl = {
  // 获取客户列表
  getCustomerList: '/advert/promote/customer/getList',
  // 获取产品列表
  getProductList: '/advert/promote/product/getList',
  // 获取主体列表
  getAccountList: '/advert/promote/account/getList',
  // 获取运营部门列表
  getPromoteDeptList: '/advert/promote/dept/getList',
  // 获取运营人员列表
  getPromoteUserList: '/advert/promote/user/getList',
  // 获取广告组状态(CAMPAIGN_STATUS)/广告组推广目的(CAMPAIGN_LANDINGTYPE)/计划状态(AD_STATUS)/计划首选位置(AD_INVENTORY_TYPE)/计划平台(AD_PLATFORM)/学习期状态(AD_LEARN_PHASE)/诊断状态(AD_SCENE_TEXT)
  getDictList: '/sys/dict/list',

  getTrendList: '/ocean/trend/getList', //获取趋势图数据列表
  // 获取表格数据
  getTableData: {
    product: '/ocean/productTable/getList', ///产品
    account: '/ocean/advertiserTable/getList', //账户
    campaign: '/ocean/campaigns/list', // 广告组
    ad: '/ocean/ads', //计划
    creativity: '/ocean/creatives', //创意
  },
  // 获取所有空白指标
  getIndicatorList: '/indicator/list',
  // 获取常用模板列表
  getIndicatorTempList: '/indicator/temp/list/',
  // 根据模板名称添加新的用户模板
  getIndicatorTempByName: '/indicator/temp/',
  // 修改自定义列表信息
  updateIndicator: '/indicator/update',
  // 添加自定义列表
  addIndicator: '/indicator/add',
  // 删除自定义列表
  deleteIndicator: '/indicator/delete/',

  // 批量操作
  batchOperation: {
    campaign: '/ocean/campaigns/status/', // 广告组
    ad: '/ocean/ad/status/', //计划
    creativity: '/ocean/adcreative/status/', //创意
  },

  // 账户批量调整日预算
  advertiserUpdateDailyBudget: '/ocean/advertiser/update_daily_budget',
  // 账户修改备注
  advertiserUpdateRemark: '/promote/advertiser/update_save_remark',
  // --------------------------------------tabKey==campaign-----------------------------------------------------
  // 广告组批量修改日预算
  campaignUpdateDailyBudget: '/ocean/campaigns/update_daily_budget',
  updateCampaignsNameTT: '/ocean/campaigns/name/update',

  // --------------------------------------tabKey==ad-----------------------------------------------------
  // 广告计划批量调整日预算
  adgroupUpdateDailyBudget: '/ocean/ad/daily_budget/update',
  // 广告计划一键起量
  acquisitionEnabled: '/tencent/adgroup/acquisition/enabled',
  // 广告计划批量修改深度出价
  updateBid: '/tencent/adgroup/update/behavior_bid',
  // 广告计划批量修改出价策略
  updateStrategy: '/tencent/adgroup/update/bid_strategy',
  // 广告计划批量修改投放时间
  updatePostTime: '/tencent/adgroup/serving/time/update',
  // 广告计划批量修改投放日期
  updatePostDate: '/tencent/adgroup/serving/date/update',
  // 广告计划批量修改出价
  updateBidAmount: '/ocean/ad/bid_amount/update',
  // 广告计划修改名称
  updateAdName: '/ocean/ad/name/update',
};

// 拼接get请求
function getUrl(url, params) {
  let str = '';
  Object.keys(params)?.forEach(key => {
    if (params[key]) {
      str += `&${key}=${params[key]}`;
    }
  });
  return url + str.replace('&', '?');
}

// 获取客户列表
export function getCustomerListTT(params) {
  return axios.get(getUrl(apiUrl.getCustomerList, params));
}

// 获取产品列表
export function getProductListTT(params) {
  return axios.get(getUrl(apiUrl.getProductList, params));
}

// 获取主体列表
export function getAccountListTT(params) {
  return axios.get(getUrl(apiUrl.getAccountList, params));
}

// 获取运营部门列表
export function getPromoteDeptListTT(params) {
  return axios.get(getUrl(apiUrl.getPromoteDeptList, params));
}

// 获取运营人员列表
export function getPromoteUserListTT(params) {
  return axios.get(getUrl(apiUrl.getPromoteUserList, params));
}

// 获取广告组状态(CAMPAIGN_STATUS)/广告组推广目的(CAMPAIGN_LANDINGTYPE)/计划状态(AD_STATUS)/计划首选位置(AD_INVENTORY_TYPE)/计划平台(AD_PLATFORM)/学习期状态(AD_LEARN_PHASE)/诊断状态(AD_SCENE_TEXT)
export function getDictListTT(params) {
  return axios.get(getUrl(apiUrl.getDictList, params));
}
// 获取趋势图数据列表
export function getTrendListTT(params) {
  return axios.post(advert_prefix + apiUrl.getTrendList, params);
}
// 获取表格数据
export function getTableDataTT(params, type) {
  let url = advert_prefix + apiUrl.getTableData[type];
  return axios.post(url, params);
}

// 获取所有空白指标
export function getIndicatorListTT(params) {
  return axios.post(advert_prefix + apiUrl.getIndicatorList, params);
}
// 获取常用模板列表
export function getIndicatorTempListTT(userId, channel) {
  return axios.get(advert_prefix + apiUrl.getIndicatorTempList + '' + userId + '/' + channel);
}
// 根据模板名称获取用户模板详细信息
export function getIndicatorTempByNameTT(userId, tempName, channel) {
  return axios.get(advert_prefix + apiUrl.getIndicatorTempByName + '' + userId + '/' + tempName + '/' + channel);
}
// 修改自定义列表信息
export function updateIndicatorTT(params) {
  return axios.post(advert_prefix + apiUrl.updateIndicator, params);
}
// 添加自定义列表
export function addIndicatorTT(params) {
  return axios.post(advert_prefix + apiUrl.addIndicator, params);
}
// 删除自定义列表
export function deleteIndicatorTT(userId, tempName, channel) {
  return axios.delete(advert_prefix + apiUrl.deleteIndicator + '' + userId + '/' + tempName + '/' + channel);
}

// 批量操作
export function batchOperationTT(params, mode, type) {
  let url = advert_prefix + apiUrl.batchOperation[type] + mode;
  return axios.post(url, params);
}
// 批量一键起量
export function acquisitionEnabledTT(params) {
  return axios.post(advert_prefix + apiUrl.acquisitionEnabled, params);
}

// 批量调整账户日预算
export function advertiserUpdateDailyBudgetTT(params) {
  return axios.post(advert_prefix + apiUrl.advertiserUpdateDailyBudget, params);
}
// 批量调整账户备注
export function advertiserUpdateRemarkTT(params) {
  return axios.post(advert_prefix + apiUrl.advertiserUpdateRemark, params);
}

// 批量修改广告组日预算
export function campaignUpdateDailyBudgetTT(params) {
  return axios.post(advert_prefix + apiUrl.campaignUpdateDailyBudget, params);
}
// 广告组批量修改深度出价
export function updateBidTT(params) {
  return axios.post(advert_prefix + apiUrl.updateBid, params);
}
// 广告组批量修改出价策略
export function updateStrategyTT(params) {
  return axios.post(advert_prefix + apiUrl.updateStrategy, params);
}
// 广告组批量修改出价
export function updateBidAmountTT(params) {
  return axios.post(advert_prefix + apiUrl.updateBidAmount, params);
}
// 广告组批量修改名称
export function updateCampaignsNameTT(params) {
  return axios.post(advert_prefix + apiUrl.updateCampaignsNameTT, params);
}

// 批量调整计划日预算
export function adgroupUpdateDailyBudgetTT(params) {
  return axios.post(advert_prefix + apiUrl.adgroupUpdateDailyBudget, params);
}
// 编辑计划名称
export function updateAdPlanNameTT(params) {
  return axios.post(advert_prefix + apiUrl.updateAdName, params);
}

// 批量调整广告投放时间
export function advertiserUpdatePostTimeTT(params) {
  return axios.post(advert_prefix + apiUrl.updatePostTime, params);
}
// 批量调整广告投放日期
export function advertiserUpdatePostDateTT(params) {
  return axios.post(advert_prefix + apiUrl.updatePostDate, params);
}
// 编辑广告名称
export function updateAdNameTT(params) {
  let url = `/adgroup/update/${params.adgroupId}/${params.adgroupName}`;
  return axios.get(url);
}
