<!--
 * @Author: Yran
 * @Date: 2021-11-29 10:37:15
 * @LastEditors: LiuXin
 * @LastEditTime: 2021-12-28 16:40:46
-->
<template>
  <a-drawer v-bind="drawer" @close="closeDrawer" title="自定义托管" class="host-drawer promotion_management_drawer">
    <a-tabs v-model:activeKey="activeKey" class="host-drawer-tab" @change="tab => tab == 'executionRecord' && getHostTableData()">
      <a-tab-pane key="sideRulesList" tab="托管规则"></a-tab-pane>
      <a-tab-pane key="executionRecord" tab="执行记录"></a-tab-pane>
      <template #tabBarExtraContent>
        <a-button type="primary" @click="toHosting">自定义托管</a-button>
      </template>
    </a-tabs>
    <hosting-table></hosting-table>
  </a-drawer>
  <div :class="{ drawer_close_btn: true, hidden_btn: !visible }" @click="closeDrawer">
    <CloseOutlined />
  </div>
</template>

<script>
import { CloseOutlined } from '@ant-design/icons-vue';
import HostingTable from '@/views/hosting/components/HostingTable.vue';
import { getExecutionTableList, getRulesTableList, getAccountOptions, getProductOptions } from '@/api/hosting/index.js';
import { getUserChannel } from '@/api/home/detail.js';
export default {
  provide() {
    return {
      // 选中tab
      getActiveKey: () => this.activeKey,
      // 筛选组件类型
      filterType: 'side',
      // 表格数据
      tableData: this.tableData,
      // 表格配置
      table: this.table,
      // 推广管理源数据
      getSelectRow: () => this.rowDataSource,
      // 获取表格数据方法
      getHostTableData: this.getHostTableData,
      // 获取用户渠道
      getUserChannel: this.getUserChannel,
      // 获取产品筛选项
      getProductOptions: this.getProductOptionsFun,
      // 账户筛选项
      getAccountOptions: this.getAccountOptionsFun,
    };
  },
  inject: ['getTabActiveKey'],
  props: {
    visible: { type: Boolean, default: false }, //抽屉显隐控制
    rowData: { type: Object, default: () => {} }, // 点击详情的行数据
    channel: { type: String, default: 'GDT' }, // 渠道
  },
  emits: ['update:visible'],
  components: { HostingTable, CloseOutlined },
  computed: {
    // 抽屉配置
    drawer() {
      return { visible: this.visible, mask: false, width: '50vw' };
    },
  },
  watch: {
    rowData: {
      handler(newVal) {
        this.rowDataSource = newVal;
      },
      deep: true,
    },
  },
  data() {
    return {
      activeKey: 'sideRulesList',
      // 列表选中行数据
      rowDataSource: {},
      // 自定义托管表格数据
      tableData: { dataSource: [], tableLoading: false },
      // 表格配置
      table: {
        bordered: true,
        size: 'small',
        scroll: { x: 'max-content' },
        showExpanded: false,
        pagination: { current: 1, pageSize: 10, total: 0, showTotal: total => `总计${total}条`, showSizeChanger: true, pageSizeOptions: ['10', '20', '30', '50'] },
      },
    };
  },
  watch: {},
  methods: {
    closeDrawer() {
      this.activeKey = 'sideRulesList';
      this.$emit('update:visible', false);
    },
    toHosting() {
      this.$router.push({ name: 'hosting' });
    },
    /**
     * @description: 获取自定义托管表格数据
     * @param {*}
     * @return {*}
     */
    async getHostTableData(filterOptions) {
      const postData = {
        ...filterOptions,
        channel: this.channel,
        level: this.getTabActiveKey(),
        limit: this.table.pagination.pageSize,
        page: this.table.pagination.current,
      };
      this.tableData.tableLoading = true;
      // 表格请求接口
      const getUrlList = {
        sideRulesList: getRulesTableList,
        executionRecord: getExecutionTableList,
      };
      try {
        let res = await getUrlList[this.activeKey](postData);
        if (res.code == 0) {
          this.tableData.dataSource = res.data?.list;
          this.tableData.tableLoading = false;
        }
      } catch (err) {
        console.log(err);
        this.tableData.tableLoading = false;
      }
    },

    /**
     * @description: 获取当前人员的渠道
     * @param {*}
     * @return {*}
     */
    async getUserChannel() {
      let res = await getUserChannel();
      this.$store.commit('SET_USERCHANNEL', this.channel);
      return res.data;
    },
    /**
     * @description: 获取账户筛选项
     * @param {Object} postData 请求参数
     * @return {*}
     */
    async getAccountOptionsFun(postData) {
      let res = await getAccountOptions(postData);
      if (res.code == 0) {
        return (
          res.data?.list?.map(item => ({
            label: item.accountName,
            value: item.accountName,
          })) || []
        );
      }
    },
    /**
     * @description: 获取产品筛选项
     * @param {Object} postData 请求参数
     * @return {*}
     */
    async getProductOptionsFun(postData) {
      let res = await getProductOptions(postData);
      if (res.code == 0) {
        return (
          res.data?.list?.map(item => ({
            label: item.productName,
            value: item.productCode,
          })) || []
        );
      }
    },
  },
};
</script>
<style lang="less" >
// 抽屉样式
.host-drawer {
  .ant-drawer-content-wrapper {
    .ant-drawer-body {
      height: calc(100% - 60px);
      padding: 12px;
    }
  }

  .host-drawer-tab {
    background: #f5f7fa;
    margin: -12px;
    margin-bottom: 10px;
    padding-left: 24px;
    padding-right: 12px;
    .ant-tabs-bar {
      margin-bottom: 0px;
      border-bottom: none;
    }
    .ant-tabs-tab {
      padding: 12px 5px 12px;
    }
    .ant-tabs-ink-bar {
      height: 3px;
    }
  }
}
</style>