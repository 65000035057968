/*
 * @Author: Yran
 * @Date: 2021-10-20 15:10:04
 * @LastEditors: Yran
 * @LastEditTime: 2021-11-19 16:19:30
 */
import axios, { advert_prefix } from '@/utils/axios';

const apiUrl = {
  // 获取客户列表
  getCustomerList: '/advert/promote/customer/getList',
  // 获取产品列表
  getProductList: '/advert/promote/product/getList',
  // 获取主体列表
  getAccountList: '/advert/promote/account/getList',
  // 获取运营部门列表
  getPromoteDeptList: '/advert/promote/dept/getList',
  // 获取运营人员列表
  getPromoteUserList: '/advert/promote/user/getList',
  // 获取广告组状态(CAMPAIGN_STATUS)/广告组推广目的(CAMPAIGN_LANDINGTYPE)/计划状态(AD_STATUS)/计划首选位置(AD_INVENTORY_TYPE)/计划平台(AD_PLATFORM)/学习期状态(AD_LEARN_PHASE)/诊断状态(AD_SCENE_TEXT)
  getDictList: '/sys/dict/list',

  getTrendList: '/ks/trend/getList', //获取趋势图数据列表
  // 获取表格数据
  getTableData: {
    product: '/ks/productTable/getList', ///产品
    account: '/ks/advertiserTable/getList', //账户
    campaign: '/ks/campaigns', // 计划
    ad: '/ks/units', //广告组
    creativity: '/ks/creatives/list', //创意
  },
  // 获取所有空白指标
  getIndicatorList: '/indicator/list',
  // 获取常用模板列表
  getIndicatorTempList: '/indicator/temp/list/',
  // 根据模板名称添加新的用户模板
  getIndicatorTempByName: '/indicator/temp/',
  // 修改自定义列表信息
  updateIndicator: '/indicator/update',
  // 添加自定义列表
  addIndicator: '/indicator/add',
  // 删除自定义列表
  deleteIndicator: '/indicator/delete/',

  // 批量操作
  batchOperation: {
    campaign: '/ks/campaigns/status/', // 广告计划
    ad: '/ks/unit/status/', //广告组
    creativity: '/ks/creative/status/', //创意
  },

  // --------------------------------------tabKey==ad-----------------------------------------------------
  // 广告组批量修改日预算
  unitUpdateDailyBudget: '/ks/unit/daily_budget/update',
  // 广告组修改广告组名称
  updateUnitsName: '/ks/unit/name/update',
  // 广告组修改深度出价
  updateUnitsDeepbid: '/ks/unit/deepbid/update',
  // 广告组修改分别出价
  updateUnitsUniformBidAmount: '/unit/uniform/bid_amount/update',
  // 广告组修改分别出价
  updateUnitsBidAmount: '/ks/unit/bid_amount/update',
  // 广告组修改投放时段
  updatePostTimeRange: '/ks/unit/schedule_time/update',
  // 广告组修改投放时间
  updatePostTime: '/ks/unit/time/update',

  // --------------------------------------tabKey==account-----------------------------------------------------
  // 账户批量调整日预算
  advertiserUpdateDailyBudget: '/ks/advertiser/update_daily_budget',
  // 账户修改备注
  advertiserUpdateRemark: '/promote/advertiser/update_save_remark',

  // --------------------------------------tabKey==campaign-----------------------------------------------------
  //计划批量修改日预算
  campaignUpdateDailyBudget: '/ks/campaigns/update_daily_budget',
  // 广告计划修改名称
  updateCampaignsNameKS: '/ks/campaigns/name/update',
};

// 拼接get请求
function getUrl(url, params) {
  let str = '';
  Object.keys(params)?.forEach(key => {
    if (params[key]) {
      str += `&${key}=${params[key]}`;
    }
  });
  return url + str.replace('&', '?');
}
/*------------------------------------------筛选项数据请求---------------------------------------------------*/

// 获取客户列表
export function getCustomerListKS(params) {
  return axios.get(getUrl(apiUrl.getCustomerList, params));
}

// 获取产品列表
export function getProductListKS(params) {
  return axios.get(getUrl(apiUrl.getProductList, params));
}

// 获取主体列表
export function getAccountListKS(params) {
  return axios.get(getUrl(apiUrl.getAccountList, params));
}

// 获取运营部门列表
export function getPromoteDeptListKS(params) {
  return axios.get(getUrl(apiUrl.getPromoteDeptList, params));
}

// 获取运营人员列表
export function getPromoteUserListKS(params) {
  return axios.get(getUrl(apiUrl.getPromoteUserList, params));
}

// 获取广告组状态(CAMPAIGN_STATUS)/广告组推广目的(CAMPAIGN_LANDINGTYPE)/计划状态(AD_STATUS)/计划首选位置(AD_INVENTORY_TYPE)/计划平台(AD_PLATFORM)/学习期状态(AD_LEARN_PHASE)/诊断状态(AD_SCENE_TEXT)
export function getDictListKS(params) {
  return axios.get(getUrl(apiUrl.getDictList, params));
}

/*-----------------------------------------表格趋势图数据请求---------------------------------------------------*/
// 获取趋势图数据列表
export function getTrendListKS(params) {
  return axios.post(advert_prefix + apiUrl.getTrendList, params);
}
// 获取表格数据
export function getTableDataKS(params, type) {
  let url = advert_prefix + apiUrl.getTableData[type];
  return axios.post(url, params);
}

/*------------------------------------------自定义列相关操作请求---------------------------------------------------*/

// 获取所有空白指标
export function getIndicatorListKS(params) {
  return axios.post(advert_prefix + apiUrl.getIndicatorList, params);
}
// 获取常用模板列表
export function getIndicatorTempListKS(userId, channel) {
  return axios.get(advert_prefix + apiUrl.getIndicatorTempList + '' + userId + '/' + channel);
}
// 根据模板名称获取用户模板详细信息
export function getIndicatorTempByNameKS(userId, tempName, channel) {
  return axios.get(advert_prefix + apiUrl.getIndicatorTempByName + '' + userId + '/' + tempName + '/' + channel);
}
// 修改自定义列表信息
export function updateIndicatorKS(params) {
  return axios.post(advert_prefix + apiUrl.updateIndicator, params);
}
// 添加自定义列表
export function addIndicatorKS(params) {
  return axios.post(advert_prefix + apiUrl.addIndicator, params);
}
// 删除自定义列表
export function deleteIndicatorKS(userId, tempName, channel) {
  return axios.delete(advert_prefix + apiUrl.deleteIndicator + '' + userId + '/' + tempName + '/' + channel);
}

// 批量操作一键启停等
export function batchOperationKS(params, mode, type) {
  let url = advert_prefix + apiUrl.batchOperation[type] + mode;
  return axios.post(url, params);
}

/*------------------------------------------账户批量编辑---------------------------------------------------*/

// 批量修改账户日预算
export function advertiserUpdateDailyBudgetKS(params) {
  return axios.post(advert_prefix + apiUrl.advertiserUpdateDailyBudget, params);
}
// 批量调整账户备注
export function advertiserUpdateRemarkKS(params) {
  return axios.post(advert_prefix + apiUrl.advertiserUpdateRemark, params);
}

/*------------------------------------------广告计划批量编辑---------------------------------------------------*/

// 批量修改计划日预算
export function campaignUpdateDailyBudgetKS(params) {
  return axios.post(advert_prefix + apiUrl.campaignUpdateDailyBudget, params);
}
// 编辑计划名称
export function updateCampaignsNameKS(params) {
  return axios.post(advert_prefix + apiUrl.updateCampaignsNameKS, params);
}

/*------------------------------------------广告组批量编辑---------------------------------------------------*/

// 批量修改广告组日预算
export function unitUpdateDailyBudgetKS(params) {
  return axios.post(advert_prefix + apiUrl.unitUpdateDailyBudget, params);
}

// 广告组批量修改深度出价
export function updateUnitsDeepbidKS(params) {
  return axios.post(advert_prefix + apiUrl.updateUnitsDeepbid, params);
}
// 广告组批量修改名称
export function updateUnitsNameKS(params) {
  return axios.post(advert_prefix + apiUrl.updateUnitsName, params);
}

// 批量调整广告投放时段
export function advertiserUpdatePostTimeRangeKS(params) {
  return axios.post(advert_prefix + apiUrl.updatePostTimeRange, params);
}
// 批量调整广告投放时间
export function advertiserUpdatePostTimeKS(params) {
  return axios.post(advert_prefix + apiUrl.updatePostTime, params);
}
// 广告组修改统一出价
export function updateUnitsUniformBidAmountKS(params) {
  return axios.post(advert_prefix + apiUrl.updateUnitsUniformBidAmount, params);
}
// 广告组修改分别出价
export function updateUnitsBidAmountKS(params) {
  return axios.post(advert_prefix + apiUrl.updateUnitsBidAmount, params);
}
