<!--
 * @Author: Yran
 * @Date: 2021-11-29 09:48:12
 * @LastEditors: Yran
 * @LastEditTime: 2021-12-20 17:29:19
-->
<template>
  <div v-if="filterType == 'home'">
    <a-row>
      <a-col class="filter-select-item">
        <a-select v-model:value="filterOptions.channel" :options="optionList.channel" placeholder="渠道" v-bind="$filterOptions" :allow-clear="false" @change="channelChange"></a-select>
      </a-col>
      <a-col class="filter-select-item">
        <SelectMultiple v-model:value="filterOptions.result" :select-data="filterOptions.result" :select-options="optionList.result" placeholder="执行结果" v-bind="$multipleFilterOptions" @change="filterChange"></SelectMultiple>
      </a-col>
      <a-col class="filter-select-item">
        <SelectMultiple v-model:value="filterOptions.action" :select-data="filterOptions.action" :select-options="optionList.action" placeholder="执行操作" v-bind="$multipleFilterOptions" @change="filterChange"></SelectMultiple>
      </a-col>
      <a-col class="filter-select-item">
        <SelectMultiple v-model:value="filterOptions.account" :select-data="filterOptions.account" :select-options="optionList.account" placeholder="涉及账户" v-bind="$multipleFilterOptions" :lazy-load="true" :load-over="loadOver" @change="filterChange" @popupScroll="e => onSelectScroll(e, 'account')"></SelectMultiple>
      </a-col>
      <a-col class="filter-select-item">
        <SelectMultiple v-model:value="filterOptions.project" :select-data="filterOptions.project" :select-options="optionList.project" placeholder="涉及产品" v-bind="$multipleFilterOptions" :lazy-load="true" :load-over="loadOver" @change="filterChange" @popupScroll="e => onSelectScroll(e, 'project')"></SelectMultiple>
      </a-col>
    </a-row>
  </div>
  <div v-else>
    <a-select class="filter-select-item" style="width: 176px" v-model:value="filterOptions.result" :options="optionList.result" placeholder="执行结果" v-bind="$filterOptions" @change="filterChange"></a-select>
  </div>
</template>

<script>
import SelectMultiple from '@/components/selectMultiple/index.vue';
export default {
  inject: ['filterOptions', 'filterType', 'table', 'getProductOptions', 'getHostTableData', 'getAccountOptions', 'getUserChannel'],
  components: { SelectMultiple },
  data() {
    return {
      // 下拉框选项集合
      optionList: {
        project: [],
        account: [],
        result: [
          { value: '-1', label: '执行失败' },
          { value: '0', label: '执行中' },
          { value: '1', label: '执行成功' },
        ],
        channel: [
          { value: 'GDT', label: '腾讯广告' },
          { value: 'TT', label: '巨量引擎' },
          { value: 'KS', label: '磁力引擎' },
        ],
        action: [
          { value: 1, label: '修改日预算' },
          { value: 2, label: '仅发送通知' },
          { value: 3, label: '开启/暂停' },
          { value: 4, label: '调整出价' },
          { value: 5, label: '调整深度出价' },
        ],
      },
      loadOver: false,
      // 产品页数
      productPage: 1,
      // 账户页数
      accountPage: 1,
    };
  },
  async mounted() {
    this.filterType == 'home' && this.getFilterChannel();
  },
  methods: {
    /**
     * @description:初始化筛选项
     * @param {*}
     * @return {*}
     */
    async getFilterChannel() {
      if (!this.filterOptions.ruleId) {
        this.filterOptions.channel = this.$store.state.userChannel ? this.$store.state.userChannel : (await this.getUserChannel()) || 'GDT';
      }
      this.table.pagination.page = 1;
      this.table.pagination.current = 1;
      this.getFilterProductOptions();
      this.getFilterAccountcOptions();
      this.getHostTableData(this.filterOptions);
    },
    /**
     * @description: 筛选项变化
     * @param {*}
     * @return {*}
     */
    filterChange() {
      this.getHostTableData(this.filterOptions);
    },
    /**
     * @description: 渠道发生变化
     * @param {*}
     * @return {*}
     */
    async channelChange() {
      this.getFilterProductOptions();
      this.getFilterAccountcOptions();
      this.getHostTableData(this.filterOptions);
    },
    /**
     * @description: 获取产品筛选项
     * @param {*}
     * @return {*}
     */
    async getFilterProductOptions() {
      const postData = {
        page: this.productPage,
        limit: 50,
        mediaChannel: this.filterOptions.channel,
      };
      const arr = await this.getProductOptions(postData);
      Array.isArray(this.optionList.project) ? (this.optionList.project = this.optionList.project.concat(arr)) : (this.optionList.project = arr);
    },
    /**
     * @description: 获取账户筛选项
     * @param {*}
     * @return {*}
     */
    async getFilterAccountcOptions() {
      const postData = {
        page: this.accountPage,
        limit: 50,
        mediaChannel: this.filterOptions.channel,
      };
      const arr = await this.getAccountOptions(postData);
      Array.isArray(this.optionList.account) ? (this.optionList.account = this.optionList.account.concat(arr)) : (this.optionList.account = arr);
    },
    /**
     * @description: 下拉框滚动
     * @param {Object} e 滚动元素
     * @param {Object} filter 滚动的筛选项
     * @return {*}
     */
    onSelectScroll(e, type) {
      let scrollTop = e.target.scrollTop;
      let scrollHeight = e.target.scrollHeight;
      let clientHeight = e.target.clientHeight;
      let scrollBottom = scrollHeight - clientHeight - scrollTop;
      if (scrollBottom <= 0) {
        if (type == 'project') {
          this.productPage++;
          this.getFilterProductOptions();
        } else {
          this.accountPage++;
          this.getFilterAccountcOptions();
        }
      }
    },
  },
};
</script>

<style>
</style>