/*
 * @Author: Yran
 * @Date: 2021-09-09 14:30:33
 * @LastEditors: LiuXin
 * @LastEditTime: 2021-11-11 11:51:24
 */
import axios from '@/utils/axios';

const apiUrl = {
    report: '/report',
    reportAgg: '/report/aggregate',
    getUserChannel: '/user/channel'
};

// 获取素材成本列表
export function getDetailReport(params) {
    const url=getUrl(apiUrl.report,params);
    return axios.get(url);
}

// 获取素材成本总计
export function getDetailReportAgg(params) {
    const url=getUrl(apiUrl.reportAgg,params);
    return axios.get(url);
}

// 获取素材成本总计
export function getUserChannel() {
    return axios.get(apiUrl.getUserChannel);
}
// 拼接get请求
function getUrl(url, params) {
    let str = '';
    Object.keys(params).forEach(key => {
      if (params[key]) {
        str += `&${key}=${params[key]}`;
      }
    });
    return url + str.replace('&', '?');
}