<!--
 * @Author: Yran
 * @Date: 2021-10-13 11:38:10
 * @LastEditors: LiuXin
 * @LastEditTime: 2021-10-14 09:34:03
-->
<template>
  <a-table v-bind="table" :row-selection="rowSelection">
    <template v-for="s in slots" #[s.slots.customRender]="{ text, record, index }">
      <slot :name="s.slots.customRender" :text="text" :record="record" :index="index"> </slot>
    </template>
    <template v-if="table.showExpanded" #expandedRowRender="{ text, record, index }">
      <slot name="expandedRowRender" :text="text" :record="record" :index="index"> </slot>
    </template>
  </a-table>
</template>
<script>
export default {
  props: {
    // 显示勾选框
    rowSelection: {
      type: [Object, String],
      default: () => {
        return null;
      },
    },
    // 表格相关配置
    table: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      slots: this.table.columns.filter(setting => {
        return setting.slots && setting.slots.customRender;
      }),
    };
  },
  watch: {
    'table.columns'(newVal) {
      this.slots = newVal.filter(setting => {
        return setting.slots && setting.slots.customRender;
      });
    },
  },
};
</script>