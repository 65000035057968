<!--
 * @Author: LiuXin
 * @Date: 2021-10-18 11:45:52
 * @LastEditors: LiuXin
 * @LastEditTime: 2021-11-24 17:27:17
-->
<template>
  <div class="base_info_wrapper w100 h100 flow_auto">
    <div class="base_info w100">
      <!-- 创意 -->
      <div v-if="imgList.length" class="img_wrapper">
        <creative-box v-for="item in imgList" :key="item" v-bind="{ imgInfo: item, status: item.status, link: { url: getMaterialDetailUrl(item), text: '素材详情>>' } }" />
      </div>
      <div v-else class="img_wrapper">
        <a-empty></a-empty>
      </div>
      <!-- 审核信息 -->
      <div class="check_opinion h100">
        <p class="check_title">
          <span class="title">审核意见</span>
          <!-- <a-button type="primary">编辑</a-button> -->
        </p>
        <ul class="check_result_list">
          <li v-for="item in baseInfo.auditSpec" :key="item">
            <p class="check_name">{{ siteDict['SITE_SET_' + item.siteSet] }}</p>

            <p class="sucess_result" v-if="item.systemStatus == 'NORMAL'">
              <svg-icon icon-class="success" class-name="result_icon"></svg-icon>
              <span class="result_text">审核通过</span>
            </p>
            <p class="fail_result" v-else>
              <svg-icon icon-class="fail" class-name="result_icon"></svg-icon>
              <span class="result_text">审核失败</span>
              <span class="fail_reason" :title="item.rejectMessage">失败原因：{{ item.rejectMessage }}</span>
            </p>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import CreativeBox from '@/views/popularize/components/CreativeBox.vue';
export default {
  name: 'BaseInfoForCreativity',
  components: { CreativeBox },
  props: {
    baseInfo: { type: Object, default: () => {} },
  },
  inject: ['jlyqDict', 'getBaseFilter', 'mediaChannel'],
  data() {
    const siteDict = {};
    JSON.parse(localStorage.getItem('promoteCodesOptionsTT') || '[]').forEach(item => {
      siteDict[item.value] = item.label;
    });
    return {
      systemStatusDict: {
        NORMAL: '有效',
        PENDING: '待审核',
        DENIED: '审核不通过',
        PARTIALLY_PENDING: '部分审核中',
        PARTIALLY_NORMAL: '部分有效',
        PREPARE: '准备中(当投放视频广告时，该状态代表视频转码中)',
        DELETED: '已删除',
        INVALID: '异常(当投放视频广告时，该状态代表视频转码失败)',
      },
      siteDict,
    };
  },
  computed: {
    imgList() {
      const imgList = [{ title: this.baseInfo.title, url: this.baseInfo.video, type: this.baseInfo.type, status: this.jlyqDict.creativityStatus(this.baseInfo.status), md5: this.baseInfo.md5 }];
      return imgList;
    },
  },
  methods: {
    getMaterialDetailUrl(item) {
      if (!item.md5) {
        return null;
      }
      let filterCondition = this.getBaseFilter();
      let url = `?md5=${item.md5}&channel=${this.mediaChannel}&sd=${filterCondition.uploadTime[0].format('YYYY-MM-DD')}&ed=${filterCondition.uploadTime[1].format('YYYY-MM-DD')}`;
      if (item.type == 'image') {
        return '/asset/material/image' + url;
      } else {
        return '/asset/material/video' + url;
      }
    },
  },
};
</script>

<style scoped lang="less">
.base_info_wrapper {
  padding: 10px;
  @border: 1px solid rgb(239, 241, 246);
  @radius: 4px;
  .base_info {
    display: flex;
    border: @border;
    border-radius: @radius;
    .img_wrapper {
      width: 275px;
      height: 100%;
      padding: 10px;
      box-sizing: border-box;
      overflow: auto;
    }
    .check_opinion {
      flex-grow: 1;
      background: rgb(251, 251, 251);
      margin-left: 10px;
      .check_title {
        height: 30px;
        padding: 0 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-top: 5px;
        .title {
          line-height: 30px;
          font-weight: bold;
          font-size: 14px;
        }
        .ant-btn {
          height: 30px;
        }
      }
      .check_result_list {
        height: calc(100% - 40px);
        padding-left: 30px;
        li {
          border-bottom: @border;
          padding: 5px 0;
          .check_name {
            margin: 3px 0 !important;
          }
          .sucess_result .result_text {
            color: #23bb67;
          }
          .fail_result {
            .result_text {
              color: #ec4d4d;
            }
            .fail_reason {
              color: #bdbdbd;
              margin-left: 10px;
            }
          }
          .result_icon {
            transform: translateY(2px);
            margin-right: 5px;
          }
        }
      }
    }
  }
}
</style>
