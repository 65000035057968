<!--
 * @Author: Yran
 * @Date: 2021-11-25 15:52:54
 * @LastEditors: Yran
 * @LastEditTime: 2022-01-07 15:48:30
-->
<template>
  <div class="rules-list-wrapper">
    <div class="toolbar-wrapper flexJustifyBetween">
      <div class="filter-select">
        <a-row>
          <a-col class="filter-select-item"> <a-select v-model:value="filterOptions.status" :options="optionList.status" placeholder="规则状态" v-bind="$filterOptions" @change="filterChange"></a-select> </a-col>
          <a-col class="filter-select-item">
            <SelectMultiple v-model:value="filterOptions.userIds" :select-data="filterOptions.userIds" :select-options="userIds" placeholder="创建人" v-bind="$multipleFilterOptions" @change="filterChange"></SelectMultiple>
          </a-col>
          <!-- <a-col class="filter-select-item"> <a-select v-model:value="filterOptions.value3" :options="optionList.value3" mode="multiple" :max-tag-count="1" placeholder="执行操作" v-bind="$filterOptions" @change="filterChange"></a-select> </a-col> -->
          <!-- <a-col class="filter-select-item"> <a-select v-model:value="filterOptions.value4" :options="optionList.value4" mode="multiple" :max-tag-count="1" placeholder="监测范围" v-bind="$filterOptions" @change="filterChange"></a-select> </a-col> -->
          <!-- <a-col class="filter-select-item"> <a-select v-model:value="filterOptions.value5" :options="optionList.value4" mode="multiple" :max-tag-count="1" placeholder="涉及账户" v-bind="$filterOptions" @change="filterChange"></a-select> </a-col> -->
          <!-- <a-col class="filter-select-item"> <a-select v-model:value="filterOptions.value6" :options="optionList.value4" mode="multiple" :max-tag-count="1" placeholder="涉及产品" v-bind="$filterOptions" @change="filterChange"></a-select> </a-col> -->
          <a-col class="filter-select-item">
            <SelectMultiple v-model:value="filterOptions.channels" :select-data="filterOptions.channels" :select-options="optionList.channels" placeholder="渠道" v-bind="$multipleFilterOptions" @change="filterChange"></SelectMultiple>
          </a-col>
        </a-row>
      </div>
      <div class="filter-other flexAlignCenter">
        <a-input-search v-model:value="filterOptions.ruleName" placeholder="搜索规则名称" allow-clear class="filter-other-input" @search="filterChange"></a-input-search>
        <a-range-picker v-model:value="filterOptions.createTime" value-format="YYYY-MM-DD" @change="timeFilterChange">
          <template #suffixIcon><CalendarTwoTone /></template>
        </a-range-picker>
      </div>
    </div>
    <div class="rules-table-wrapper">
      <a-table v-bind="table" :data-source="tableData.dataSource" :loading="tableData.tableLoading" row-key="id" @change="tableChange">
        <template v-for="column in $attrs.columns" :key="column.dataIndex">
          <!-- 开关 -->
          <a-table-column v-if="column.dataIndex == 'switch'" v-bind="column">
            <template #default="{ record }">
              <a-switch :loading="record.switchLoading" :checked="!!record.status" :disabled="!!record.switchDisabled" size="small" @change="(changed, e) => updateRulesStatusFun(changed, e, record)" />
            </template>
          </a-table-column>
          <!--监测范围 -->
          <a-table-column v-else-if="column.dataIndex == 'execRange'" v-bind="column">
            <template #default="{ record }">
              <span>{{ setExecRange(record.level, record.execRange) }}</span>
            </template>
          </a-table-column>
          <!--触发条件 -->
          <a-table-column v-else-if="column.dataIndex == 'condition'" v-bind="column">
            <template #default="{ record }">
              <span>{{ setCondition(record.condition) }}</span>
            </template>
          </a-table-column>
          <!--执行操作 -->
          <a-table-column v-else-if="column.dataIndex == 'execContent'" v-bind="column">
            <template #default="{ record }">
              <span>{{ setExecContent(record.execContent) }}</span>
            </template>
          </a-table-column>
          <!--创建时间 -->
          <a-table-column v-else-if="column.dataIndex == 'createTime'" v-bind="column">
            <template #default="{ record }">
              <span>{{ record.createTime && moment(record.createTime).format('YYYY-MM-DD') }}</span>
            </template>
          </a-table-column>
          <!-- 操作 -->
          <a-table-column v-else-if="column.dataIndex == 'operate'" v-bind="column">
            <template #default="{ record }">
              <a class="mr10 cursorPoi" @click="toExecutionRecord(record)">执行记录</a>
              <a class="mr10 cursorPoi" @click="editRules(record)">编辑</a>
              <a-dropdown>
                <a class="ant-dropdown-link" @click.prevent>
                  更多
                  <DownOutlined />
                </a>
                <template #overlay>
                  <a-menu>
                    <a-menu-item>
                      <a @click="copyRules(record)">复制</a>
                    </a-menu-item>
                    <a-menu-item>
                      <a @click="deleteRules(record)">删除</a>
                    </a-menu-item>
                  </a-menu>
                </template>
              </a-dropdown>
            </template>
          </a-table-column>
          <!-- 其他 -->
          <a-table-column v-else v-bind="column">
            <template #default="{ record }">
              <span> {{ record?.[column.dataIndex] || '-' }}</span>
            </template>
          </a-table-column>
        </template>
      </a-table>
    </div>
  </div>
</template>

<script>
import { CalendarTwoTone, DownOutlined } from '@ant-design/icons-vue';
import SelectMultiple from '@/components/selectMultiple/index.vue';
import moment from 'moment';
export default {
  name: 'RulesListTable',
  components: { CalendarTwoTone, DownOutlined, SelectMultiple },
  inject: ['showCustomizeRule', 'tableData', 'getHostTableData', 'toExecutionRecord', 'editRules', 'copyRules', 'deleteRules', 'updateRulesStatusFun', 'table', 'setExecRange', 'setCondition', 'setExecContent', 'getAllUsers'],
  data() {
    return {
      moment,
      // 筛选数据
      filterOptions: {},
      // 下拉框选项集合
      optionList: {
        status: [
          { value: '1', label: '启动' },
          { value: '0', label: '停止' },
          { value: 'null', label: '不限' },
        ],
        result: [
          { value: 0, label: '执行失败' },
          { value: 1, label: '执行成功' },
          { value: 2, label: '执行失败' },
        ],
        value4: [],
        value5: [],
        operate: [
          { value: 1, label: '修改日预算' },
          { value: 2, label: '仅发送通知' },
          { value: 3, label: '开启/暂停' },
          { value: 4, label: '调整出价' },
          { value: 5, label: '调整深度出价' },
        ],
        channels: [
          { value: 'GDT', label: '腾讯广告' },
          { value: 'TT', label: '巨量引擎' },
          { value: 'KS', label: '磁力引擎' },
        ],
      },

      // 选中的表格
      selectRow: {},
    };
  },
  computed: {
    userIds() {
      return this.getAllUsers();
    },
  },
  methods: {
    /**
     * @description: 表格页码切换
     * @param {*} pagination
     * @param {*} filters
     * @param {*} sorter
     * @return {*}
     */
    tableChange(pagination, filters, sorter) {
      this.table.pagination.page = pagination.current.toString();
      this.table.pagination.pageSize = pagination.pageSize;
      this.filterOptions.page = pagination.current.toString();
      this.filterOptions.limit = pagination.pageSize.toString();
      this.getHostTableData(this.filterOptions);
    },
    /**
     * @description: 筛选项变化
     * @param {*}
     * @return {*}
     */
    filterChange() {
      this.filterOptions.status == 'null' && delete this.filterOptions.status;
      this.filterOptions.channels?.length == 0 && delete this.filterOptions.channels;
      this.getHostTableData(this.filterOptions);
    },
    /**
     * @description: 时间筛选项变化
     * @param {*} dates
     * @return {*}
     */
    timeFilterChange(dates) {
      if (dates?.length == 2) {
        this.filterOptions.startCreateTime = dates[0];
        this.filterOptions.endCreateTime = dates[1];
      } else {
        delete this.filterOptions.startCreateTime;
        delete this.filterOptions.endCreateTime;
      }
      this.filterChange();
    },
  },
};
</script>

<style lang="less" scoped>
.rules-list-wrapper {
  .toolbar-wrapper {
    .filter-select {
      &-item {
        width: 6.75rem;
        margin-bottom: 10px;
        .ant-select {
          width: 93%;
          :deep(.ant-select-selection-item) {
            max-width: 65%;
          }
        }
      }
    }
    .filter-other {
      height: 32px;

      &-input {
        width: 224px;
        margin-right: 12px;
        height: 32px;
      }
    }
  }
  .rules-table-wrapper {
    :deep(.ant-table-row):first-child {
      td:not(:nth-child(2)) > span {
        color: #000;
      }
    }

    :deep(.ant-table-row) {
      height: 48px !important;
      .ant-table-row-cell-break-word {
        height: 48px;
      }
    }
  }
}
</style>