<!--
 * @Author: LiuXin
 * @Date: 2021-10-20 14:22:32
 * @LastEditors: Yran
 * @LastEditTime: 2021-12-23 10:25:53
-->
<template>
  <a-select @change="operationChange" v-bind="multSelectOptions" v-show="multSelectOptions.options" v-model:value="selectedValue" class="batch_operation_select"></a-select>
  <a-modal v-bind="modal" v-model:visible="modalVisible" @ok="handleOK(selectedRows, selectedValue, null)" :width="selectedValue == 'deliveryMethod' ? 356 : 'auto'" :title="modalTitle" :ok-button-props="{ loading: btnLoading }">
    <!-- 批量分配 -->
    <span v-if="selectedValue == 'batchAssign'"> 跳转到媒体账号管理界面，并选中所勾选的账号即可 </span>

    <!-- 投放方式 -->
    <a-radio-group v-model:value="speedMode" v-else-if="selectedValue == 'deliveryMethod'">
      <a-radio value="STANDARD">标准投放（推荐）</a-radio>
      <a-radio value="FAST">加速投放</a-radio>
    </a-radio-group>

    <!-- 出价策略 -->
    <a-radio-group v-model:value="advertise.strategy.radio" v-else-if="selectedValue == 'strategy'">
      <a-radio value="BID_STRATEGY_AVERAGE_COST">稳定拿量</a-radio>
      <a-radio value="BID_STRATEGY_TARGET_COST">优先拿量</a-radio>
      <a-radio value="BID_STRATEGY_PRIORITY_LOW_COST">优先低成本</a-radio>
      <!-- <a-radio value="BID_STRATEGY_PRIORITY_CAP_COST">控制成本上限</a-radio> -->
    </a-radio-group>

    <!-- 投放日期 -->
    <template v-else-if="selectedValue == 'date'">
      <a-radio-group v-model:value="advertise.date.radio">
        <a-radio value="date">长期投放</a-radio>
        <a-radio value="dateRange">特定日期</a-radio>
      </a-radio-group>
      <div v-if="advertise.date.radio == 'date'">
        <p class="grey">开始投放日期</p>
        <a-date-picker value-format="YYYY-MM-DD" v-model:value="advertise.date.beginDate" :disabled-date="disabledDate" />
      </div>
      <div v-else>
        <p class="grey">开始投放日期 - 投放结束日期</p>
        <a-range-picker value-format="YYYY-MM-DD" v-model:value="advertise.date.dateRange" separator="至" :disabled-date="disabledDate" />
      </div>
    </template>

    <!-- 投放时间、时段 -->
    <template v-else-if="selectedValue == 'time'">
      <template v-if="mediaChannel == 'GDT'">
        <a-radio-group v-model:value="advertise.time.radio" v-if="!advertise.time.advanced">
          <a-radio value="range">时间段</a-radio>
          <a-radio value="start">仅修改首日开始时间</a-radio>
        </a-radio-group>
        <div v-show="advertise.time.radio == 'range' && !advertise.time.advanced">
          <p>
            <a-time-picker v-model:value="advertise.time.range[0]" format="HH:mm" value-format="HH:mm" :minute-step="60" />
            <span> - </span>
            <a-time-picker v-model:value="advertise.time.range[1]" :minute-step="60" value-format="HH:mm" format="HH:mm" />
          </p>
        </div>
        <template v-if="advertise.time.advanced">
          <time-selection style="width: 100%" v-model:activeList="advertise.time.activeList"></time-selection>
        </template>
        <p>
          <a-space style="height: 28px">
            <div v-show="advertise.time.radio == 'range'"><a-checkbox v-model:checked="advertise.time.checkbox">指定首日开始时间</a-checkbox></div>
            <div v-show="advertise.time.checkbox || advertise.time.radio == 'start'">
              <a-input-group compact>
                <a-input value="开始时间" disabled style="width: 80px" />
                <a-time-picker v-model:value="advertise.time.firstDayBeginTime" :minute-step="60" format="HH:mm" style="width: 188px" value-format="HH:mm" />
              </a-input-group>
            </div>
          </a-space>
        </p>
      </template>
      <template v-else>
        <template v-if="!advertise.time.advanced">
          <p>
            <a-time-picker v-model:value="advertise.time.range[0]" format="HH:mm" value-format="HH:mm" :minute-step="60" />
            <span> - </span>
            <a-time-picker v-model:value="advertise.time.range[1]" :minute-step="60" value-format="HH:mm" format="HH:mm" />
          </p>
        </template>
        <template v-if="advertise.time.advanced">
          <time-selection style="width: 100%" v-model:activeList="advertise.time.activeList" :grid-num-of-day="mediaChannel == 'TT' ? 48 : 24"></time-selection>
        </template>
      </template>
      <p v-if="!advertise.time.advanced"><a href="#" @click="advertise.time.advanced = true">高级设置</a></p>
    </template>

    <!-- 一键起量 -->
    <template v-else-if="selectedValue == 'minimum'">
      <a-switch v-model:checked="advertise.minimum.checked"></a-switch>
      <span class="ml10">{{ advertise.minimum.checked ? '开启' : '关闭' }}</span>
    </template>

    <!-- 定向包 -->
    <template v-else-if="selectedValue == 'direction'">
      <a-select v-model:value="advertise.direction.selected" placeholder="请选择定向包" style="width: 100%" />
    </template>

    <!-- 渠道包 -->
    <template v-else-if="selectedValue == 'channel'">
      <a-select v-model:value="advertise.direction.selected" placeholder="请选择渠道包" style="width: 100%" />
    </template>
  </a-modal>
  <edit-modal v-model:visible="batchEditVisible" :option-name="optionName" :table-data="selectedRows" :type="batchEditType" @dailyBudgetChange="handleOK"></edit-modal>
</template>

<script>
import { Modal } from 'ant-design-vue';
import moment from 'moment';
import EditModal from './EditModal.vue';
import TimeSelection from '../../../components/TimeSelection/TimeSelection.vue';
import { advertiserUpdatePostTime, advertiserUpdatePostDate, advertiserUpdateDailyBudget, adgroupUpdateDailyBudget, updateBidAmount, updateSpeedMode, campaignsUpdateDailyBudget, updateBid, updateStrategy } from '@/api/popularize/txgg/index.js';
import { campaignUpdateDailyBudgetTT, adgroupUpdateDailyBudgetTT, updateBidAmountTT, advertiserUpdateDailyBudgetTT } from '@/api/popularize/jlyq/index.js';
import { campaignUpdateDailyBudgetKS, unitUpdateDailyBudgetKS, advertiserUpdateDailyBudgetKS, updateUnitsUniformBidAmountKS, updateUnitsBidAmountKS, updateUnitsDeepbidKS, advertiserUpdatePostTimeKS, advertiserUpdatePostTimeRangeKS } from '@/api/popularize/clyq/index.js';
export default {
  emits: ['operationChange', 'dailyBudgetChange'],
  name: 'BatchOperation',
  components: { EditModal, TimeSelection },
  inject: ['mediaChannel', 'getActiveKey'],
  props: {
    optionName: { type: String, default: '' },
    selectedRows: { type: Array, default: () => [] },
  },
  data() {
    return {
      btnLoading: false,
      selectedValue: undefined, //选中的批量操作项
      // 操作弹框配置
      modalVisible: false,
      modal: {
        class: 'popularize_batch_operation_modal',
        okButtonProps: { loading: false },
        // width: 600,
        maskClosable: false,
      },
      advertise: {
        strategy: { radio: 'BID_STRATEGY_AVERAGE_COST' },
        date: { radio: 'date', beginDate: '', dateRange: [] },
        time: { radio: 'range', range: ['', ''], firstDayBeginTime: '', checkbox: false, advanced: false, activeList: [] },
        minimum: { checked: false },
        direction: { selected: undefined },
        channel: { selected: undefined },
      },
      speedMode: 'STANDARD', //推广计划投放方式
      batchEditVisible: false, // 批量编辑预算和出价弹框
      batchEditType: 'price', //批量编辑弹框的编辑类型
    };
  },
  computed: {
    // 批量操作配置
    multSelectOptions() {
      const channelOptions = {
        GDT: {
          account: [
            { label: '批量调整日预算', value: 'budget' },
            { label: '批量分配', value: 'batchAssign' },
          ],
          manage: [
            { label: '开启', value: 'NORMAL' },
            { label: '关闭', value: 'SUSPEND' },
            // { label: '删除', value: 'DELETED' },
            { label: '批量调整日预算', value: 'budget' },
            { label: '批量修改投放方式', value: 'deliveryMethod' },
          ],
          advertise: [
            { label: '开启', value: 'NORMAL' },
            { label: '关闭', value: 'SUSPEND' },
            // { label: '删除', value: 'DELETED' },
            { label: '批量调整日预算', value: 'budget' },
            { label: '批量修改出价', value: 'price' },
            { label: '批量修改深度出价', value: 'deepPrice' },
            { label: '批量修改出价策略', value: 'strategy' },
            { label: '批量修改投放日期', value: 'date' },
            { label: '批量修改投放时间', value: 'time' },
            // { label: '批量修改一键起量', value: 'minimum' },
            // { label: '批量修改定向包', value: 'direction' },
            // { label: '批量修改渠道包', value: 'channel' },
          ],
          creativity: [
            { label: '开启', value: 'NORMAL' },
            { label: '关闭', value: 'SUSPEND' },
            // { label: '删除', value: 'DELETED' },
          ],
        },
        TT: {
          // 账户
          account: [
            { label: '批量调整账户日预算', value: 'budget' },
            { label: '批量分配', value: 'batchAssign' },
          ],
          // 广告组
          campaign: [
            { label: '开启', value: 'enable' },
            { label: '关闭', value: 'disable' },
            { label: '删除', value: 'delete' },
            { label: '批量调整日预算', value: 'budget' },
          ],
          // 计划
          ad: [
            { label: '开启', value: 'enable' },
            { label: '关闭', value: 'disable' },
            { label: '删除', value: 'delete' },
            { label: '批量修改日预算', value: 'budget' },
            { label: '批量修改出价', value: 'price' },
            // { label: '批量修改深度转化出价', value: 'deepPrice' },
            // { label: '批量修改出价策略', value: 'strategy' },
            // { label: '批量修改投放时间', value: 'date' },
            // { label: '批量修改投放时段', value: 'time' },
          ],
          // 创意
          creativity: [
            { label: '开启', value: 'enable' },
            { label: '关闭', value: 'disable' },
          ],
        },
        KS: {
          // 账户
          account: [
            { label: '批量调整账户预算', value: 'budget' },
            { label: '批量分配', value: 'batchAssign' },
          ],
          // 计划
          campaign: [
            { label: '开启', value: 'enable' },
            { label: '关闭', value: 'disable' },
            { label: '删除', value: 'delete' },
            { label: '批量调整预算', value: 'budget' },
          ],
          // 广告组
          ad: [
            { label: '开启', value: 'enable' },
            { label: '关闭', value: 'disable' },
            { label: '删除', value: 'delete' },
            { label: '批量修改预算', value: 'budget' },
            { label: '批量修改出价', value: 'price' },
            { label: '批量修改深度转化出价', value: 'deepPrice' },
            { label: '批量修改投放时间', value: 'date' },
            { label: '批量修改投放时段', value: 'time' },
          ],
          // 创意
          creativity: [
            { label: '开启', value: 'enable' },
            { label: '关闭', value: 'disable' },
            { label: '删除', value: 'delete' },
          ],
        },
      };
      let disabled = false;
      let title;
      // 批量操作对象必须是同一个产品,磁力引擎批量修改深度出价需要限制同产品和深度转化目标一致
      // 巨量引擎广告组统一修改日预算的对象预算类型不能是无限
      const getDisabled = () => {
        if (!this.selectedRows || this.selectedRows.length == 0) {
          disabled = true;
        } else {
          const productName = this.selectedRows[0].product_name;
          this.selectedRows.forEach(item => {
            if (item.product_name != productName) {
              title = '批量操作对象必须是同一个产品!';
              return (disabled = true);
            }
          });
        }
      };
      getDisabled();
      return {
        dropdownMatchSelectWidth: false,
        placeholder: '批量操作',
        options: channelOptions[this.mediaChannel]?.[this.optionName],
        disabled,
        title,
      };
    },
    // 弹出框标题
    modalTitle() {
      let item = this.multSelectOptions.options?.find(item => item.value == this.selectedValue);
      return item?.label;
    },
  },
  watch: {
    modalVisible(val) {
      if (!val) {
        this.selectedValue = undefined;
      } else {
        this.advertise.time.advanced = false;
      }
    },
    batchEditVisible(val) {
      if (!val) {
        this.selectedValue = undefined;
      }
    },
  },
  methods: {
    // 下拉项改变
    operationChange(mode) {
      const modeArr1 = ['SUSPEND', 'NORMAL', 'enable', 'disable', 'delete', 'pause'];
      const modeArr2 = ['deepPrice', 'price', 'budget'];
      const _this = this;
      // tab为账户时请判断是否是同一个产品！！！！！！！！！！！！！！！！
      if (modeArr1.includes(mode)) {
        Modal.confirm({
          title: '提示',
          content: '是否确认该操作？',
          onOk() {
            _this.$emit('operationChange', mode);
            _this.selectedValue = undefined;
          },
          onCancel() {
            _this.selectedValue = undefined;
          },
        });
      } else if (modeArr2.includes(mode)) {
        this.batchEditType = mode;
        this.batchEditVisible = true;
      } else if (mode == 'batchAssign') {
        const ids = this.selectedRows.map(item => item.advertiser_id);
        // 跳转到媒体账号管理并筛选未分配
        this.$router.push({ name: 'mediaAccount', params: { ids, channel: this.mediaChannel } });
      } else {
        this.modalVisible = true;
      }
    },
    // 除状态外的其他批量编辑保存
    handleOK(rows, type, editMode) {
      this.btnLoading = true;
      switch (this.mediaChannel) {
        case 'GDT':
          this.dailyBudgetChangeGDT(rows, type, editMode);
          break;
        case 'TT':
          this.dailyBudgetChangeTT(rows, type, editMode);
          break;
        case 'KS':
          this.dailyBudgetChangeKS(rows, type, editMode);
          break;
      }
      this.btnLoading = false;
    },
    // 腾讯广告
    async dailyBudgetChangeGDT(rows, type, editMode) {
      let params = [];
      let res = null;
      // 广告批量修改出价
      if (this.optionName == 'advertise' && type == 'price') {
        rows.forEach(item => {
          const startNumber = editMode == 'many' ? rows[0].startNumber : item.startNumber || null;
          const endNumber = editMode == 'many' ? rows[0].endNumber : item.endNumber || null;
          let random;
          if (startNumber && endNumber) {
            random = startNumber + ',' + endNumber;
          }
          params.push({ adGroupId: item.adgroup_id, number: Number(startNumber), type: item.priceType, random });
        });
        res = await updateBidAmount(params);
      }
      // 广告批量修改深度出价
      else if (this.optionName == 'advertise' && this.selectedValue == 'deepPrice') {
        rows.forEach(item => {
          params.push({ adGroupId: item.adgroup_id, number: Number(editMode == 'many' ? rows[0].startNumber : item.startNumber) || 0, type: item.priceType });
        });
        res = await updateBid(params);
      }
      // 广告批量修改预算
      else if (this.optionName == 'advertise' && this.selectedValue == 'budget') {
        rows.forEach(item => {
          params.push({ adGroupId: item.adgroup_id, number: (editMode == 'many' ? rows[0].dailyBudget : item.dailyBudget) || 0, type: 1 });
        });
        res = await adgroupUpdateDailyBudget(params);
      }
      // 广告修改一键起量
      else if (this.optionName == 'advertise' && this.selectedValue == 'minimum') {
        this.$emit('operationChange', this.selectedValue, this.advertise.minimum.checked);
      }
      // 广告批量修改投放时间
      else if (this.optionName == 'advertise' && this.selectedValue == 'time') {
        const time = this.advertise.time;
        const ids = this.selectedRows.map(item => item.adgroup_id);
        const params = { adgroupIds: ids, timeSeries: time.activeList, firstDayBeginTime: time.firstDayBeginTime };
        if (time.radio == 'start') {
          delete params.timeSeries;
        } else if (time.advanced) {
          params.timeSeries = time.activeList.flat();
        } else {
          const startTime = time.range[0];
          const endTime = time.range[1];
          if (this.advertise.time.radio == 'range') {
            if (!startTime || !endTime) {
              this.$message.warning('请输入完整时间范围');
              return;
            }
          }
          const index1 = parseInt(startTime.split(':')[0]) * 2 + (startTime.split(':')[1] == '30' ? 1 : 0);
          const index2 = parseInt(endTime.split(':')[0]) * 2 + (endTime.split(':')[1] == '30' ? 1 : 0);
          let activeList = [];
          for (let index = 0; index < 48; index++) {
            if (index > index1 - 1 && index < index2) {
              activeList.push(1);
            } else {
              activeList.push(0);
            }
          }
          const arr = [];
          for (let index = 0; index < 7; index++) {
            arr.push(...activeList);
          }
          params.timeSeries = arr;
        }
        res = await advertiserUpdatePostTime(params);
      }
      // 广告批量修改日期
      else if (this.optionName == 'advertise' && this.selectedValue == 'date') {
        if (this.advertise.date.radio == 'date') {
          const ids = this.selectedRows.map(item => item.adgroup_id);
          params = { adgroupIds: ids, beginDate: this.advertise.date.beginDate };
        } else {
          if (!this.advertise.date.dateRange.length) {
            this.$message.warning('投放日期不能为空');
            return;
          } else {
            const ids = this.selectedRows.map(item => item.adgroup_id);
            params = { adgroupIds: ids, beginDate: this.advertise.date.dateRange[0], endDate: this.advertise.date.dateRange[1] };
          }
        }
        res = await advertiserUpdatePostDate(params);
      }
      // 广告批量出价策略
      else if (this.optionName == 'advertise' && this.selectedValue == 'strategy') {
        res = await updateStrategy({ adGroupIds: this.selectedRows.map(item => item.adgroup_id), bidStrategy: this.advertise.strategy.radio });
      }
      // 计划批量修改投放方式
      else if (this.optionName == 'manage' && this.selectedValue == 'deliveryMethod') {
        const ids = this.selectedRows.map(item => item.campaign_id);
        const params = { speedMode: this.speedMode, ids: ids };
        res = await updateSpeedMode(params);
      }
      // 计划批量修改预算
      else if (this.optionName == 'manage' && type == 'budget') {
        rows.forEach(item => {
          params.push({ campaignId: item.campaign_id, dailyBudget: (editMode == 'many' ? rows[0].dailyBudget * 100 : item.dailyBudget * 100) || 0 });
        });
        res = await campaignsUpdateDailyBudget(params);
      }
      // 账户批量修改预算
      else if (this.optionName == 'account' && type == 'budget') {
        rows.forEach(item => {
          params.push({ advertiserId: item.advertiser_id, dailyBudget: (editMode == 'many' ? rows[0].dailyBudget : item.dailyBudget) || 0 });
        });
        res = await advertiserUpdateDailyBudget(params);
      }
      if (res?.code == 0) {
        this.$message.success('操作成功！');
        this.modalVisible = false;
        this.batchEditVisible = false;
      } else if (res) {
        this.$message.error('操作失败！');
      }
    },
    // 巨量引擎
    async dailyBudgetChangeTT(rows, type, editMode) {
      let params = [];
      let res = null;
      // 计划出价
      if (this.optionName == 'ad' && type == 'price') {
        rows.forEach(item => {
          const updatedPrice = (editMode == 'many' ? rows[0].updatedPrice : item.updatedPrice) || 0;
          const endNumber = (editMode == 'many' ? rows[0].endNumber : item.endNumber) || 0;
          params.push({ adId: item.id, bid: updatedPrice });
        });
        res = await updateBidAmountTT(params);
      } else if (this.optionName == 'ad' && type == 'budget') {
        // 计划预算
        rows.forEach(item => {
          params.push({ adId: item.id, budget: (editMode == 'many' ? rows[0].dailyBudget : item.dailyBudget) || 0 });
        });
        res = await adgroupUpdateDailyBudgetTT(params);
      } else if (this.optionName == 'campaign' && type == 'budget') {
        // 广告组日预算
        rows.forEach(item => {
          params.push({ campaignId: item.campaign_id, dailyBudget: (editMode == 'many' ? rows[0].dailyBudget : item.dailyBudget) || 0, budgetMode: 'BUDGET_MODE_DAY' });
        });
        res = await campaignUpdateDailyBudgetTT(params);
      } else if (this.optionName == 'account' && type == 'budget') {
        rows.forEach(item => {
          // 预算为不限的时候传0
          params.push({ advertiserId: item.advertiser_id, number: (editMode == 'many' ? rows[0].dailyBudget : item.dailyBudget) || 0, budgetMode: 'BUDGET_MODE_DAY' });
        });
        res = await advertiserUpdateDailyBudgetTT(params);
      }
      if (res.code == 0) {
        this.$message.success('操作成功！');
      }
    },
    // 磁力引擎
    async dailyBudgetChangeKS(rows, type, editMode) {
      let params = [];
      let res = null;
      // 广告组批量修改出价
      if (this.optionName == 'ad' && type == 'price') {
        // 统一修改出价
        if (editMode == 'many') {
          const updatedPrice = (editMode == 'many' ? rows[0].updatedPrice : item.updatedPrice) || 0;
          params = {
            unitIds: rows.map(item => item.unit_id),
            bid: rows[0].updatedPrice || 0,
          };
          res = await updateUnitsUniformBidAmountKS(params);
        } else {
          // 分别修改出价
          rows.forEach(item => {
            params.push({ unitId: item.unit_id, bid: item.updatedPrice || 0 });
          });
          res = await updateUnitsBidAmountKS(params);
        }
      } else if (this.optionName == 'ad' && type == 'deepPrice') {
        // 广告组批量修改深度出价
        rows.forEach(item => {
          const updatedPrice = (editMode == 'many' ? rows[0].updatedPrice : item.updatedPrice) || 0;
          const endNumber = (editMode == 'many' ? rows[0].endNumber : item.endNumber) || 0;
          params.push({ unitId: item.unit_id, deepConversionBid: updatedPrice });
        });
        res = await updateUnitsDeepbidKS(params);
      } else if (this.optionName == 'ad' && type == 'budget') {
        // 广告组批量修改预算
        rows.forEach(item => {
          const record = {
            unitId: item.unit_id,
            dailyBudget: (editMode == 'many' ? rows[0].dailyBudget : item.dailyBudget) || 0,
            dayBudgetSchedule: editMode == 'many' ? rows[0].budgetByDay.dayBudgetSchedule : item.budgetByDay.dayBudgetSchedule,
          };
          // 分日预算与预算不能同时传
          if (item.editBudgetMode == 'byDay') {
            delete record.dailyBudget;
          } else {
            delete record.dayBudgetSchedule;
          }
          params.push(record);
        });
        res = await unitUpdateDailyBudgetKS(params);
      } else if (this.optionName == 'ad' && type == 'date') {
        // 广告组批量修改投放时间
        if (this.advertise.date.radio == 'date') {
          // 长期投放
          this.selectedRows.forEach(item =>
            params.push({
              unitId: item.unit_id,
              beginTime: this.advertise.date.beginDate,
            })
          );
        } else {
          // 特定日期
          if (!this.advertise.date.dateRange.length) {
            this.$message.warning('投放日期不能为空');
            return;
          } else {
            this.selectedRows.forEach(item =>
              params.push({
                unitId: item.unit_id,
                beginTime: this.advertise.date.dateRange[0],
                endTime: this.advertise.date.dateRange[1],
              })
            );
          }
        }
        res = await advertiserUpdatePostTimeKS(params);
      } else if (this.optionName == 'ad' && type == 'time') {
        // 广告组批量修改时段
        const time = this.advertise.time;
        let scheduleTime = [];
        if (time.advanced) {
          scheduleTime = time.activeList.flat();
        } else {
          const startTime = time.range[0];
          const endTime = time.range[1];
          if (time.radio == 'range') {
            if (!startTime || !endTime) {
              this.$message.warning('请输入完整时间范围');
              return;
            }
          }
          const index1 = parseInt(startTime.split(':')[0]);
          const index2 = parseInt(endTime.split(':')[0]);
          let activeList = [];
          for (let index = 0; index < 24; index++) {
            if (index > index1 - 1 && index < index2) {
              activeList.push(1);
            } else {
              activeList.push(0);
            }
          }
          const arr = [];
          for (let index = 0; index < 7; index++) {
            arr.push(...activeList);
          }
          scheduleTime = arr;
        }
        rows.forEach(item => params.push({ unitId: item.unit_id, scheduleTime }));
        res = await advertiserUpdatePostTimeRangeKS(params);
      } else if (this.optionName == 'campaign' && type == 'budget') {
        // 计划批量修改日预算
        rows.forEach(item => {
          const record = {
            campaignId: item.campaign_id,
            budget: (editMode == 'many' ? rows[0].dailyBudget : item.dailyBudget) || 0,
            dayBudgetSchedule: editMode == 'many' ? rows[0].budgetByDay.dayBudgetSchedule : item.budgetByDay.dayBudgetSchedule,
          };
          // 分日预算与预算不能同时传
          if (item.editBudgetMode == 'byDay') {
            delete record.budget;
          } else {
            delete record.dayBudgetSchedule;
          }
          params.push(record);
        });
        res = await campaignUpdateDailyBudgetKS(params);
      } else if (this.optionName == 'account' && type == 'budget') {
        // 账户批量修改日预算
        // 磁力账户日预算请求数据换算成单位是厘!!!!!!!!!!!!!!!!!!!
        rows.forEach(item => {
          let dayBudgetSchedule = editMode == 'many' ? rows[0].budgetByDay.dayBudgetSchedule : item.budgetByDay.dayBudgetSchedule;
          dayBudgetSchedule = dayBudgetSchedule.map(item => Number(item) * 1000);
          const record = {
            advertiserId: item.advertiser_id,
            number: Number((editMode == 'many' ? rows[0].dailyBudget : item.dailyBudget) || 0) * 1000,
            dayBudgetSchedule,
          };
          // 分日预算与预算不能同时传
          if (item.editBudgetMode == 'byDay') {
            delete record.number;
          } else {
            delete record.dayBudgetSchedule;
          }
          params.push(record);
        });
        res = await advertiserUpdateDailyBudgetKS(params);
      }
      if (res.code == 0) {
        this.$message.success('操作成功！');
      }
    },
    disabledDate(current) {
      return current && current < moment().subtract(1, 'day').endOf('day');
    },
  },
};
</script>

<style scoped lang="less">
.batch_operation_select:not(.ant-select-disabled) {
  :deep(.ant-select-selection-placeholder) {
    color: black !important;
  }
}
</style>
<style lang="less">
.popularize_batch_operation_modal {
  .ant-modal-body {
    padding: 12px;
    .grey {
      color: #919191;
    }
    p {
      margin: 10px 0 !important;
    }
  }
}
</style>
