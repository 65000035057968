<!--
 * @Author: Yran
 * @Date: 2021-11-25 15:52:54
 * @LastEditors: Yran
 * @LastEditTime: 2022-01-07 15:48:47
-->
<template>
  <div class="rules-list-wrapper">
    <div class="toolbar-wrapper flexAlignCenter" @click.self="btnClick = false">
      <div class="filter-select">
        <div class="filter-select-btn flexBetweenCenter" :class="{ active: btnClick }" @click="onBtnClick">
          <span>执行范围：{{ filterOptions.selectName || '全部' + getMediaActiveTab.name }}</span>
          <span>
            <CloseCircleFilled style="margin: 0 5px" v-if="filterOptions.selectName" @click.stop="clearSelect" />
            <UpOutlined v-if="btnClick" />
            <DownOutlined v-else />
          </span>
        </div>
        <transition name="filter-item">
          <div class="filter-item" id="filterItem" v-if="btnClick" @click.self="onBtnClick">
            <div class="filter-item-content">
              <div class="item-title">
                <a-input-search v-model:value="filterOptions.value" :placeholder="getMediaActiveTab.placeholder" style="width: 300px; height: 32px" @search="onSearch">
                  <template #prefix> {{ getMediaActiveTab.name }} <a-divider type="vertical" style="height: 16px; background-color: #d9d9d9" /></template>
                </a-input-search>
              </div>
              <span class="item-content">
                <p v-for="content in dataList" :key="content" @click="onFilterItemClick(content)">
                  <span>
                    <span class="item-name" style="color: #197afb">{{ content.name }}</span>
                    <br />
                    <span class="item-id" style="color: #b7b7b7"> id: {{ content.id }}</span>
                  </span>
                  <span v-if="filterOptions.selectId && filterOptions.selectId == content.id">
                    <CheckOutlined />
                  </span>
                </p>
              </span>
            </div>
          </div>
        </transition>
      </div>
      <div class="filter-other flexAlignCenter">
        <a-input-search v-model:value="filterOptions.ruleName" placeholder="搜索规则名称" allow-clear class="filter-other-input" @search="filterChange"></a-input-search>
      </div>
    </div>
    <div class="rules-table-wrapper">
      <a-table v-bind="table" :data-source="tableData.dataSource" :loading="tableData.tableLoading" rowkey="id" @change="tableChange">
        <template v-for="column in $attrs.columns" :key="column.dataIndex">
          <!-- 操作 -->
          <a-table-column v-if="column.dataIndex == 'operate'" v-bind="column">
            <template #default> <ClockCircleOutlined class="mr10 cursorPoi" /><HighlightOutlined class="mr10 cursorPoi" /><DiffOutlined class="mr10 cursorPoi" /><DeleteOutlined class="cursorPoi" /> </template>
          </a-table-column>
          <!--监测范围 -->
          <a-table-column v-if="column.dataIndex == 'execRange'" v-bind="column">
            <template #default="{ record }">
              <span>{{ setExecRange(record.level, record.execRange) }}</span>
            </template>
          </a-table-column>
          <!--触发条件 -->
          <a-table-column v-else-if="column.dataIndex == 'condition'" v-bind="column">
            <template #default="{ record }">
              <span>{{ setCondition(record.condition) }}</span>
            </template>
          </a-table-column>
          <!--执行操作 -->
          <a-table-column v-else-if="column.dataIndex == 'execContent'" v-bind="column">
            <template #default="{ record }">
              <span>{{ setExecContent(record.execContent) }}</span>
            </template>
          </a-table-column>
          <!--创建时间 -->
          <a-table-column v-else-if="column.dataIndex == 'createTime'" v-bind="column">
            <template #default="{ record }">
              <span>{{ record.createTime && moment(record.createTime).format('YYYY-MM-DD') }}</span>
            </template>
          </a-table-column>
          <!-- 其他 -->
          <a-table-column v-else v-bind="column">
            <template #default="{ record }">
              <span> {{ record?.[column.dataIndex] || '-' }}</span>
            </template>
          </a-table-column>
        </template>
      </a-table>
    </div>
  </div>
</template>

<script>
import { ClockCircleOutlined, HighlightOutlined, DiffOutlined, DeleteOutlined, UpOutlined, DownOutlined, CheckOutlined, CloseCircleFilled } from '@ant-design/icons-vue';
import moment from 'moment';
export default {
  name: 'RulesListTable',
  components: { ClockCircleOutlined, HighlightOutlined, DiffOutlined, DeleteOutlined, UpOutlined, DownOutlined, CheckOutlined, CloseCircleFilled },
  inject: ['getTabActiveKey', 'getMediaAccount', 'tableData', 'getHostTableData', 'setExecRange', 'setCondition', 'setExecContent'],
  computed: {
    getMediaActiveTab() {
      const mediaTabs = this.mediaActiveTab[this.getMediaAccount];
      const mediaActiveTab = mediaTabs?.filter(item => {
        return item.key == this.getTabActiveKey();
      })?.[0];
      return mediaActiveTab || { name: '产品', key: 'product', placeholder: '搜索产品名称' };
    },
  },
  data() {
    return {
      // 筛选数据
      filterOptions: {},
      // 托管规则筛选项显示
      mediaActiveTab: {
        TXGG: [
          { name: '产品', key: 'product', placeholder: '搜索产品名称' },
          { name: '账户', key: 'account', placeholder: '搜索账户名称' },
          { name: '推广计划', key: 'manage', placeholder: '搜索推广计划名称' },
          { name: '广告', key: 'advertise', placeholder: '搜索广告名称' },
          { name: '创意', key: 'creativity', placeholder: '搜索创意名称' },
        ],
        JLYQ: [
          { name: '产品', key: 'product', placeholder: '搜索产品名称' },
          { name: '账户', key: 'account', placeholder: '搜索账户名称' },
          { name: '广告组', key: 'campaign', placeholder: '搜索广告组名称' },
          { name: '计划', key: 'ad', placeholder: '搜索计划名称' },
          { name: '创意', key: 'creativity', placeholder: '搜索创意名称' },
        ],
        CLYQ: [
          { name: '产品', key: 'product', placeholder: '搜索产品名称' },
          { name: '账户', key: 'account', placeholder: '搜索账户名称' },
          { name: '广告计划', key: 'campaign', placeholder: '搜索广告计划名称' },
          { name: '广告组', key: 'ad', placeholder: '搜索广告组名称' },
          { name: '创意', key: 'creativity', placeholder: '搜索创意名称' },
        ],
      },
      // 托管规则筛选项展示数据
      dataList: [
        { id: 12321546455, name: 'pyq指尖ios-xmj011-1111' },
        { id: 12321543455, name: 'pyq指尖ios-xmj011-1111' },
        { id: 1232154455, name: 'pyq指尖ios-xmj011-1111' },
        { id: 12321556455, name: 'pyq指尖ios-xmj011-1111' },
        { id: 1232646455, name: 'pyq指尖ios-xmj011-1111' },
      ],
      // 托管规则筛选项展开
      btnClick: false,
      // 表格配置
      table: {
        bordered: true,
        size: 'small',
        scroll: { x: 'max-content' },
        showExpanded: false,
        pagination: { current: 1, pageSize: 10, total: 0, showTotal: total => `总计${total}条`, showSizeChanger: true, pageSizeOptions: ['10', '20', '30', '50'] },
      },
      // 表格加载
      tableLoading: false,
    };
  },
  mounted() {
    this.getHostTableData();
  },
  methods: {
    /**
     * @description: 表格页码切换
     * @param {*} pagination
     * @param {*} filters
     * @param {*} sorter
     * @return {*}
     */
    tableChange(pagination, filters, sorter) {
      this.table.pagination.page = pagination.current.toString();
      this.table.pagination.pageSize = pagination.pageSize;
      this.filterOptions.page = pagination.current.toString();
      this.filterOptions.limit = pagination.pageSize.toString();
      this.getHostTableData(this.filterOptions);
    },
    /**
     * @description: 筛选项变化
     * @param {*}
     * @return {*}
     */
    filterChange() {
      this.getHostTableData(this.filterOptions);
    },
    getFilterOptions(type) {},
    switchChange() {},
    onSearch() {},
    onBtnClick() {
      this.btnClick = !this.btnClick;
    },
    onFilterItemClick(content) {
      this.filterOptions.selectName = content.name;
      this.filterOptions.selectId = content.id;
      this.filterChange();
    },
    clearSelect() {
      delete this.filterOptions.selectName;
      delete this.filterOptions.selectId;
    },
  },
};
</script>

<style lang="less" scoped>
.rules-list-wrapper {
  height: calc(100% - 60px);
  .toolbar-wrapper {
    margin-bottom: 10px;
    .filter-select {
      position: relative;
      .filter-item-enter-active,
      .filter-item-leave-active {
        transition: opacity 0.3s;
      }
      .filter-item-enter,
      .filter-item-leave-to {
        opacity: 0;
      }
      .filter-item {
        position: absolute;
        top: 0px;
        width: 45vw;
        height: 60vh;
        z-index: 2;
        .filter-item-content {
          margin-top: 36px;
          width: 300px;
          height: 270px;
          border-radius: 2px;
          background: #fff;
          border: 1px solid #d9d9d9;
          border-top: 0;
        }
        .item-content {
          p {
            width: 294px;
            height: 48px;
            padding: 6px 12px;
            border-radius: 2px;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: space-between;
            &:hover {
              background: #f8fbff;
            }
            .item-name {
              font-size: 12px;
              letter-spacing: 0.03px;
              color: #197afb;
              padding-bottom: 4px;
            }
            .item-id {
              font-size: 12px;
              letter-spacing: 0.03px;
              color: #b7b7b7;
            }
          }
        }
      }
      .filter-select-btn {
        height: 30px;
        padding: 6px 12px;
        border-radius: 2px;
        background: #fff;
        border: 1px solid #d9d9d9;
        min-width: 224px;
        cursor: pointer;
        &.active {
          border: 1px solid#2680eb;
        }
      }
    }
    .filter-other {
      height: 32px;
      margin-left: 12px;

      &-input {
        width: 224px;
        margin-right: 12px;
        height: 32px;
      }
    }
  }
}
</style>